import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import Zoom from '@material-ui/core/Zoom';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import TaxMaps from './TaxMaps';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});


export default class Taxes extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: "",
			openTaxMapsDialog: false
		};

		this.editTaxMaps_clickHandler = this.editTaxMaps_clickHandler.bind(this);
		this.taxMapsDialog_closeHandler = this.taxMapsDialog_closeHandler.bind(this);
	}

	componentDidMount() {
		this.fetchTaxes();
	}

	editTaxMaps_clickHandler(event) {
		event.preventDefault();

		this.setState({ openTaxMapsDialog: true, selectedTaxTypeCode: event.target.dataset.taxtypecode });
	}

	taxMapsDialog_closeHandler(event) {
		this.setState({ openTaxMapsDialog: false });
	}

	fetchTaxes() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/taxes')
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				that.setState({ data: data.records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		const info = {
			taxTypeCode: this.state.selectedTaxTypeCode,
			inProgress: this.props.inProgress
		}

		return (
			<div>
				<h1>Taxes</h1>
				<div className="form-group">
					<MaterialTable						
						title=""
						columns={[
							{
								title: 'Tax',
								field: 'taxCode'
							},
							{
								title: 'Tax Description',
								field: 'taxDesc'
							},
							{
								title: 'Tax Type',
								field: 'taxTypeCode'
							},
							{
								title: 'Tax Type Description',
								field: 'taxTypeDesc'
							},
							{
								title: 'Tax Amount (%)',
								field: 'otherTaxAmt',
								type: "numeric"
							},
							{
								title: 'Is Tax Included',
								field: 'taxIncludedFlag',
								render: rowData => { return rowData.taxIncludedFlag === 1 ? "Y" : "N"; }
							},
							{
								title: 'Is Tax Percentage',
								field: 'taxPrcFlag',
								render: rowData => { return rowData.taxPrcFlag === 1 ? "Y" : "N"; }
							},
							{
								title: 'Mappings',
								headerStyle: {
									textAlign: 'center',
								},
								cellStyle: {
									textAlign: 'center'
								},
								render: rowData => <Link data-taxtypecode={rowData.taxTypeCode} href="#" onClick={this.editTaxMaps_clickHandler}>Edit</Link>
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670
						}}
						data={this.state.data}
					/>
				</div>
				<Dialog open={this.state.openTaxMapsDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<TaxMaps info={info} onClose={this.taxMapsDialog_closeHandler} />
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}
