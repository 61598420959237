import * as React from 'react';
//import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RefreshIcon from '@material-ui/icons/Refresh';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable, { MTableToolbar, MTableGroupRow, MTableBodyRow, MTableEditRow } from 'material-table'
import SearchIcon from '@material-ui/icons/Search';
import UncollapseIcon from '@material-ui/icons/NavigateNext';
import CollapseIcon from '@material-ui/icons/NavigateBefore';
import Slide from '@material-ui/core/Slide';
import Zoom from '@material-ui/core/Zoom';
import { InvoiceDocumentViewer } from './InvoiceDocumentViewer';
import { InvoiceDocumentRoutingHistory } from './InvoiceDocumentRoutingHistory';
import { VendorLookup } from '../Lookups/VendorLookup';
import { POHeaderLookup } from '../Lookups/POHeaderLookup';
import { Level2Lookup } from '../Lookups/Level2Lookup';
import { Level3Lookup } from '../Lookups/Level3Lookup';
import { CostCodesLookup } from '../Lookups/CostCodesLookup';
import { POHeaderResourceLookup } from '../Lookups/POHeaderResourceLookup';
import { Level2ResourceLookup } from '../Lookups/Level2ResourceLookup';
import { stringIsNullOrEmpty, blankStringIfNullOrEmpty, formatNumber, zeroIfNull, addDaysInDate } from '../Common/Utilities';
import { toast } from 'react-toastify';
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class InvoiceDocumentEditor extends React.Component {
	constructor(props) {
		super(props);

		var title;
		var openReplaceFileScanInProgress = false;

		switch (this.props.info.mode) {
			case "View":
				title = "View Invoice";
				break;
			case "Test":
				title = "View Invoice (Test)";
				break;
			case "UserEntry":
				title = "User Entry";
				break;
			case "ManualEntry":
				title = "Manual Entry";
				break;
			case "MakeCorrections":
				title = "Make Corrections in Invoice";
				break;
			case "Replace":
				title = "Replace Invoice";
				openReplaceFileScanInProgress = true;
				break;
			default:
				title = "";
		}

		var userInfo = JSON.parse(localStorage.userInfo);
		this.resourceId = userInfo.id;
		this.isAdmin = userInfo.selectedUserRoles.isAdmin;

		this.currentEditRow = null;

		this.state = {
			isDirty: false,
			readOnly: this.props.info.mode === "View" || this.props.info.mode === "Approval" || this.props.info.mode === "Test",
			title: title,
			openVendorDialog: false,
			openPOHeaderDialog: false,
			openLevel2Dialog: false,
			openLevel3Dialog: false,
			openCostCodesDialog: false,
			openPOHeaderResourceDialog: false,
			openLevel2ResourceDialog: false,
			openChangeVendorConfirmation: false,
			openReplaceInvoiceDocumentEditorDialog: false,
			openInvoiceDocumentRoutingHistoryDialog: false,
			openReplaceFileScanInProgress: openReplaceFileScanInProgress,
			vendor: null,
			inProgress: false,
			replacedInvoiceDocument: null,
			serviceTermStartDate: null,
			serviceTermEndDate: null,
			isInvoiceNumberDuplicate: false,
			uncollapsed: true,
			displayInvoiceDocumentViewer: ""
		};

		this.input_changeHandler = this.input_changeHandler.bind(this);
		this.invoiceNumber_blurHandler = this.invoiceNumber_blurHandler.bind(this);
		this.serviceTerm_blurHandler = this.serviceTerm_blurHandler.bind(this);
		this.close_clickHandler = this.close_clickHandler.bind(this);
		this.fileUpload_changeHandler = this.fileUpload_changeHandler.bind(this);
		this.search_clickHandler = this.search_clickHandler.bind(this);
		this.searchIONumber_clickHandler = this.searchIONumber_clickHandler.bind(this);
		this.deletePOCode_clickHandler = this.deletePOCode_clickHandler.bind(this);
		this.searchLevel2_clickHandler = this.searchLevel2_clickHandler.bind(this);
		this.searchLevel3_clickHandler = this.searchLevel3_clickHandler.bind(this);
		this.searchCostCodes_clickHandler = this.searchCostCodes_clickHandler.bind(this);
		this.routeToEmployee_clickHandler = this.routeToEmployee_clickHandler.bind(this);
		this.accept_clickHandler = this.accept_clickHandler.bind(this);
		this.reject_clickHandler = this.reject_clickHandler.bind(this);
		this.routingHistory_clickHandler = this.routingHistory_clickHandler.bind(this);
		this.invoiceDocumentRoutingHistoryDialog_closeHandler = this.invoiceDocumentRoutingHistoryDialog_closeHandler.bind(this);
		this.save_clickHandler = this.save_clickHandler.bind(this);
		this.vendorDialog_closeHandler = this.vendorDialog_closeHandler.bind(this);
		this.poHeaderDialog_closeHandler = this.poHeaderDialog_closeHandler.bind(this);
		this.poHeaderResourceDialog_closeHandler = this.poHeaderResourceDialog_closeHandler.bind(this);
		this.level2ResourceDialog_closeHandler = this.level2ResourceDialog_closeHandler.bind(this);
		this.level2Dialog_closeHandler = this.level2Dialog_closeHandler.bind(this);
		this.level3Dialog_closeHandler = this.level3Dialog_closeHandler.bind(this);
		this.costCodesDialog_closeHandler = this.costCodesDialog_closeHandler.bind(this);
		this.changeVendorYes_clickHandler = this.changeVendorYes_clickHandler.bind(this);
		this.changeVendor_closeHandler = this.changeVendor_closeHandler.bind(this);
		this.replace_clickHandler = this.replace_clickHandler.bind(this);
		this.tryFetchingInvoiceDocument = this.tryFetchingInvoiceDocument.bind(this);
		this.replaceFileInfoDialog_clickHandler = this.replaceFileInfoDialog_clickHandler.bind(this);
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.collapseUncollapse_clickHandler = this.collapseUncollapse_clickHandler.bind(this);
		this.getUpdatedData = this.getUpdatedData.bind(this);
		this.onPaymentTermsChange = this.onPaymentTermsChange.bind(this);
		this.onTaxCodeChange = this.onTaxCodeChange.bind(this);
		this.onIoInvoiceAmountBlur = this.onIoInvoiceAmountBlur.bind(this);

		this.fetchInvoiceDocument();
	}

	fetchInvoiceDocument() {
		var modelDefId = this.props.info.modelDefId;
		var invoiceModel = this.props.info.invoiceModel;
		var uri;

		if (invoiceModel) {
			uri = "/api/invoicemodels/invoicemodeleditor/" + modelDefId + "/" + invoiceModel.masterDocumentModelId + "/" + invoiceModel.invoiceDocumentSampleFileLocation;
		}
		else {
			uri = "/api/invoicedocument/invoicedocumenteditor/" + modelDefId + "/" + (this.props.info.rowData ? this.props.info.rowData.invoiceDocumentId : "0");
		}

		this.props.info.inProgress(true);

		httpGet(uri)
			.then((response) => {
				var invoiceDocument = response.data;

				if (this.props.info.isViaQueryString) { // all the code below is to be executed if the editor is being opened through query string in the URL
					if (this.props.info.mode !== "UserEntry" && this.props.info.rowData.invoiceDocumentId > 0) {
						if (invoiceDocument.invoiceDocumentId === 0) {
							throw new Error('Invoice Document not found');
						}
						else if (this.props.info.mode === "Approval" &&
							!(invoiceDocument.invoiceDocumentStatusId === 50 ||
								invoiceDocument.invoiceDocumentStatusId === 70 ||
								invoiceDocument.invoiceDocumentStatusId === 90 ||
								invoiceDocument.invoiceDocumentStatusId === 110 ||
								invoiceDocument.invoiceDocumentStatusId === 130)
						) {
							throw new Error('Invoice has been processed or status has changed');
						}
						else if ((this.props.info.mode === "MakeCorrections" || this.props.info.mode === "ManualEntry") &&
							!(invoiceDocument.invoiceDocumentStatusId <= 40 ||
								invoiceDocument.invoiceDocumentStatusId === 50 ||
								invoiceDocument.invoiceDocumentStatusId === 60 ||
								invoiceDocument.invoiceDocumentStatusId === 80 ||
								invoiceDocument.invoiceDocumentStatusId === 100 ||
								invoiceDocument.invoiceDocumentStatusId === 120 ||
								invoiceDocument.invoiceDocumentStatusId === 140 ||
								invoiceDocument.invoiceDocumentStatusId === 160 ||
								invoiceDocument.invoiceDocumentStatusId === 180)
						) {
							throw new Error('Invoice has been processed or status has changed');
						}
						else if (this.props.info.mode !== "View" && (!isNaN(this.props.info.rowData.invoiceDocumentStatusId) || this.props.info.rowData.invoiceDocumentStatusId !== 0) && this.props.info.rowData.invoiceDocumentStatusId !== invoiceDocument.invoiceDocumentStatusId) {
							throw new Error('Invoice has been processed or status has changed');
						}
					}
				}

				var title = this.state.title;
				var readOnly = this.state.readOnly;
				var serviceTermStartDate = invoiceDocument.serviceTermStartDate;
				var serviceTermEndDate = invoiceDocument.serviceTermEndDate;

				if (this.props.info.mode === "UserEntry" || this.props.info.mode === "Replace") {
					invoiceDocument.invoiceDocumentStatusAttribute = "M";
					invoiceDocument.vendorCode = "";
					invoiceDocument.vendor = "";
					invoiceDocument.siteId = "";
					invoiceDocument.documentModelName = "";
					invoiceDocument.documentModelNameTag = "";
					invoiceDocument.invoiceNumber = "";
					invoiceDocument.invoiceDateString = "";
					invoiceDocument.dueDateString = "";
					invoiceDocument.serviceTermStartDateString = "";
					invoiceDocument.serviceTermEndDateString = "";
					invoiceDocument.paymentTermCode = "";
					invoiceDocument.currencyCode = "";
					invoiceDocument.createDateString = "";

					if (this.props.info.mode === "Replace") {
						invoiceDocument.invoiceDocumentId = this.props.info.invoiceDocumentId;
					}
				} else if (this.props.info.mode === "ManualEntry") {
					invoiceDocument.invoiceDocumentStatusId = 50;
					invoiceDocument.invoiceDocumentStatusAttribute = "M";
				} else if (this.props.info.mode === "MakeCorrections") {

					if (invoiceDocument.invoiceDocumentStatusId === 80 ||
						invoiceDocument.invoiceDocumentStatusId === 100 ||
						invoiceDocument.invoiceDocumentStatusId === 120 ||
						invoiceDocument.invoiceDocumentStatusId === 140 ||
						invoiceDocument.invoiceDocumentStatusId === 160) {
						invoiceDocument.invoiceDocumentStatusId = (invoiceDocument.invoiceDocumentStatusId - 30);
					}
					else {
						invoiceDocument.invoiceDocumentStatusId = 50;
					}

					invoiceDocument.invoiceDocumentStatusAttribute = "C";
				} else if (this.props.info.mode === "Approval") {
					title = invoiceDocument.title;
					readOnly = !invoiceDocument.allowInvoiceEditing;
					if (invoiceDocument.invoiceDocumentStatusId === 100 ||
						invoiceDocument.invoiceDocumentStatusId === 120 ||
						invoiceDocument.invoiceDocumentStatusId === 140 ||
						invoiceDocument.invoiceDocumentStatusId === 160) {
						invoiceDocument.invoiceDocumentStatusId = (invoiceDocument.invoiceDocumentStatusId - 50);
					}
					else if (invoiceDocument.invoiceDocumentStatusId === 80) {
						invoiceDocument.invoiceDocumentStatusId = 50;
					}

					invoiceDocument.invoiceDocumentStatusAttribute = "C";
				}

				if (invoiceDocument.masterDocumentModelId === 0) {
					invoiceDocument.masterDocumentModelId = null;
				}

				if (invoiceModel) {
					if (stringIsNullOrEmpty(invoiceDocument.vendor) || stringIsNullOrEmpty(invoiceDocument.siteId)) {
						invoiceDocument.vendor = invoiceModel.vendor;
						invoiceDocument.siteId = invoiceModel.siteId;
					}

					invoiceDocument.documentModelName = invoiceModel.modelName;
					invoiceDocument.documentModelNameTag = invoiceModel.modelTag;
				}

				var taxes = [];
				//invoiceDocument.taxes = invoiceDocument.taxes.filter(t => t.taxIncludedFlag === (modelDefId === 1 ? 0 : 1));
				//invoiceDocument.taxes = invoiceDocument.taxes.filter(t => t.taxIncludedFlag === 1); // No need for this check both production and media would use the same.
				if (invoiceDocument.taxes) {
					invoiceDocument.taxes.forEach(t => {
						if (taxes.find(tt => tt.taxCode === t.taxCode) == null) {
							taxes.push({ taxCode: t.taxCode, taxDesc: t.taxDesc });
						}
					});
				}

				this.props.info.inProgress(false);

				this.setState({ invoiceDocument: invoiceDocument, taxes: taxes, title: title, readOnly: readOnly, serviceTermStartDate: serviceTermStartDate, serviceTermEndDate: serviceTermEndDate, isInvoiceNumberDuplicate: (invoiceDocument.duplicateInvoiceFlag === 'Y') });
			}).catch((error) => {
				this.setState({ invoiceDocument: null });
				this.props.info.inProgress(false);

				toast.error(stringIsNullOrEmpty(error.message) ? 'Error fetching invoice document' : error.message);

				this.close_clickHandler();
			});
	}

	fetchInvoiceDocumentByFileName(invoiceDocumentFileLocation) {
		var that = this;

		httpGet("/api/invoicedocument/invoicedocumenteditorbyfilename/" + escape(invoiceDocumentFileLocation))
			.then((response) => {
				var invoiceDocument = response.data;

				if (invoiceDocument.invoiceDocumentId > 0) {
					that.setState({ invoiceDocument: invoiceDocument, replacedInvoiceDocument: invoiceDocument, fetchInterval: null, inProgress: false });

					clearInterval(that.state.fetchInterval);

					that.props.info.inProgress(false);
				}
				else {
					that.setState({ inProgress: false });
				}
			}).catch((error) => {
				this.props.info.inProgress(false);

				toast.error('Error fetching replaced invoice document');

				this.close_clickHandler();
			});
	}

	tryFetchingInvoiceDocument() {
		if (!this.state.replacedInvoiceDocument && !this.state.inProgress) {
			this.setState({ inProgress: true });

			this.fetchInvoiceDocumentByFileName(this.state.invoiceDocument.invoiceDocumentFileLocation);
		}
	}

	fileUpload_changeHandler(event) {
		var that = this;

		const formData = new FormData();
		const fileOriginal = event.target.files[0];

		if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
			event.target.value = null
			return;
		}

		if (fileOriginal) {
			var filename = fileOriginal.name.replace(/[^\w\.]/gi, '');
			var file = new File([fileOriginal], filename, { type: fileOriginal.type });
			formData.append(0, file);

			this.setState({ selectedFile: file, documentContentType: file.type });

			var url = "/api/invoicedocument/";

			if (this.props.info.mode === "Replace") {
				url += "replace";
				formData.append("invoiceDocumentId", this.state.invoiceDocument.invoiceDocumentId)
			}
			else {
				url += "upload";
			}

			formData.append("modelDefId", this.state.invoiceDocument.modelDefId);

			const token = localStorage.token;
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}

			this.props.info.inProgress(true, false, 0);

			httpPost(url, formData, {
				headers: headers,
				onUploadProgress: ProgressEvent => {
					this.props.info.inProgress(true, true, (ProgressEvent.loaded / ProgressEvent.total * 100));
				},
			}).then((response) => {
				var invoiceDocument = this.state.invoiceDocument;

				invoiceDocument.invoiceDocumentFileLocation = response.data.invoiceDocumentFileLocation;
				invoiceDocument.fileName = response.data.fileName;
				invoiceDocument.createDateString = response.data.createDateString;

				setTimeout(function () {
					that.setState({ invoiceDocument: invoiceDocument });
					that.props.info.inProgress(false, false, 0);

					toast.success('Uploaded Successfully');

					if (that.props.info.mode === "Replace") {
						that.setState({ fetchInterval: setInterval(that.tryFetchingInvoiceDocument, 15000) });
						that.props.info.inProgress(false);
						that.close_clickHandler();
					}
					else {
						that.props.info.inProgress(false);
					}
				}, 3000);
			}).catch((error) => {
				toast.error('Upload Failed');

				this.props.info.inProgress(false, false, 0);
			});
		}

		event.target.value = null;
	}

	checkMimeType = (event) => {
		//getting file object
		let files = event.target.files
		//define message container
		let err = []
		// list allow mime type
		const types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']
		// loop access array
		for (var x = 0; x < files.length; x++) {
			// compare file type find doesn't matach
			if (types.every(type => files[x].type !== type)) {
				// create error message and assign to container   
				err.push(files[x].name + ' of type \'' + files[x].type + '\' is not a supported format');
			}
		};

		if (err.length > 0) { // if message not same old that mean has error
			for (var i = 0; i < err.length; i++) {
				toast.error(err[i]);
			}

			return false;
		}

		return true;
	}

	checkFileSize = (event) => {
		let files = event.target.files
		var size = 10 * 1024 * 1024; //10 MB size
		let err = "";

		for (var x = 0; x < files.length; x++) {
			if (files[x].size > size) {
				err += files[x].name + ' is too large, please pick a smaller file\n';
			}
		};

		if (err !== '') {
			toast.error(err);

			return false
		}

		return true;
	}

	routeToEmployee_clickHandler() {
		var isProduction = this.state.invoiceDocument.modelDefId === 2;
		this.setState({ openPOHeaderResourceDialog: !isProduction, openLevel2ResourceDialog: isProduction });
	}

	accept_clickHandler() {
		this.props.info.inProgress(true);

		httpPost("/api/invoicedocument/reviewaccept/", this.state.invoiceDocument)
			.then((response) => {
				toast.success('Accepted Successfully');
				this.props.info.inProgress(false);
				this.close_clickHandler(null);
			}).catch((error) => {
				this.props.info.inProgress(false);

				toast.error(<div>Accept Failed<br />{error.response.data}</div>);
			});
	}

	reject_clickHandler() {
		this.props.info.inProgress(true);

		httpPost("/api/invoicedocument/reviewreject/", this.state.invoiceDocument)
			.then((response) => {
				toast.success('Rejected Successfully');
				this.props.info.inProgress(false);
				this.close_clickHandler(null);
			}).catch((error) => {
				this.props.info.inProgress(false);
				toast.error('Reject Failed');
			});
	}

	save_clickHandler() {
		var invoiceDocument = this.state.invoiceDocument;

		this.props.info.inProgress(true);

		httpPost("/api/invoicedocument/invoicedocumenteditor/", invoiceDocument)
			.then((response) => {
				toast.success('Updated Successfully');
				this.props.info.inProgress(false);
				this.close_clickHandler(null);
			}).catch((error) => {
				this.props.info.inProgress(false);
				toast.error(<div>Update Failed<br />{error.response.data.detail}</div>);
			});
	}

	routingHistory_clickHandler(event) {
		this.setState({ openInvoiceDocumentRoutingHistoryDialog: true });
	}

	invoiceDocumentRoutingHistoryDialog_closeHandler(event) {
		this.setState({ openInvoiceDocumentRoutingHistoryDialog: false });
	}

	input_changeHandler(event) {
		var invoiceDocument = this.state.invoiceDocument;

		invoiceDocument[event.currentTarget.name] = event.currentTarget.value;

		if (event.currentTarget.dataset && event.currentTarget.dataset.name) {
			invoiceDocument[event.currentTarget.dataset.name] = event.currentTarget.value;
		}

		//Reset if currency not matching
		if (event.currentTarget.name == "currencyCode" && event.currentTarget.value != invoiceDocument.currencyCode) {
			invoiceDocument.vendorCode = "";
			invoiceDocument.shortName = "";
			invoiceDocument.vendor = "";
			invoiceDocument.siteId = "";
			//invoiceDocument.currencyCode = "";

			this.setState({ invoiceDocument: invoiceDocument, isDirty: false });
		}
		else
			this.setState({ invoiceDocument: invoiceDocument, isDirty: true });
	}

	onPaymentTermsChange(event) {
		this.input_changeHandler(event);
		var paymentTerm = this.state.invoiceDocument.paymentTerms.find(val => val.termsCode === this.state.invoiceDocument.paymentTermCode);
		if (!paymentTerm) return;

		var daysDue = parseInt(paymentTerm.daysDue, 10);
		var invoiceDate = new Date(this.state.invoiceDocument.invoiceDateString);
		var dueDate = addDaysInDate(invoiceDate, daysDue);
		var invoiceDocument = this.state.invoiceDocument;
		invoiceDocument.dueDate = dueDate;
		if (dueDate && dueDate.toISOString().length > 10)
			invoiceDocument.dueDateString = dueDate.toISOString().slice(0, 10);
		this.setState({ invoiceDocument: invoiceDocument });
	}

	onTaxCodeChange(event) {
		this.currentEditRow.taxCode = event.currentTarget.value;

		this.calculateTaxAmount(this.currentEditRow.taxCode, this.currentEditRow);
		this.setState({ forceRender: true });

	}

	onIoInvoiceAmountBlur(event, props) {
		this.calculateTaxAmount(this.currentEditRow.taxCode, this.currentEditRow);

		this.setState({ forceRender: true });
	}

	invoiceNumber_blurHandler(event) {
		var invoiceDocument = this.state.invoiceDocument;

		if (this.isVendorSelected() && !stringIsNullOrEmpty(invoiceDocument.invoiceNumber)) {
			this.props.info.inProgress(true);

			httpGet("/api/invoicedocument/validateduplicate/" + parseInt(encodeURIComponent(invoiceDocument.invoiceDocumentId)) + "/" + encodeURIComponent(invoiceDocument.vendorCode) + "/" + encodeURIComponent(invoiceDocument.invoiceNumber))
				.then((response) => {
					var isInvoiceNumberDuplicate = response.data;

					this.props.info.inProgress(false);

					this.setState({ isInvoiceNumberDuplicate: isInvoiceNumberDuplicate });
				}).catch((error) => {
					this.props.info.inProgress(false);

					toast.error(<div>Delivery Amount update failed<br />{error.response.data.detail}</div>);
				});

			//this.setState({ invoiceDocument: invoiceDocument, serviceTermStartDate: serviceTermStartDate, serviceTermEndDate: serviceTermEndDate });
		}
	}

	serviceTerm_blurHandler(event) {
		var pocodes = [];

		if (this.state.invoiceDocument.invoiceDocumentEditorDetails.length > 0) {
			var invoiceDocument = this.state.invoiceDocument;
			var serviceTermStartDate = invoiceDocument.serviceTermStartDate;
			var serviceTermEndDate = invoiceDocument.serviceTermEndDate;

			if (stringIsNullOrEmpty(this.state.invoiceDocument.serviceTermStartDate) || stringIsNullOrEmpty(this.state.invoiceDocument.serviceTermEndDate)) {
				this.state.invoiceDocument.invoiceDocumentEditorDetails.map(function (detail, index) {
					detail.deliveryAmount = 0.00;
				});

				if (this.currentEditRow) {
					this.currentEditRow.deliveryAmount = 0.00;
				}
			}
			else if (this.state.serviceTermStartDate !== this.state.invoiceDocument.serviceTermStartDate || this.state.serviceTermEndDate !== this.state.invoiceDocument.serviceTermEndDate) {
				var uniqueInvoiceDocumentEditorDetails = this.uniqueBy(this.state.invoiceDocument.invoiceDocumentEditorDetails, ["ioNumber"]);

				uniqueInvoiceDocumentEditorDetails.map(function (detail, index) {
					if (!stringIsNullOrEmpty(detail.ioNumber)) pocodes.push(detail.ioNumber.trim());
				});

				var params = {
					pocodes: pocodes,
					serviceTermStartDate: stringIsNullOrEmpty(this.state.invoiceDocument.serviceTermStartDate) ? null : this.state.invoiceDocument.serviceTermStartDate,
					serviceTermEndDate: stringIsNullOrEmpty(this.state.invoiceDocument.serviceTermEndDate) ? null : this.state.invoiceDocument.serviceTermEndDate
				}

				this.props.info.inProgress(true);

				httpPost("/api/lookup/poheaderlistbypocodelist/", params)
					.then((response) => {
						var poHeaders = response.data;

						invoiceDocument.invoiceDocumentEditorDetails.map(function (detail, index) {
							var poHeader = poHeaders.find(poHeader => !stringIsNullOrEmpty(poHeader.poCode) && !stringIsNullOrEmpty(detail.ioNumber) && poHeader.poCode.trim() === detail.ioNumber.trim());

							if (poHeader) {
								detail.deliveryAmount = poHeader.deliveryAmount;
							}
						});

						if (this.currentEditRow) {
							this.currentEditRow.deliveryAmount = poHeaders.find(poHeader => poHeader.poCode.trim() === this.currentEditRow.ioNumber.trim()).deliveryAmount;
						}

						this.props.info.inProgress(false);
					}).catch((error) => {
						this.props.info.inProgress(false);
						if (error.response && error.response.data) {
							toast.error(<div>Delivery Amount update failed<br />{error.response.data.detail}</div>);
						}
					});
			}

			this.setState({ invoiceDocument: invoiceDocument, serviceTermStartDate: serviceTermStartDate, serviceTermEndDate: serviceTermEndDate });
		}
	}

	search_clickHandler(event) {
		this.setState({ openVendorDialog: true });
	}

	vendorDialog_closeHandler(event, vendor) {
		if (vendor && !stringIsNullOrEmpty(this.state.invoiceDocument.vendorCode) && this.state.invoiceDocument.vendorCode !== vendor.vendorCode &&
			((this.state.invoiceDocument.modelDefId === 1 && this.state.invoiceDocument.invoiceDocumentEditorDetails.length > 0) ||
				(this.state.invoiceDocument.modelDefId === 2 && !stringIsNullOrEmpty(this.state.invoiceDocument.poCode)))
		) {
			this.setState({ openVendorDialog: false, vendor: vendor, openChangeVendorConfirmation: true });
		}
		else {
			this.setState({ openVendorDialog: false, poHeaderLookup: [] });
			this.setVendor(vendor);
		}
	}

	close_clickHandler(event) {
		if (this.props.onClose) {
			this.props.onClose(event);
		}
	}

	replaceFileInfoDialog_clickHandler(event) {
		this.setState({ openReplaceFileScanInProgress: false });
	}

	searchIONumber_clickHandler(event) {
		this.setState({ openPOHeaderDialog: true });
	}

	deletePOCode_clickHandler(event) {
		var invoiceDocument = this.state.invoiceDocument;

		invoiceDocument.poCode = "";
		invoiceDocument.isPOCodeValid = 'Y';

		this.setState({ invoiceDocument: invoiceDocument, isDirty: true });

		this.verifyProductionInvoiceDocumentDetails();
	}

	searchLevel2_clickHandler(event) {
		this.setState({ openLevel2Dialog: true });
	}

	searchLevel3_clickHandler(event) {
		this.setState({ openLevel3Dialog: true });
	}

	searchCostCodes_clickHandler(event) {
		this.setState({ openCostCodesDialog: true });
	}

	poHeaderDialog_closeHandler(event, poHeader) {
		var invoiceDocument = this.state.invoiceDocument;

		if (poHeader) {
			if (this.state.invoiceDocument.modelDefId === 2) {
				var doVerification = invoiceDocument.poCode !== poHeader.poCode;

				invoiceDocument.poCode = poHeader.poCode.trim();
				invoiceDocument.poAmount = poHeader.poAmount;
				invoiceDocument.isPOCodeValid = 'Y';
				if (doVerification) {
					this.verifyProductionInvoiceDocumentDetails();
				}
			}
			else {
				this.currentEditRow.ioNumber = poHeader.poCode.trim();
				this.currentEditRow.poAmount = poHeader.poAmount;
				this.currentEditRow.consumedAmount = poHeader.consumedAmount;
				this.currentEditRow.deliveryAmount = poHeader.deliveryAmount;
				this.currentEditRow.isIONumberValid = 'Y';
				this.currentEditRow.isIOUpdated = true;
				//Check for tax code
				//const invoiceDocument = this.state.invoiceDocument;
				var IOPO = invoiceDocument.invoiceDocumentEditorDetails;
				var invoiceDocumentEditorDetails = IOPO.filter(x => x.ioNumber == poHeader.poCode.trim());
				if (invoiceDocumentEditorDetails && invoiceDocumentEditorDetails.length > 0) {
					this.currentEditRow.taxCode = invoiceDocumentEditorDetails[0].taxCode;
					this.calculateTaxAmount(this.currentEditRow.taxCode, this.currentEditRow);
				}

			}
		}

		this.setState({ openPOHeaderDialog: false, invoiceDocument: invoiceDocument, isDirty: true });
	}

	poHeaderResourceDialog_closeHandler(event, poHeaderResources) {
		if (poHeaderResources.length > 0) {
			var that = this;
			var routedToResources = [];

			poHeaderResources.map(function (poHeaderResource, poHeaderResourceIndex) {
				const invoiceDocumentResourceRoutingHistory = { invoiceDocumentId: that.state.invoiceDocument.invoiceDocumentId, resourceId: poHeaderResource.resourceId, email: poHeaderResource.email, comments: poHeaderResource.comments };

				routedToResources.push(invoiceDocumentResourceRoutingHistory);
			});

			this.props.info.inProgress(true);

			httpPost("/api/invoicedocument/routetoemployee/", routedToResources)
				.then((response) => {
					var data = response.data;
					var invoiceDocument = this.state.invoiceDocument;
					invoiceDocument.routedToResourceId = data.resourceId;

					this.setState({ invoiceDocument: invoiceDocument });

					this.props.info.inProgress(false);
					toast.success('Routed to Employee Successfully');

				}).catch((error) => {
					this.props.info.inProgress(false);
					toast.error('Route to Employee Failed');
				});
		}

		this.setState({
			openPOHeaderResourceDialog: false, selectedRows: [],
			comments: ""
		});
	}

	level2ResourceDialog_closeHandler(event, level2Resources) {
		if (level2Resources.length > 0) {
			var that = this;
			var routedToResources = [];

			level2Resources.map(function (level2Resource, level2ResourceIndex) {
				const invoiceDocumentResourceRoutingHistory = { invoiceDocumentId: that.state.invoiceDocument.invoiceDocumentId, resourceId: level2Resource.resourceId, email: level2Resource.email, comments: level2Resource.comments };
				routedToResources.push(invoiceDocumentResourceRoutingHistory);
			});

			this.props.info.inProgress(true);

			httpPost("/api/invoicedocument/routetoemployee/", routedToResources)
				.then((response) => {
					var data = response.data;
					var invoiceDocument = this.state.invoiceDocument;
					invoiceDocument.routedToResourceId = data.resourceId;

					this.setState({ invoiceDocument: invoiceDocument });

					this.props.info.inProgress(false);
					toast.success('Routed to Employee Successfully');

				}).catch((error) => {
					this.props.info.inProgress(false);
					toast.error('Route to Employee Failed');
				});
		}

		this.setState({
			openLevel2ResourceDialog: false, selectedRows: [],
			comments: ""
		});
	}

	level2Dialog_closeHandler(event, level2) {
		if (level2) {
			if (this.currentEditRow.level2Key !== level2.level2Key) {
				this.currentEditRow.level3Key = null;
				this.currentEditRow.costType = null;
				this.currentEditRow.resType = null;
				this.currentEditRow.rTypeName = null;

				this.currentEditRow.netCost = 0.00;
				this.currentEditRow.matchToDateNet = 0.00;
				this.currentEditRow.remainingAmount = 0.00;

				this.fetchLevel3(level2.level2Key);
			}

			this.currentEditRow.level2Key = level2.level2Key;
			this.currentEditRow.isLevel2KeyValid = 'Y';

			this.currentEditRow.poRequiredFlag = level2.poRequiredFlag;
			this.currentEditRow.tolerancePOFlag = level2.tolerancePOFlag;
			this.currentEditRow.tolerancePOAmount = level2.tolerancePOAmount;
		}

		this.setState({ openLevel2Dialog: false });
	}

	level3Dialog_closeHandler(event, level3) {
		if (level3) {
			if (this.currentEditRow.level3Key !== level3.level3Key) {
				this.currentEditRow.resType = null;
				this.currentEditRow.rTypeName = null;

				this.currentEditRow.netCost = 0.00;
				this.currentEditRow.matchToDateNet = 0.00;
				this.currentEditRow.remainingAmount = 0.00;
			}

			this.currentEditRow.level3Key = level3.level3Key;
			this.currentEditRow.costType = level3.costType;
			this.currentEditRow.isLevel3KeyValid = 'Y';

			this.fetchCostCodes(level3.level2Key, level3.level3Key);
		}

		this.setState({ openLevel3Dialog: false });
	}

	costCodesDialog_closeHandler(event, costCodes) {
		if (costCodes) {
			this.currentEditRow.resType = costCodes.resType;
			this.currentEditRow.rTypeName = costCodes.rTypeName;
			this.currentEditRow.isCostCodesValid = 'Y';

			this.currentEditRow.netCost = costCodes.netCost;
			this.currentEditRow.matchToDateNet = costCodes.matchToDateNet;
			this.currentEditRow.remainingAmount = costCodes.remainingAmount;
		}

		this.setState({ openCostCodesDialog: false });
	}

	changeVendorYes_clickHandler(event) {
		var invoiceDocument = this.state.invoiceDocument;

		if (invoiceDocument.modelDefId === 2) {
			invoiceDocument.poCode = "";
			invoiceDocument.isPOCodeValid = this.state.vendor.isNoPOVendor ? "Y" : "N";

			this.verifyProductionInvoiceDocumentDetails();
		}
		else {
			invoiceDocument.invoiceDocumentEditorDetails.map(function (detail, index) {
				detail.ioNumber = "";
				detail.poAmount = 0.00;
				detail.consumedAmount = 0.00;
				detail.deliveryAmount = 0.00;
			});
		}

		this.setState({ poHeaderLookup: [], invoiceDocument: invoiceDocument, openChangeVendorConfirmation: false });

		this.setVendor(this.state.vendor);
	}

	changeVendor_closeHandler(event) {
		this.setState({ openChangeVendorConfirmation: false });
	}

	replace_clickHandler(event) {
		this.setState({ openReplaceInvoiceDocumentEditorDialog: true });
	}

	refresh_clickHandler(event) {
		var that = this;
		var invoiceDocument = this.state.invoiceDocument;

		if (this.props.info.modelDefId === 1) {
			const ioNumbers = invoiceDocument.invoiceDocumentEditorDetails.map(item => item.ioNumber);
			const distinctIoNumbers = [...new Set(ioNumbers)];

			distinctIoNumbers.map(function (ioNumber) {
				if (!stringIsNullOrEmpty(ioNumber)) {
					that.getUpdatedData(null, ioNumber);
				}
			});
		}
		else if (this.props.info.modelDefId === 2 && !stringIsNullOrEmpty(invoiceDocument.poCode)) {
			this.getUpdatedData(null, invoiceDocument.poCode);
		}
	}

	collapseUncollapse_clickHandler(event) {
		var that = this;
		this.setState({ uncollapsed: !this.state.uncollapsed });

		setTimeout(function () { that.setState({ displayInvoiceDocumentViewer: that.state.uncollapsed ? "" : "none" }); }, 200);
	}

	getUpdatedData(row, poCode) {
		var that = this;
		var invoiceDocument = this.state.invoiceDocument;

		this.props.info.inProgress(true);

		httpGet("/api/invoicedocument/refetchpoinfo/" + this.props.info.modelDefId + '/' + poCode)
			.then((response) => {
				var data = response.data;

				if (that.props.info.modelDefId === 1) {
					invoiceDocument.invoiceDocumentEditorDetails.forEach(item => {
						if (!stringIsNullOrEmpty(item.ioNumber) && item.ioNumber.trim() === data.poCode.trim()) {
							item.poAmount = data.poAmount;
							item.consumedAmount = data.consumedAmount;
							item.deliveryAmount = data.deliveryAmount;
							item.isIONumberValid = 'Y';
							item.isIOUpdated = true;
						}
					});

					var item = that.currentEditRow;

					if (item && !stringIsNullOrEmpty(item.ioNumber) && item.ioNumber.trim() === data.poCode.trim()) {
						item.poAmount = data.poAmount;
						item.consumedAmount = data.consumedAmount;
						item.deliveryAmount = data.deliveryAmount;
						item.isIONumberValid = 'Y';
						item.isIOUpdated = true;
					}
				}
				else {
					invoiceDocument.invoiceDocumentEditorDetails.forEach(item => {
						item.netCost = data.poAmount;
						item.matchToDateNet = data.consumedAmount;
						item.remainingAmount = data.poAmount - data.consumedAmount;
						item.isPOCodeValid = 'Y';
					});

					var item = that.currentEditRow;

					if (item) {
						item.poAmount = data.poAmount;
						item.consumedAmount = data.consumedAmount;
						item.remainingAmount = data.poAmount - data.consumedAmount;
						item.isPOCodeValid = 'Y';
					}
				}

				this.props.info.inProgress(false);
				this.setState({ invoiceDocument: invoiceDocument });
			}).catch((error) => {
				this.props.info.inProgress(false);

				toast.error('Error fetching IO/PO info');
			});
	}

	verifyProductionInvoiceDocumentDetails() {
		var that = this;

		if (this.state.invoiceDocument.invoiceDocumentEditorDetails.length === 0) {
			return;
		}

		this.props.info.inProgress(true);

		httpPost("/api/invoicedocument/verifyproductioninvoicedocumenteditor/", this.state.invoiceDocument)
			.then((response) => {
				var data = response.data;

				var invoiceDocument = this.state.invoiceDocument;

				if (stringIsNullOrEmpty(invoiceDocument.poCode)) {
					invoiceDocument.invoiceDocumentEditorDetails.map(function (invoiceDocumentEditorDetail, index) {
						var row = data.find(d => d.seqId === invoiceDocumentEditorDetail.seqId);

						invoiceDocumentEditorDetail.level2Key = row.level2Key;
						invoiceDocumentEditorDetail.isLevel2KeyValid = row.isLevel2KeyValid;

						invoiceDocumentEditorDetail.level3Key = row.level3Key;
						invoiceDocumentEditorDetail.isLevel3KeyValid = row.isLevel3KeyValid;

						invoiceDocumentEditorDetail.costType = row.costType;
						invoiceDocumentEditorDetail.resType = row.resType;
						invoiceDocumentEditorDetail.rTypeName = row.rTypeName;
						invoiceDocumentEditorDetail.isCostCodesValid = row.isCostCodesValid;

						if (that.currentEditRow != null && that.currentEditRow.seqId === row.seqId) {
							that.currentEditRow.level2Key = row.level2Key;
							that.currentEditRow.isLevel2KeyValid = row.isLevel2KeyValid;

							that.currentEditRow.level3Key = row.level3Key;
							that.currentEditRow.isLevel3KeyValid = row.isLevel3KeyValid;

							that.currentEditRow.costType = row.costType;
							that.currentEditRow.resType = row.resType;
							that.currentEditRow.rTypeName = row.rTypeName;
							that.currentEditRow.isCostCodesValid = row.isCostCodesValid;
						}
					});
				}
				else {
					if (invoiceDocument.invoiceDocumentEditorDetails.length === 0) {
						data.map(function (poDetail, index) {
							var invoiceDocumentEditorDetail = poDetail;

							invoiceDocumentEditorDetail.invoiceDocumentId = invoiceDocument.invoiceDocumentId;
							invoiceDocumentEditorDetail.seqId = index + 1;
							invoiceDocumentEditorDetail.ioInvoiceAmount = poDetail.remainingAmount;

							invoiceDocument.invoiceDocumentEditorDetails.push(invoiceDocumentEditorDetail);
						});
					}
					else if (data.length === 1) {
						invoiceDocument.invoiceDocumentEditorDetails.map(function (invoiceDocumentEditorDetail, index) {
							invoiceDocumentEditorDetail.level2Key = data[0].level2Key;
							invoiceDocumentEditorDetail.isLevel2KeyValid = data[0].isLevel2KeyValid;

							invoiceDocumentEditorDetail.level3Key = data[0].level3Key;
							invoiceDocumentEditorDetail.isLevel3KeyValid = data[0].isLevel3KeyValid;

							invoiceDocumentEditorDetail.costType = data[0].costType;
							invoiceDocumentEditorDetail.resType = data[0].resType;
							invoiceDocumentEditorDetail.rTypeName = data[0].rTypeName;
							invoiceDocumentEditorDetail.isCostCodesValid = data[0].isCostCodesValid;

							invoiceDocumentEditorDetail.poRequiredFlag = data[0].poRequiredFlag;
							invoiceDocumentEditorDetail.tolerancePOFlag = data[0].tolerancePOFlag;
							invoiceDocumentEditorDetail.tolerancePOAmount = data[0].tolerancePOAmount;

							invoiceDocumentEditorDetail.netCost = data[0].netCost;
							invoiceDocumentEditorDetail.matchToDateNet = data[0].matchToDateNet;
							invoiceDocumentEditorDetail.remainingAmount = data[0].remainingAmount;
						});
					}
					else {
						invoiceDocument.invoiceDocumentEditorDetails.map(function (invoiceDocumentEditorDetail, index) {
							var verifiedRow = data.find(d => d.level2Key != null && d.level2Key === invoiceDocumentEditorDetail.level2Key &&
								d.level3Key != null && d.level3Key === invoiceDocumentEditorDetail.level3Key &&
								d.costType != null && d.costType === invoiceDocumentEditorDetail.costType &&
								d.resType != null && d.resType === invoiceDocumentEditorDetail.resType);

							if (verifiedRow != null) {
								return;
							}

							var defaultLevel2Key = "";
							var isLevel2KeyValid = 'N';
							var defaultPoRequiredFlag = 'N';
							var defaultTolerancePOFlag = 'N';
							var defaultTolerancePOAmount = 0.00;

							var defaultLevel3Key = "";
							var isLevel3KeyValid = 'N';

							var defaultCostType = null;
							var defaultResType = null;
							var defaultRTypeName = "";
							var isCostCodesValid = 'N';
							var defaultNetCost = 0.00;
							var defaultMatchToDateNet = 0.00;
							var defaultRemainingAmount = 0.00;

							var verifiedRows = data.filter(d => d.level2Key != null && d.level2Key === invoiceDocumentEditorDetail.level2Key);

							if (verifiedRows != null && verifiedRows.length > 0) {
								verifiedRow = verifiedRows[0];

								var distinctLevel2Keys = that.uniqueBy(verifiedRows, ["level2Key"]);

								if (distinctLevel2Keys.length === 1) {
									defaultLevel2Key = verifiedRow.level2Key;
									isLevel2KeyValid = 'Y';

									defaultPoRequiredFlag = verifiedRow.poRequiredFlag;
									defaultTolerancePOFlag = verifiedRow.tolerancePOFlag;
									defaultTolerancePOAmount = verifiedRow.tolerancePOAmount;
								}

								verifiedRows = verifiedRows.filter(idd => idd.level3Key != null && idd.level3Key === invoiceDocumentEditorDetail.level3Key);

								if (verifiedRows != null && verifiedRows.length > 0) {
									verifiedRow = verifiedRows[0];

									var distinctLevel3Keys = that.uniqueBy(verifiedRows, ["level3Key"]);

									if (distinctLevel3Keys.length === 1) {
										defaultLevel3Key = verifiedRow.level3Key;
										isLevel3KeyValid = 'Y';
									}

									verifiedRows = verifiedRows.filter(idd => idd.costType != null && invoiceDocumentEditorDetail.costType != null && idd.costType === invoiceDocumentEditorDetail.costType &&
										idd.resType != null && invoiceDocumentEditorDetail.resType != null && idd.resType === invoiceDocumentEditorDetail.resType);

									if (verifiedRows != null && verifiedRows.length > 0) {
										verifiedRow = verifiedRows[0];

										var distinctCostTypes = that.uniqueBy(verifiedRows, ["costType", "resType"]);

										if (distinctCostTypes.length === 1) {
											defaultCostType = verifiedRow.costType;
											defaultResType = verifiedRow.resType;
											defaultRTypeName = verifiedRow.rTypeName;
											isCostCodesValid = 'Y';

											defaultNetCost = verifiedRow.netCost;
											defaultMatchToDateNet = verifiedRow.matchToDateNet;
											defaultRemainingAmount = verifiedRow.remainingAmount;
										}
									}
									else {
										invoiceDocumentEditorDetail.costType = defaultCostType;
										invoiceDocumentEditorDetail.resType = defaultResType;
										invoiceDocumentEditorDetail.rTypeName = defaultRTypeName;
										invoiceDocumentEditorDetail.isCostCodesValid = isCostCodesValid;

										invoiceDocumentEditorDetail.netCost = defaultNetCost;
										invoiceDocumentEditorDetail.matchToDateNet = defaultMatchToDateNet;
										invoiceDocumentEditorDetail.remainingAmount = defaultRemainingAmount;
									}
								}
								else {
									invoiceDocumentEditorDetail.level3Key = defaultLevel3Key;
									invoiceDocumentEditorDetail.isLevel3KeyValid = isLevel3KeyValid;

									invoiceDocumentEditorDetail.costType = defaultCostType;
									invoiceDocumentEditorDetail.resType = defaultResType;
									invoiceDocumentEditorDetail.rTypeName = defaultRTypeName;
									invoiceDocumentEditorDetail.isCostCodesValid = isCostCodesValid;

									invoiceDocumentEditorDetail.netCost = defaultNetCost;
									invoiceDocumentEditorDetail.matchToDateNet = defaultMatchToDateNet;
									invoiceDocumentEditorDetail.remainingAmount = defaultRemainingAmount;
								}
							}
							else {
								invoiceDocumentEditorDetail.level2Key = defaultLevel2Key;
								invoiceDocumentEditorDetail.isLevel2KeyValid = isLevel2KeyValid;

								invoiceDocumentEditorDetail.poRequiredFlag = defaultPoRequiredFlag;
								invoiceDocumentEditorDetail.tolerancePOFlag = defaultTolerancePOFlag;
								invoiceDocumentEditorDetail.tolerancePOAmount = defaultTolerancePOAmount;

								invoiceDocumentEditorDetail.level3Key = defaultLevel3Key;
								invoiceDocumentEditorDetail.isLevel3KeyValid = isLevel3KeyValid;

								invoiceDocumentEditorDetail.costType = defaultCostType;
								invoiceDocumentEditorDetail.resType = defaultResType;
								invoiceDocumentEditorDetail.rTypeName = defaultRTypeName;
								invoiceDocumentEditorDetail.isCostCodesValid = isCostCodesValid;

								invoiceDocumentEditorDetail.netCost = defaultNetCost;
								invoiceDocumentEditorDetail.matchToDateNet = defaultMatchToDateNet;
								invoiceDocumentEditorDetail.remainingAmount = defaultRemainingAmount;
							}
						});
					}
				}

				this.setState({ invoiceDocument: invoiceDocument });

				this.props.info.inProgress(false);

			}).catch((error) => {
				this.props.info.inProgress(false);
				toast.error('Verification of invoice details failed');
			});
	}

	uniqueBy(arr, keyProps) {
		const kvArray = arr.map(entry => {
			const key = keyProps.map(k => entry[k]).join('|');
			return [key, entry];
		});

		const map = new Map(kvArray);

		return Array.from(map.values());
	}

	groupAndSum(arr, groupKeys, sumKeys) {
		return Object.values(
			arr.reduce((acc, curr) => {
				const group = groupKeys.map(k => curr[k]).join('-');
				acc[group] = acc[group] || Object.fromEntries(groupKeys.map(k => [k, curr[k]]).concat(sumKeys.map(k => [k, 0])));
				sumKeys.forEach(k => acc[group][k] += curr[k]);

				return acc;
			}, {})
		);
	}

	fetchLevel3(level2Key) {
		var that = this;
		var poCode = stringIsNullOrEmpty(this.state.invoiceDocument.poCode) ? "" : "/" + this.state.invoiceDocument.poCode;

		this.props.info.inProgress(true);

		httpGet("/api/lookup/level3list/" + level2Key + poCode)
			.then((response) => {
				var data = response.data;

				if (data.length === 1) {
					this.currentEditRow.level3Key = data[0].level3Key;
					this.currentEditRow.costType = data[0].costType;
					this.currentEditRow.isLevel3KeyValid = 'Y';

					that.fetchCostCodes(this.currentEditRow.level2Key, this.currentEditRow.level3Key);
				}

				this.props.info.inProgress(false);
			}).catch((error) => {
				this.props.info.inProgress(false);

				toast.error('Error fetching Activity');
			});
	}

	fetchCostCodes(level2Key, level3Key) {
		var poCode = stringIsNullOrEmpty(this.state.invoiceDocument.poCode) ? "" : "/" + this.state.invoiceDocument.poCode;

		this.props.info.inProgress(true);

		httpGet("/api/lookup/costcodeslist/" + level2Key + "/" + level3Key + poCode)
			.then((response) => {
				var data = response.data;

				if (data.length === 1) {
					this.currentEditRow.resType = data[0].resType;
					this.currentEditRow.rTypeName = data[0].rTypeName;
					this.currentEditRow.isCostCodesValid = 'Y';

					this.currentEditRow.netCost = data[0].netCost;
					this.currentEditRow.matchToDateNet = data[0].matchToDateNet;
					this.currentEditRow.remainingAmount = data[0].remainingAmount;
				}

				this.props.info.inProgress(false);
			}).catch((error) => {
				this.props.info.inProgress(false);

				toast.error('Error fetching Expense Type/Cost Codes');
			});
	}

	getFileUploadOrInvoiceDocumentViewer() {
		var returnValue = "";

		if (this.state.invoiceDocument.invoiceDocumentFileLocation && this.state.invoiceDocument.invoiceDocumentFileLocation.length > 0) {
			const invoiceViewerInfo = {
				inProgress: this.props.info.inProgress,
				modelDefId: this.props.info.modelDefId,
				invoiceDocumentFileLocation: this.state.invoiceDocument.invoiceDocumentFileLocation,
				inline: true
			}

			returnValue = <div><InvoiceDocumentViewer info={invoiceViewerInfo} /></div>
		}
		else {
			returnValue = <div className="file-drop-area">
				<label htmlFor="input-file">
					<div className="file-drop-container">
						<img src="images/drag-and-drop-icon.png" alt="" />
						<span className="file-msg">Drag & Drop Invoice Here <br /> or </span>
					</div>
					<input
						type="file"
						accept="application/pdf,image/jpg,image/jpeg,image/png"
						id="input-file"
						style={{ display: "none" }}
						onChange={this.fileUpload_changeHandler} />
					<div className="file-drop-browse">
						<label htmlFor="input-file">
							<Button variant="contained" color="default" component="span" size="large" startIcon={<CloudUploadIcon />}>Browse Invoice</Button>
						</label>
					</div>
				</label>
			</div>
		}

		return returnValue;
	}

	setVendor(vendor) {
		var target = { name: "vendorCode", value: vendor ? vendor.vendorCode : "" };
		this.input_changeHandler({ target: target, currentTarget: target });

		target = { name: "shortName", value: vendor ? vendor.shortName : "" };
		this.input_changeHandler({ target: target, currentTarget: target });

		target = { name: "vendor", value: vendor ? vendor.vendorCode + " - " + vendor.shortName : "" };
		this.input_changeHandler({ target: target, currentTarget: target });

		target = { name: "siteId", value: vendor ? vendor.siteId : "" };
		this.input_changeHandler({ target: target, currentTarget: target });

		if (vendor) {
			target = { name: "currencyCode", value: vendor ? vendor.currencyCode : "" };
			this.input_changeHandler({ target: target, currentTarget: target });
		}
		if (vendor && this.state.invoiceDocument.modelDefId === 2) {
			var invoiceDocument = this.state.invoiceDocument;

			invoiceDocument.noPoFlag = vendor.noPoFlag;
			invoiceDocument.isNoPOVendor = vendor.isNoPOVendor;

			this.setState({ invoiceDocument: invoiceDocument });
		}
		this.invoiceNumber_blurHandler(); //Validate invoice number for this vendor
	}

	isVendorSelected() {
		return !(stringIsNullOrEmpty(this.state.invoiceDocument.vendor));
	}

	isPOCodeValid() {
		var isValid = this.state.invoiceDocument.modelDefId === 2 ? false : true;

		if (this.state.invoiceDocument.modelDefId === 2) {
			if (this.isVendorSelected()) {
				isValid = (stringIsNullOrEmpty(this.state.invoiceDocument.poCode) && (this.state.invoiceDocument.invoiceDocumentEditorDetails.length === 0 || this.state.invoiceDocument.invoiceDocumentEditorDetails.every(d => d.poRequiredFlag === 'N')))
					|| (!stringIsNullOrEmpty(this.state.invoiceDocument.poCode) && this.state.invoiceDocument.isPOCodeValid === 'Y');

				//if (this.state.invoiceDocument.isNoPOVendor) {
				//	isValid = (stringIsNullOrEmpty(this.state.invoiceDocument.poCode) && (this.state.invoiceDocument.invoiceDocumentEditorDetails.length === 0 || this.state.invoiceDocument.invoiceDocumentEditorDetails.every(d => d.poRequiredFlag === 'N')))
				//		|| (!stringIsNullOrEmpty(this.state.invoiceDocument.poCode) && this.state.invoiceDocument.isPOCodeValid === 'Y');
				//}
				//else {
				//	isValid = (!stringIsNullOrEmpty(this.state.invoiceDocument.poCode) && this.state.invoiceDocument.isPOCodeValid === 'Y');
				//}
			}
		}
		return isValid;
	}

	isDueDateValid() {
		var isValid = stringIsNullOrEmpty(this.state.invoiceDocument.dueDateString) && stringIsNullOrEmpty(this.state.invoiceDocument.invoiceDateString) ? false : true;

		if (isValid) {
			var dueDate = new Date(this.state.invoiceDocument.dueDateString);
			var invoiceDate = new Date(this.state.invoiceDocument.invoiceDateString);
			isValid = dueDate >= invoiceDate;
		}
		return isValid;
	}

	isInvoiceNumberValid() {
		var isValid = !stringIsNullOrEmpty(this.state.invoiceDocument.invoiceNumber) && this.state.invoiceDocument.invoiceNumber.length <= 32;

		return isValid;
	}

	isServiceTermStartValid() {
		var isValid = !stringIsNullOrEmpty(this.state.invoiceDocument.serviceTermStartDateString);
		return isValid;
	}

	isServiceTermEndValid() {
		var isValid = !stringIsNullOrEmpty(this.state.invoiceDocument.serviceTermEndDateString);

		if (isValid) {
			var endDate = new Date(this.state.invoiceDocument.serviceTermEndDateString);
			var startDate = new Date(this.state.invoiceDocument.serviceTermStartDateString);
			isValid = endDate >= startDate;
		}
		return isValid;
	}

	isPOCodeNotEmptyAndValid() {
		return (!stringIsNullOrEmpty(this.state.invoiceDocument.poCode) && this.state.invoiceDocument.isPOCodeValid === 'Y')
	}

	isIONumberValid(rowData) {
		var isValid = true;

		if (this.state.invoiceDocument.modelDefId === 1 &&
			(stringIsNullOrEmpty(rowData.ioNumber) || (!stringIsNullOrEmpty(rowData.ioNumber) && rowData.isIONumberValid !== 'Y'))) {
			isValid = false;
		}

		return isValid;
	}

	isLevel2KeyValid(rowData) {
		var isValid = true;

		if (this.state.invoiceDocument.modelDefId === 2 &&
			(stringIsNullOrEmpty(rowData.level2Key) || (!stringIsNullOrEmpty(rowData.level2Key) && rowData.isLevel2KeyValid !== 'Y'))) {
			isValid = false;
		}

		return isValid;
	}

	isLevel3KeyValid(rowData) {
		var isValid = true;

		if (this.state.invoiceDocument.modelDefId === 2 &&
			(stringIsNullOrEmpty(rowData.level3Key) || (!stringIsNullOrEmpty(rowData.level3Key) && rowData.isLevel3KeyValid !== 'Y'))) {
			isValid = false;
		}

		return isValid;
	}

	isCostCodesValid(rowData) {
		var isValid = true;

		if (this.state.invoiceDocument.modelDefId === 2 &&
			(stringIsNullOrEmpty(rowData.resType) || (!stringIsNullOrEmpty(rowData.resType) && rowData.isCostCodesValid !== 'Y'))) {
			isValid = false;
		}

		return isValid;
	}
	isTaxCodeValid(newData) {
		var isValid = true;
		const invoiceDocument = this.state.invoiceDocument;
		if (invoiceDocument && invoiceDocument.invoiceDocumentEditorDetails) {
			var IOPO = invoiceDocument.invoiceDocumentEditorDetails;
			var invoiceDocumentEditorDetails = IOPO.filter(x => x.ioNumber == newData.ioNumber && x.taxCode != newData.taxCode);
			if (invoiceDocumentEditorDetails && invoiceDocumentEditorDetails.length > 1) {
				isValid = false;
			}
		}
		return isValid;
	}
	updateTaxCode(newData, invoiceDocument) {
		//const invoiceDocument = this.state.invoiceDocument;
		var IOPO = invoiceDocument.invoiceDocumentEditorDetails;
		var invoiceDocumentEditorDetails = IOPO.filter(x => x.ioNumber == newData.ioNumber && x.taxCode != newData.taxCode);
		for (const item of invoiceDocumentEditorDetails) {
			item.taxCode = newData.taxCode;
			this.calculateTaxAmount(newData.taxCode, item);
		}
	}
	isInvoiceAmountValid(rowData) {
		var isValid = false;

		// -ve values are also acceptable in invoice details
		if (this.state.invoiceDocument.modelDefId === 2) {
			isValid = rowData.ioInvoiceAmount != 0;

			if (isValid) {
				if (!stringIsNullOrEmpty(this.state.invoiceDocument.poCode)) {
					var invoiceDocumentEditorDetails = JSON.parse(JSON.stringify(this.state.invoiceDocument.invoiceDocumentEditorDetails));

					var index = invoiceDocumentEditorDetails.findIndex(d => d.seqId === rowData.seqId);

					if (index < 0) {
						invoiceDocumentEditorDetails.push(JSON.parse(JSON.stringify(rowData)));
					} else {
						invoiceDocumentEditorDetails[index] = JSON.parse(JSON.stringify(rowData));
					}

					invoiceDocumentEditorDetails[invoiceDocumentEditorDetails.findIndex(d => d.seqId === rowData.seqId)].ioInvoiceAmount = Number(Number(rowData.ioInvoiceAmount).toFixed(2));

					var groupedInvoiceDocumentDetails = this.groupAndSum(invoiceDocumentEditorDetails, ['level2Key', 'tolerancePOFlag', 'tolerancePOAmount', 'level3Key', 'resType', 'remainingAmount'], ['ioInvoiceAmount']);
					var groupedFilteredOnKeyFields = groupedInvoiceDocumentDetails.filter(d => d.level2Key === rowData.level2Key && d.level3Key === rowData.level3Key && d.resType === rowData.resType);
					isValid = !(groupedFilteredOnKeyFields.some(d => {
						var isInvoiceAmountInvalid = Number(zeroIfNull(d.ioInvoiceAmount).toFixed(2)) > Number(zeroIfNull(d.remainingAmount).toFixed(2));

						if (isInvoiceAmountInvalid) {
							if (d.tolerancePOFlag === 'N') {
								isInvoiceAmountInvalid = Number(zeroIfNull(d.ioInvoiceAmount).toFixed(2)) > Number((zeroIfNull(d.remainingAmount) + zeroIfNull(d.tolerancePOAmount)).toFixed(2));
							}
							else {
								var percentRemainingAmount = (zeroIfNull(d.tolerancePOAmount) * zeroIfNull(d.remainingAmount)) / 100.00;

								isInvoiceAmountInvalid = Number(Number(d.ioInvoiceAmount).toFixed(2)) > Number((d.remainingAmount + percentRemainingAmount).toFixed(2));
							}
						}

						return isInvoiceAmountInvalid;
					}));
				}
			}
		}
		else {

			if (this.currentEditRow !== null && this.currentEditRow.isIOUpdated && this.currentEditRow.seqId === rowData.seqId) {
				rowData.ioNumber = this.currentEditRow.ioNumber;
				rowData.poAmount = this.currentEditRow.poAmount;
				rowData.consumedAmount = this.currentEditRow.consumedAmount;
				rowData.deliveryAmount = this.currentEditRow.deliveryAmount;
				rowData.isIONumberValid = this.currentEditRow.isIONumberValid = 'Y';
				this.currentEditRow.isIOUpdated = false;
			}

			isValid = rowData.ioInvoiceAmount != 0;

			if (isValid) {
				var invoiceDocumentEditorDetails = JSON.parse(JSON.stringify(this.state.invoiceDocument.invoiceDocumentEditorDetails));

				var index = invoiceDocumentEditorDetails.findIndex(d => d.seqId === rowData.seqId);

				if (index < 0) {
					invoiceDocumentEditorDetails.push(JSON.parse(JSON.stringify(rowData)));
				} else {
					invoiceDocumentEditorDetails[index] = JSON.parse(JSON.stringify(rowData));
				}

				invoiceDocumentEditorDetails[invoiceDocumentEditorDetails.findIndex(d => d.seqId === rowData.seqId)].ioInvoiceAmount = Number(Number(rowData.ioInvoiceAmount).toFixed(2));

				var groupedInvoiceDocumentDetails = this.groupAndSum(invoiceDocumentEditorDetails, ['ioNumber', 'poAmount', 'consumedAmount'], ['ioInvoiceAmount']);
				var groupedFilteredOnKeyFields = groupedInvoiceDocumentDetails.filter(d => d.ioNumber === rowData.ioNumber);
				isValid = !(groupedFilteredOnKeyFields.some(d => {

					var remainingAmount = (Number(zeroIfNull(d.poAmount).toFixed(2)) - Number(zeroIfNull(d.consumedAmount).toFixed(2)));

					var isInvoiceAmountInvalid = Number(Number(zeroIfNull(d.ioInvoiceAmount)).toFixed(2)) > Number(remainingAmount.toFixed(2));

					return isInvoiceAmountInvalid;
				}));
			}
		}

		return isValid;
	}

	isInvoiceDocumentDetailValid() {
		var isValid = this.state.invoiceDocument.invoiceDocumentEditorDetails.length > 0;

		if (isValid) {
			if (this.state.invoiceDocument.modelDefId === 2) {
				isValid = this.state.invoiceDocument.invoiceDocumentEditorDetails.some(d =>
					this.isLevel2KeyValid(d) &&
					this.isLevel3KeyValid(d) &&
					this.isCostCodesValid(d) &&
					this.isInvoiceAmountValid(d));
			}
			else {
				isValid = !(this.state.invoiceDocument.invoiceDocumentEditorDetails.some(d => stringIsNullOrEmpty(d.ioNumber) || !this.isInvoiceAmountValid(d)));
			}
		}

		return isValid;
	}

	getRefreshButton() {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginRight: 15, marginTop: 22 }}
			disabled={this.state.invoiceDocument.modelDefId === 1 && !this.state.invoiceDocument.invoiceDocumentEditorDetails.some(d => !stringIsNullOrEmpty(d.ioNumber)) || this.state.invoiceDocument.modelDefId === 2 && stringIsNullOrEmpty(this.state.invoiceDocument.poCode)}>
			<RefreshIcon />
		</IconButton>;
	}

	getCollapseUncollapseButton() {
		return <IconButton
			variant="contained"
			onClick={this.collapseUncollapse_clickHandler}
			size="small">
			{this.state.uncollapsed ? <CollapseIcon /> : <UncollapseIcon />}
		</IconButton>;
	}

	calculateTaxAmount(taxCode, row) {
		row.taxAmount = 0.00;
		row.invoiceDocumentDetailTaxes = [];

		if (!stringIsNullOrEmpty(taxCode)) {
			var taxes = this.state.invoiceDocument.taxes.filter(t => t.taxCode === taxCode);

			taxes.forEach(tax => {
				row.invoiceDocumentDetailTaxes.push(
					{
						companyCode: row.companyCode,
						invoiceDocumentId: row.invoiceDocumentId,
						seqId: row.seqId,
						taxCode: tax.taxCode,
						taxTypeCode: tax.taxTypeCode,
						taxPrc: tax.otherTaxAmt,
						taxAmount: isNaN(row.ioInvoiceAmount) ? 0.00 : row.ioInvoiceAmount * (tax.otherTaxAmt / 100)
					});
			});

			var taxAmount = row.invoiceDocumentDetailTaxes.map(item => item.taxAmount).reduce((prev, next) => prev + next);
			row.taxAmount = taxAmount;
		}
	}
	RowAdd = newData =>
		new Promise((resolve, reject) => {
			setTimeout(() => {
				if (this.state.invoiceDocument.modelDefId !== 2) {
					var isTaxCodeValid = this.isTaxCodeValid(newData);
					if (this.isInvoiceAmountValid(newData)) {

						const invoiceDocument = this.state.invoiceDocument;

						newData.companyCode = invoiceDocument.companyCode;
						newData.invoiceDocumentId = invoiceDocument.invoiceDocumentId;
						newData.seqId = 0;
						newData.quantity = parseInt(newData.quantity, 10);
						newData.ioInvoiceAmount = parseFloat(newData.ioInvoiceAmount);

						newData.seqId = invoiceDocument.invoiceDocumentEditorDetails.length + 1;
						invoiceDocument.invoiceDocumentEditorDetails.push(newData);

						this.currentEditRow = null;
						if (!isTaxCodeValid) {
							toast.success("All IO Line(s) with same PO Code updated with new Tax.");
							this.updateTaxCode(newData, invoiceDocument);
						}
						this.setState({ invoiceDocument: invoiceDocument, isDirty: true }, () => resolve());


						resolve();
					}
					else {
						toast.error('Invoice Net Amount should be less than or equal to remaining amount: ' + formatNumber(newData.poAmount - newData.consumedAmount));
						reject();
					}
				}
				else {
					if (newData.quantity > 0 && newData.ioInvoiceAmount > 0) {
						var invoiceDocument = this.state.invoiceDocument;

						newData.companyCode = invoiceDocument.companyCode;
						newData.invoiceDocumentId = invoiceDocument.invoiceDocumentId;
						newData.seqId = 0;

						newData.costType = zeroIfNull(parseInt(newData.costType, 10));
						newData.resType = zeroIfNull(parseInt(newData.resType, 10));
						newData.quantity = zeroIfNull(parseInt(newData.quantity, 10));
						newData.ioInvoiceAmount = parseFloat(newData.ioInvoiceAmount);

						newData.seqId = invoiceDocument.invoiceDocumentEditorDetails.length + 1;

						if (this.isInvoiceAmountValid(newData)) {
							invoiceDocument.invoiceDocumentEditorDetails.push(newData);

							this.currentEditRow = null;

							this.setState({ invoiceDocument: invoiceDocument, isDirty: true }, () => resolve());

							resolve();
						}
						else {
							toast.error('Invoice Amount should be less than or equal to remaining amount');
							reject();
						}
					}
					else {
						toast.error('Invoice Quantity and Amount should be greater than 0');
						reject();
					}
				}
			}, 1000);
		});
	RowUpdate = (newData, oldData) =>
		new Promise((resolve, reject) => {
			setTimeout(() => {
				if (this.state.invoiceDocument.modelDefId !== 2) {
					var isTaxCodeValid = this.isTaxCodeValid(newData);
					if (this.isInvoiceAmountValid(newData)) {
						newData.quantity = parseInt(newData.quantity, 10);
						newData.ioInvoiceAmount = parseFloat(newData.ioInvoiceAmount);
						const invoiceDocument = this.state.invoiceDocument;
						
						this.currentEditRow = null;
						if (!isTaxCodeValid) {
							toast.success("All IO Line(s) with same PO Code updated with new Tax.");
							this.updateTaxCode(newData, invoiceDocument);
						}
						const index = invoiceDocument.invoiceDocumentEditorDetails.indexOf(oldData);
						invoiceDocument.invoiceDocumentEditorDetails[index] = newData;
						this.setState({ invoiceDocument: invoiceDocument, isDirty: true }, () => resolve());
						resolve();
					}
					else {
						toast.error('Invoice Net Amount should be less than or equal to remaining amount: ' + formatNumber(newData.poAmount - newData.consumedAmount));
						reject();
					}
				}
				else {
					if (newData.quantity > 0 && newData.ioInvoiceAmount > 0) {
						newData.costType = zeroIfNull(parseInt(newData.costType, 10));
						newData.resType = zeroIfNull(parseInt(newData.resType, 10));
						newData.quantity = zeroIfNull(parseInt(newData.quantity, 10));
						newData.ioInvoiceAmount = parseFloat(newData.ioInvoiceAmount);

						if (this.isInvoiceAmountValid(newData)) {
							var invoiceDocument = this.state.invoiceDocument;

							const index = invoiceDocument.invoiceDocumentEditorDetails.indexOf(oldData);
							invoiceDocument.invoiceDocumentEditorDetails[index] = newData;

							this.currentEditRow = null;

							this.setState({ invoiceDocument: invoiceDocument, isDirty: true }, () => resolve());

							resolve();
						}
						else {
							toast.error('Invoice Amount should be less than or equal to remaining amount');
							reject();
						}
					}
					else {
						toast.error('Invoice Quantity and Amount should be greater than 0');
						reject();
					}
				}
			}, 1000);
		});
	RowDelete = oldData =>
		new Promise((resolve, reject) => {
			setTimeout(() => {
				{
					const invoiceDocument = this.state.invoiceDocument;
					const index = invoiceDocument.invoiceDocumentEditorDetails.indexOf(oldData);
					invoiceDocument.invoiceDocumentEditorDetails.splice(index, 1);

					this.setState({ invoiceDocument: invoiceDocument, isDirty: true }, () => resolve());
				}

				resolve();
			}, 1000);
		});
	render() {
		if (!this.state.invoiceDocument) {
			return <div></div>
		}
		else {
			if (!this.state.invoiceDocument.paymentTerms) {
				this.state.invoiceDocument.paymentTerms = [];
			}

			if (!this.state.invoiceDocument.currencies) {
				this.state.invoiceDocument.currencies = [];
			}
		}

		const currencyCode = blankStringIfNullOrEmpty(this.state.invoiceDocument.currencyCode);
		const vendorDialogConfirmationMessage = this.state.invoiceDocument.modelDefId === 1 ? "Changing vendor will reset IO/PO Number from all invoice details, Do you wish to continue ?" : "Changing vendor will reset PO Code, Do you wish to continue ?";

		var info = this.props.info;

		info.openDialog = this.state.openVendorDialog;

		//info.invoiceModel = { vendorCode: this.state.invoiceDocument.vendorCode, siteId: this.state.invoiceDocument.siteId, currencyCode: currencyCode };
		info.invoiceModel = { vendorCode: this.state.invoiceDocument.vendorCode, siteId: this.state.invoiceDocument.siteId, currencyCode: "" };

		const poHeaderInfo = {
			inProgress: this.props.info.inProgress,
			openDialog: this.state.openPOHeaderDialog,
			vendorCode: this.state.invoiceDocument.vendorCode,
			siteId: this.state.invoiceDocument.siteId,
			serviceTermStartDate: this.state.invoiceDocument.serviceTermStartDate,
			serviceTermEndDate: this.state.invoiceDocument.serviceTermEndDate,
			modelDefId: this.state.invoiceDocument.modelDefId,
			poCode: this.state.invoiceDocument.modelDefId === 1 ? (this.currentEditRow ? this.currentEditRow.ioNumber : null) : this.state.invoiceDocument.poCode,
			invoiceDocumentId: this.state.invoiceDocument.invoiceDocumentId
		};

		const poHeaderResourceInfo = {
			inProgress: this.props.info.inProgress,
			openDialog: this.state.openPOHeaderResourceDialog,
			vendorCode: this.state.invoiceDocument.vendorCode,
			siteId: this.state.invoiceDocument.siteId
		};

		const level2ResourceInfo = {
			inProgress: this.props.info.inProgress,
			openDialog: this.state.openLevel2ResourceDialog,
			vendorCode: this.state.invoiceDocument.vendorCode,
			siteId: this.state.invoiceDocument.siteId
		};

		const level2Info = {
			inProgress: this.props.info.inProgress,
			openDialog: this.state.openLevel2Dialog,
			poCode: blankStringIfNullOrEmpty(this.state.invoiceDocument.poCode),
			noPoFlag: this.state.invoiceDocument.noPoFlag,
			level2Key: this.currentEditRow ? this.currentEditRow.level2Key : null
		};

		const level3Info = {
			inProgress: this.props.info.inProgress,
			openDialog: this.state.openLevel3Dialog,
			level2Key: this.currentEditRow ? this.currentEditRow.level2Key : null,
			poCode: blankStringIfNullOrEmpty(this.state.invoiceDocument.poCode),
			level3Key: this.currentEditRow ? this.currentEditRow.level3Key : null
		};

		const costCodesInfo = {
			inProgress: this.props.info.inProgress,
			openDialog: this.state.openCostCodesDialog,
			level2Key: this.currentEditRow ? this.currentEditRow.level2Key : null,
			level3Key: this.currentEditRow ? this.currentEditRow.level3Key : null,
			poCode: blankStringIfNullOrEmpty(this.state.invoiceDocument.poCode),
			resType: this.currentEditRow ? this.currentEditRow.resType : null
		};

		const invoiceDocumentRoutingHistoryInfo = {
			rowData: this.props.info.rowData,
			inProgress: this.props.info.inProgress,
			openDialog: this.state.openInvoiceDocumentRoutingHistoryDialog
		};

		const replaceInfo = {
			rowData: null,
			invoiceDocumentId: this.state.invoiceDocument.invoiceDocumentId,
			modelDefId: this.state.invoiceDocument.modelDefId,
			mode: "Replace",
			inProgress: this.props.info.inProgress
		};

		const cellStyle = { paddingLeft: 8, paddingRight: 28, textAlign: "right" };

		const displayAccept = this.props.info.mode === "Approval" ? "" : "none";
		const displayReject = this.props.info.mode === "Approval" && this.state.invoiceDocument.invoiceDocumentStatusId > 50 ? "" : "none";
		const displaySave = (this.state.readOnly || (this.props.info.mode === "Approval" && this.state.invoiceDocument.allowInvoiceEditing === false)) ? "none" : "";
		const displayReplace = (displaySave === "none" || this.props.info.mode === "UserEntry" || this.props.info.mode === "Replace") ? "none" : "";
		const isVendorSelected = this.isVendorSelected();
		const isPOCodeValid = this.isPOCodeValid();
		const isDueDateValid = this.isDueDateValid();
		const isInvoiceDocumentDetailValid = this.isInvoiceDocumentDetailValid();
		const isServiceTermStartValid = this.isServiceTermStartValid();
		const isServiceTermEndValid = this.isServiceTermEndValid();
		const isInvoiceNumberValid = this.isInvoiceNumberValid();

		const isSaveDisabled = (
			this.state.isDirty === false ||
			stringIsNullOrEmpty(this.state.invoiceDocument.invoiceDocumentFileLocation) ||
			(!isVendorSelected) ||
			stringIsNullOrEmpty(this.state.invoiceDocument.siteId) ||
			!isInvoiceNumberValid ||
			this.state.isInvoiceNumberDuplicate ||
			!isPOCodeValid ||
			!isDueDateValid ||
			stringIsNullOrEmpty(this.state.invoiceDocument.invoiceDateString) ||
			stringIsNullOrEmpty(this.state.invoiceDocument.dueDateString) ||
			stringIsNullOrEmpty(this.state.invoiceDocument.paymentTermCode) ||
			(this.state.invoiceDocument.modelDefId === 1 && (!isServiceTermStartValid || !isServiceTermEndValid)) ||
			stringIsNullOrEmpty(this.state.invoiceDocument.currencyCode) ||
			!isInvoiceDocumentDetailValid);

		const taxTotal = this.state.invoiceDocument.invoiceDocumentEditorDetails.length > 0 ? this.state.invoiceDocument.invoiceDocumentEditorDetails.map(item => item.taxAmount).reduce((prev, next) => prev + next) : 0.00;
		const grossTotal = (this.state.invoiceDocument.invoiceDocumentEditorDetails.length > 0 ? this.state.invoiceDocument.invoiceDocumentEditorDetails.map(item => item.ioInvoiceAmount).reduce((prev, next) => prev + next) : 0.00);
		const invoiceTotal = grossTotal + taxTotal;

		return (
			<div>
				<h1 style={{ marginLeft: 15 }}>{this.state.title}</h1>
				<div className="col-invoice-view">
					<div className="mainTable box-container" style={{ maxWidth: "none" }}>
						<Grid container justify="center" style={{ flexGrow: 1 }} spacing={1}>
							<Slide direction="right" in={this.state.uncollapsed} mountOnEnter>
								<Grid key={1} item xs={this.state.uncollapsed ? 6 : 1} style={{ display: this.state.displayInvoiceDocumentViewer }}>
									{this.getFileUploadOrInvoiceDocumentViewer()}
								</Grid>
							</Slide>
							<Grid key={2} item xs={this.state.uncollapsed ? 6 : 12}>
								<Grid item xs>
									{this.getCollapseUncollapseButton()}
								</Grid>
								<Grid item xs>
									<Grid container justify="center">
										<Grid item xs={10}>
											<div className="row-form" style={{ display: stringIsNullOrEmpty(this.state.invoiceDocument.fileName) ? "none" : "" }}>
												<div className="mf-10">
													<label>Invoice Document File:</label>
													<input type="text" name="fileName" tabIndex="1" readOnly={true} value={this.state.invoiceDocument.fileName + " " + new Date(this.state.invoiceDocument.createDate).toLocaleString()} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
												</div>
											</div>
											<div className="row-form">
												<div className="mf-10">
													<label>ERP Vendor/Site:</label>
													<div className="textBoxValidate searchFld">
														<input type="text" name="vendor" tabIndex="2" readOnly={true} value={stringIsNullOrEmpty(this.state.invoiceDocument.vendor) || stringIsNullOrEmpty(this.state.invoiceDocument.siteId) ? "" : this.state.invoiceDocument.vendor + " / " + this.state.invoiceDocument.siteId} />
														<img src="images/images-invoicemodelsetup/search-icon.png" alt="Search Vendor" style={{ display: this.state.readOnly ? "none" : "" }} onClick={this.search_clickHandler} />
													</div>
												</div>
												<div style={{ width: 5, padding: 5 }}>
													<span style={{ color: "red", visibility: stringIsNullOrEmpty(this.state.invoiceDocument.vendor) || stringIsNullOrEmpty(this.state.invoiceDocument.siteId) ? "visible" : "hidden" }}>*</span>
												</div>
											</div>
											<div className="row-form" style={{ display: (!this.state.invoiceDocument.documentModelName || !this.state.invoiceDocument.documentModelNameTag) || this.props.info.mode === "UserEntry" || this.props.info.mode === "Replace" ? "none" : "" }}>
												<div className="mf-10">
													<label>Invoice Model/Tag:</label>
													<input type="text" name="documentModelName" tabIndex="3" readOnly={true} value={this.state.invoiceDocument.documentModelName + " - " + this.state.invoiceDocument.documentModelNameTag} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
												</div>
											</div>
										</Grid>
										<Grid item xs={2}>
										</Grid>
									</Grid>
									<Grid container justify="flex-start">
										<Grid item xs>
											<div className="row-form">
												<div className="mf-10">
													<label>Invoice Number:</label>
													<input type="text" name="invoiceNumber" tabIndex="4" readOnly={this.state.readOnly} value={this.state.invoiceDocument.invoiceNumber} onChange={this.input_changeHandler} onBlur={this.invoiceNumber_blurHandler} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
													<span title={isInvoiceNumberValid && this.state.isInvoiceNumberDuplicate ? "Duplicate" : ""} style={{ color: "red", visibility: this.state.isInvoiceNumberDuplicate || !isInvoiceNumberValid ? "visible" : "hidden" }}>*</span>
												</div>
											</div>
											<div className="row-form" style={{ display: this.state.invoiceDocument.modelDefId === 2 ? "" : "none" }}>
												<div className="mf-10">
													<label>PO Code:</label>
													<div className="textBoxValidate searchFld">
														<input type="text" name="poCode" tabIndex="6" readOnly={true} value={stringIsNullOrEmpty(this.state.invoiceDocument.poCode) ? "" : this.state.invoiceDocument.poCode} />
														<img src="images/cross.png" alt="Remove PO Code" style={{ padding: 6, marginRight: 30, display: this.state.readOnly || this.state.invoiceDocument.modelDefId !== 2 || (!this.state.invoiceDocument.isNoPOVendor && isPOCodeValid) || stringIsNullOrEmpty(this.state.invoiceDocument.poCode) ? "none" : "" }} onClick={this.deletePOCode_clickHandler} />
														<img src="images/images-invoicemodelsetup/search-icon.png" alt="Search PO Code" style={{ display: this.state.readOnly || stringIsNullOrEmpty(this.state.invoiceDocument.vendor) || stringIsNullOrEmpty(this.state.invoiceDocument.siteId) ? "none" : "" }} onClick={this.searchIONumber_clickHandler} />
													</div>
												</div>
												<div style={{ width: 5, padding: 5 }}>
													<span style={{ color: "red", visibility: !isPOCodeValid ? "visible" : "hidden" }}>*</span>
												</div>
											</div>
											<div className="row-form">
												<div className="mf-10">
													<label>Invoice Date:</label>
													<input type="date" name="invoiceDateString" tabIndex="7" data-name="invoiceDate" readOnly={this.state.readOnly} value={this.state.invoiceDocument.invoiceDateString} onChange={this.onPaymentTermsChange} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
													<span style={{ color: "red", visibility: stringIsNullOrEmpty(this.state.invoiceDocument.invoiceDateString) ? "visible" : "hidden" }}>*</span>
												</div>
											</div>
											<div className="row-form">
												<div className="mf-10">
													<label htmlFor="paymentTerms">Payment Term:</label>
													<select id="paymentTermCode" name="paymentTermCode" tabIndex="9" disabled={this.state.readOnly} value={stringIsNullOrEmpty(this.state.invoiceDocument.paymentTermCode) ? "" : this.state.invoiceDocument.paymentTermCode} onChange={this.onPaymentTermsChange}>
														<option key="0" value={this.state.invoiceDocument.paymentTerms.length === 0 ? this.state.invoiceDocument.paymentTermCode : ""}>{this.state.invoiceDocument.paymentTerms.length === 0 ? this.state.invoiceDocument.paymentTermCode : ""}</option>
														{
															this.state.invoiceDocument.paymentTerms.map(function (item, index) {
																return <option key={index} value={item.termsCode}>{item.termsDesc}</option>
															})
														}
													</select>
												</div>
												<div style={{ width: 5, padding: 5 }}>
													<span style={{ color: "red", visibility: stringIsNullOrEmpty(this.state.invoiceDocument.paymentTermCode) ? "visible" : "hidden" }}>*</span>
												</div>
											</div>
											<div className="row-form" style={{ display: this.state.invoiceDocument.modelDefId === 2 ? "none" : "" }}>
												<div className="mf-10">
													<label>Service Term From:</label>
													<input type="date" name="serviceTermStartDateString" tabIndex="11" data-name="serviceTermStartDate" readOnly={this.state.readOnly} value={this.state.invoiceDocument.serviceTermStartDateString} onChange={this.input_changeHandler} onBlur={this.serviceTerm_blurHandler} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
													<span style={{ color: "red", visibility: !isServiceTermStartValid ? "visible" : "hidden" }}>*</span>
												</div>
											</div>
											<div className="row-form" style={{ display: this.state.invoiceDocument.masterDocumentModelId == null ? "none" : "" }}>
												<div className="mf-10">
													<label>Scanned Total:</label>
													<input type="text" name="scannedTotal" readOnly={true} value={formatNumber(this.state.invoiceDocument.scannedTotal)}
														style={{ textAlign: 'right' }} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
												</div>
											</div>
											<div className="row-form">
												<div className="mf-10">
													<label>Tax Total:</label>
													<input type="text" name="taxTotal" readOnly={true} value={formatNumber(taxTotal)} onChange={this.input_changeHandler} style={{ textAlign: 'right' }} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
												</div>
											</div>
										</Grid>
										<Grid item xs>
											<div className="row-form" style={{ visibility: this.props.info.mode === "UserEntry" || this.props.info.mode === "Replace" ? "hidden" : "visible" }}>
												<div className="mf-10">
													<label>Invoice Status:</label>
													<input type="text" name="status" tabIndex="5" readOnly={true} value={this.state.invoiceDocument.status} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
												</div>
											</div>
											<div className="row-form" style={{ display: this.state.invoiceDocument.modelDefId === 2 ? "" : "none" }}>
												<div className="mf-10">
													<label>&nbsp;</label>
													<div className="textBoxValidate searchFld">
													</div>
												</div>
												<div style={{ width: 5, padding: 5 }}>
												</div>
											</div>
											<div className="row-form">
												<div className="mf-10">
													<label>Due Date:</label>
													<input type="date" name="dueDateString" tabIndex="8" data-name="dueDate" readOnly={this.state.readOnly} value={this.state.invoiceDocument.dueDateString} onChange={this.input_changeHandler} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
													<span style={{ color: "red", visibility: !isDueDateValid ? "visible" : "hidden" }}>*</span>
												</div>
											</div>
											<div className="row-form">
												<div className="mf-10">
													<label htmlFor="currencies">Currency:</label>
													{/*<input type="text" id="currencyCode" name="currencyCode" tabIndex="10" readOnly={true} value={currencyCode} />*/}
													<select id="currencyCode" name="currencyCode" tabIndex="10" disabled={this.state.readOnly || !stringIsNullOrEmpty(this.state.invoiceDocument.vendor)} value={currencyCode} onChange={this.input_changeHandler}>
														<option key="0" value={this.state.invoiceDocument.currencies.length === 0 ? this.state.invoiceDocument.currencyCode : ""}>{this.state.invoiceDocument.currencies.length === 0 ? this.state.invoiceDocument.currencyCode : ""}</option>
														{
															this.state.invoiceDocument.currencies.map(function (item, index) {
																return <option key={index} value={item.currencyCode}>{item.currencyName}</option>
															})
														}
													</select>
												</div>
												<div style={{ width: 5, padding: 5 }}>
													<span style={{ color: "red", visibility: stringIsNullOrEmpty(this.state.invoiceDocument.currencyCode) ? "visible" : "hidden" }}>*</span>
												</div>
											</div>
											<div className="row-form" style={{ display: this.state.invoiceDocument.modelDefId === 2 ? "none" : "" }}>
												<div className="mf-10">
													<label>Service Term To:</label>
													<input type="date" name="serviceTermEndDateString" tabIndex="12" data-name="serviceTermEndDate" readOnly={this.state.readOnly} value={this.state.invoiceDocument.serviceTermEndDateString} onChange={this.input_changeHandler} onBlur={this.serviceTerm_blurHandler} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
													<span style={{ color: "red", visibility: !isServiceTermEndValid ? "visible" : "hidden" }}>*</span>
												</div>
											</div>
											<div className="row-form" style={{ display: this.state.invoiceDocument.masterDocumentModelId == null ? "none" : "" }}>
												<div className="mf-10">
													<label>Remaining Balance:</label>
													<input type="text" name="remainingBalance" readOnly={true} value={formatNumber(this.state.invoiceDocument.scannedTotal - grossTotal)} style={{ textAlign: 'right' }} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
												</div>
											</div>
											<div className="row-form">
												<div className="mf-10">
													<label>Invoice Total:</label>
													<input type="text" name="invoiceTotal" readOnly={true} value={formatNumber(invoiceTotal)} onChange={this.input_changeHandler} style={{ textAlign: 'right' }} />
												</div>
												<div style={{ width: 5, padding: 5 }}>
												</div>
											</div>
										</Grid>
									</Grid>
									<Grid container justify="center">
										<Grid item xs>
											<div style={{ marginTop: 10, marginBottom: 10 }}>
												{this.getRefreshButton()}
												<MaterialTable
													title=""
													key="seqId"
													columns={[
														{
															title: 'IO/PO Number',
															field: 'ioNumber',
															cellStyle: { padding: 8 },
															headerStyle: { width: 200 },
															hidden: this.state.invoiceDocument.modelDefId === 2,
															defaultGroupOrder: -1,
															render: rowData => <div>{rowData.ioNumber} <span style={{ color: this.isIONumberValid(rowData) ? "" : "red", display: this.isIONumberValid(rowData) ? "none" : "" }}>*</span></div>,
															editComponent: props => {
																this.currentEditRow = props.rowData;

																return <div className="iconContainer">
																	<div style={{ paddingRight: "50px" }}>{props.value} <span style={{ color: this.isIONumberValid(props.rowData) ? "" : "red", display: this.isIONumberValid(props.rowData) ? "none" : "" }}>*</span></div>
																	<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" onClick={this.searchIONumber_clickHandler}>
																		<SearchIcon />
																	</IconButton>
																</div>
															}
														},
														{
															title: 'IO/PO Description',
															field: 'ioDescription',
															cellStyle: { padding: 8 },
															headerStyle: { width: 250 },
															hidden: this.state.invoiceDocument.modelDefId === 2,
															grouping: false,
															editComponent: props => {
																var v = '';
																if (props && props.value)
																	v = props.value;
																return <div className="MuiFormControl-root MuiTextField-root">
																	<div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl" style={{ fontSize: 13, width: 200 }}>
																		<input
																			type="text"
																			aria-invalid="false"
																			placeholder="IO/PO Description"
																			value={v}
																			className="MuiInputBase-input MuiInput-input"
																			onChange={e => props.onChange(e.target.value)}
																		/>
																	</div>
																</div>
															}
														},
														{
															title: 'Project',
															field: 'level2Key',
															cellStyle: { padding: 8 },
															headerStyle: { width: 200 },
															hidden: this.state.invoiceDocument.modelDefId !== 2,
															defaultGroupOrder: -1,
															render: rowData => <div>{rowData.level2Key} <span style={{ color: this.isLevel2KeyValid(rowData) ? "" : "red", display: this.isLevel2KeyValid(rowData) ? "none" : "" }}>*</span></div>,
															editComponent: props => {
																this.currentEditRow = props.rowData;

																return <div className="iconContainer">
																	<div style={{ paddingRight: "50px" }}>{props.value} <span style={{ color: this.isLevel2KeyValid(props.rowData) ? "" : "red", display: this.isLevel2KeyValid(props.rowData) ? "none" : "" }}>*</span></div>
																	<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" onClick={this.searchLevel2_clickHandler}>
																		<SearchIcon />
																	</IconButton>
																</div>
															}
														},
														{
															title: 'Activity',
															field: 'level3Key',
															cellStyle: { padding: 8 },
															headerStyle: { width: 150 },
															hidden: this.state.invoiceDocument.modelDefId !== 2,
															defaultGroupOrder: -1,
															render: rowData => <div>{rowData.level3Key} <span style={{ color: this.isLevel3KeyValid(rowData) ? "" : "red", display: this.isLevel3KeyValid(rowData) ? "none" : "" }}>*</span></div>,
															editComponent: props => {
																this.currentEditRow = props.rowData;

																return <div className="iconContainer">
																	<div style={{ paddingRight: "50px" }}>{props.value} <span style={{ color: this.isLevel3KeyValid(props.rowData) ? "" : "red", display: this.isLevel3KeyValid(props.rowData) ? "none" : "" }}>*</span></div>
																	<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" style={{ display: this.isLevel2KeyValid(props.rowData) ? "" : "none" }} onClick={this.searchLevel3_clickHandler}>
																		<SearchIcon />
																	</IconButton>
																</div>
															}
														},
														{
															title: 'Expense Type',
															field: 'rTypeName',
															cellStyle: { padding: 8 },
															headerStyle: { width: 150 },
															hidden: this.state.invoiceDocument.modelDefId !== 2,
															defaultGroupOrder: -1,
															render: rowData => <div>{rowData.rTypeName} <span style={{ color: this.isCostCodesValid(rowData) ? "" : "red", display: this.isCostCodesValid(rowData) ? "none" : "" }}>*</span></div>,
															editComponent: props => {
																this.currentEditRow = props.rowData;

																return <div className="iconContainer">
																	<div style={{ paddingRight: "50px" }}>{props.value} <span style={{ color: this.isCostCodesValid(props.rowData) ? "" : "red", display: this.isCostCodesValid(props.rowData) ? "none" : "" }}>*</span></div>
																	<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" style={{ display: this.isLevel3KeyValid(props.rowData) ? "" : "none" }} onClick={this.searchCostCodes_clickHandler}>
																		<SearchIcon />
																	</IconButton>
																</div>
															}
														},
														{
															title: 'Quantity',
															field: 'quantity',
															type: 'numeric',
															headerStyle: {
																textAlign: 'right',
																paddingRight: 15,
																width: 50
															},
															hidden: this.state.invoiceDocument.modelDefId !== 2,
															grouping: false,
															render: rowData => <div>{rowData.quantity} <span style={{ color: "", display: "none" }}>*</span></div>
														},
														{
															title: 'Tax Code',
															field: 'taxCode',
															cellStyle: { padding: 8 },
															headerStyle: { width: 110 },
															grouping: false,
															render: rowData => {
																var taxDesc = "";
																if (this.state.taxes.length > 0) {
																	var tax = this.state.taxes.filter(x => x.taxCode == rowData.taxCode)[0];
																	if (tax) {
																		taxDesc = tax.taxDesc;
																	}
																}
																return <div>{taxDesc}</div>;
															},
															editComponent: props => {
																this.currentEditRow = props.rowData;

																return <select id="ddlTaxes" name="taxCode" style={{ minWidth: 100 }} data-seqid={props.rowData.seqId} disabled={this.state.readOnly} value={props.rowData.taxCode} onChange={this.onTaxCodeChange}>
																	<option key="0" value=""></option>
																	{
																		this.state.taxes.map(function (item, index) {
																			return <option key={index} value={item.taxCode}>{item.taxDesc}</option>
																		})
																	}
																</select>
															}
														},
														{
															title: 'Tax Amount',
															field: 'taxAmount',
															type: 'currency',
															cellStyle: cellStyle,
															headerStyle: {
																textAlign: 'right',
																width: 110
															},
															grouping: false,
															render: rowData => formatNumber(rowData.taxAmount),
															editComponent: props => { return formatNumber(props.value); }
														},
														{
															title: this.state.invoiceDocument.modelDefId === 2 ? 'Amount' : 'Invoice Net Amount',
															field: 'ioInvoiceAmount',
															type: 'currency',
															cellStyle: cellStyle,
															headerStyle: {
																textAlign: 'right',
																width: 110
															},
															grouping: false,
															render: rowData => formatNumber(rowData.ioInvoiceAmount),
															editComponent: props => (
																<div className="MuiFormControl-root MuiTextField-root">
																	<div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
																		<input
																			type="text"
																			aria-invalid="false"
																			placeholder={this.state.invoiceDocument.modelDefId === 2 ? 'Amount' : 'Invoice Net Amount'}
																			value={props.value}
																			className="MuiInputBase-input MuiInput-input"
																			style={{ fontSize: 13, textAlign: 'right' }}
																			onBlur={e => { this.onIoInvoiceAmountBlur(e, props); }}
																			onChange={e => props.onChange(e.target.value)}
																		/>
																	</div>
																</div>
															)
														},
														{
															title: 'PO Amount',
															field: 'netCost',
															type: 'currency',
															cellStyle: cellStyle,
															headerStyle: {
																textAlign: 'right',
																width: 80
															},
															hidden: this.state.invoiceDocument.modelDefId !== 2 || !this.isPOCodeNotEmptyAndValid(),
															grouping: false,
															render: rowData => formatNumber(rowData.netCost),
															editComponent: props => { return formatNumber(props.value); }
														},
														{
															title: 'Consumed Amount',
															field: 'matchToDateNet',
															type: 'currency',
															cellStyle: cellStyle,
															headerStyle: {
																textAlign: 'right',
																width: 80
															},
															hidden: this.state.invoiceDocument.modelDefId !== 2 || !this.isPOCodeNotEmptyAndValid(),
															grouping: false,
															render: rowData => formatNumber(rowData.matchToDateNet),
															editComponent: props => { return formatNumber(props.value); }
														},
														{
															title: 'Remaining Amount',
															field: 'remainingAmount',
															type: 'currency',
															cellStyle: cellStyle,
															headerStyle: {
																textAlign: 'right',
																width: 80
															},
															hidden: this.state.invoiceDocument.modelDefId !== 2 || !this.isPOCodeNotEmptyAndValid(),
															grouping: false,
															render: rowData => formatNumber(rowData.remainingAmount),
															editComponent: props => { return (props.value); }
														},
														{
															title: 'IO/PO Amount',
															field: 'poAmount',
															type: 'currency',
															cellStyle: cellStyle,
															headerStyle: {
																textAlign: 'right',
																width: 80
															},
															hidden: this.state.invoiceDocument.modelDefId === 2,
															grouping: false,
															render: rowData => formatNumber(rowData.poAmount),
															editComponent: props => { return formatNumber(props.value); }
														},
														{
															title: 'Consumed Amount',
															field: 'consumedAmount',
															type: 'currency',
															cellStyle: cellStyle,
															headerStyle: {
																textAlign: 'right',
																width: 80
															},
															hidden: this.state.invoiceDocument.modelDefId === 2,
															grouping: false,
															render: rowData => formatNumber(rowData.consumedAmount),
															editComponent: props => { return formatNumber(props.value); }
														},
														{
															title: 'Delivery Amount',
															field: 'deliveryAmount',
															type: 'currency',
															cellStyle: cellStyle,
															headerStyle: {
																textAlign: 'right',
																width: 80
															},
															hidden: this.state.invoiceDocument.modelDefId === 2,
															grouping: false,
															render: rowData => formatNumber(rowData.deliveryAmount),
															editComponent: props => { return formatNumber(props.value); }
														}
													]}
													data={this.state.invoiceDocument.invoiceDocumentEditorDetails}
													options={{
														actionsColumnIndex: -1,
														pageSize: 5,
														pageSizeOptions: [5, 10, 20, 30, 40, 50],
														maxBodyHeight: 340,
														headerStyle: { width: 20 },
														searchFieldStyle: { padding: 8 },
														grouping: true,
														selection: true,
														showTextRowsSelected: false,
														selectionProps: rowData => ({
															color: 'default'
														})
													}}
													editable={this.state.readOnly || (!isVendorSelected) ? null :
														{
															onRowAdd: this.RowAdd,
															onRowUpdate: this.RowUpdate,
															onRowDelete: this.RowDelete
														}}
													/*actions={[
															{
																icon: "add",
																tooltip: "Add IO/PO",
																isFreeAction: true,
																onClick: (event, rowData) => {
																	// Perform add operation
																}
															},
															{
																icon: 'edit',
																tooltip: 'Edit User',
																hidden: true,
																onClick: (event, rowData) => {
																	// Perform edit operation
																}
															},
															{
																icon: 'delete',
																tooltip: 'Delete User',
																disabled: true,
																onClick: (event, rowData) => {
																	// Perform delete operation
																}
															}
														]}
														// Actions
														actions={[
															rowData => ({
																icon: 'edit',
																tooltip: "Edit row",
																onClick: (event, rowData) => {
																	// console.log(inputRefs.current[inc].current)
																	rowData.tableData.editing = 'update'
																	//console.log(inputRefs)
																	// setInc(0)
																	// inputRefs.current[inc].current.focus()
																	// alert(`\n${JSON.stringify(rowData)}`);
	
																	//this.forceUpdate();
																}
															})
														]}*/
													components={{
														/*EditRow: props => {
															return (
																<MTableEditRow
																	{...props}
																	onKeyDown={(e) => {
																		if (e.keyCode === 27) {
																			props.onEditingCanceled(props.mode, props.data)
																		}
																	}}
																	onEditingCanceled={(mode, rowData) => {
																		rowData.tableData.editing = undefined;
																		//this.forceUpdate();
																	}}
																	onEditingApproved={(mode, newData, oldData) => {

																		this.RowUpdate(newData, oldData);
																	}}
																/>
															);
														},
															Row: props => (
																<MTableBodyRow
																	{...props}
																	onDoubleClick={(e) => {
																		console.log(props.actions) // <---- HERE : Get all the actions
																		props.actions[1]().onClick(e, props.data); // <---- trigger edit event
																	}}
																/>
															),*/
														Toolbar: props => {
															return isVendorSelected ? <MTableToolbar {...props} /> :
																<div style={{ padding: 10 }}>
																	<span style={{ color: "red" }}>Please select vendor</span>
																</div>
														},
														Groupbar: props => (null),
														GroupRow: props => (
															<MTableGroupRow {...props} />
														)

													}}
												/>
											</div>
											<div className="row-form" style={{ display: displayAccept }} >
												<div className="mf-12 field_full-7">
													<label>{displayReject === "" ? "Approval/Rejection Comments:" : "Approval Comments:"}</label>
													<textarea name="comments" onChange={this.input_changeHandler}></textarea>
												</div>
											</div>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<div className="floatingButtons">
							<div className="row-form row-btn">
								<div className="mf-12">
									<button className="btn-blue" style={{ display: displayReplace }} onClick={this.replace_clickHandler}>Replace Invoice</button>
									<button className="btn-blue" style={{
										display: this.state.readOnly === false &&
											this.props.info.mode !== "Approval" &&
											this.props.info.mode !== "UserEntry" &&
											(this.isAdmin || this.state.invoiceDocument.routedToResourceId == null || this.state.invoiceDocument.routedToResourceId === this.resourceId) &&
											!stringIsNullOrEmpty(this.state.invoiceDocument.invoiceDocumentFileLocation) ? "" : "none"
									}} disabled={!isVendorSelected} onClick={this.routeToEmployee_clickHandler}>Route to Employee</button>
									<button className="btn-blue" style={{ display: displayAccept }} onClick={this.accept_clickHandler}>{this.state.invoiceDocument.acceptName}</button>
									<button className="btn-blue" style={{ display: displayReject }} onClick={this.reject_clickHandler}>{this.state.invoiceDocument.rejectName}</button>
									<button className="btn-blue" style={{ display: this.props.info.rowData && this.props.info.rowData.routingHistoryCount > 0 ? "" : "none" }} onClick={this.routingHistory_clickHandler}>View Approval History</button>
									<button className="btn-blue" style={{ display: displaySave }} disabled={isSaveDisabled} onClick={this.save_clickHandler}>Save</button>
									<button className="btn-grey" onClick={this.close_clickHandler}>Close </button>
								</div>
							</div>
						</div>
					</div>
					<VendorLookup info={info} onClose={this.vendorDialog_closeHandler} />

					<POHeaderLookup info={poHeaderInfo} onClose={this.poHeaderDialog_closeHandler} />
					<POHeaderResourceLookup info={poHeaderResourceInfo} onClose={this.poHeaderResourceDialog_closeHandler} />
					<Level2ResourceLookup info={level2ResourceInfo} onClose={this.level2ResourceDialog_closeHandler} />
					<Level2Lookup info={level2Info} onClose={this.level2Dialog_closeHandler} />
					<Level3Lookup info={level3Info} onClose={this.level3Dialog_closeHandler} />
					<CostCodesLookup info={costCodesInfo} onClose={this.costCodesDialog_closeHandler} />
					<InvoiceDocumentRoutingHistory info={invoiceDocumentRoutingHistoryInfo} onClose={this.invoiceDocumentRoutingHistoryDialog_closeHandler} />

					<Dialog
						open={this.state.openChangeVendorConfirmation}
						aria-labelledby="draggable-dialog-title"
					>
						<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
							Change vendor on invoice
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								{vendorDialogConfirmationMessage}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.changeVendorYes_clickHandler} color="primary">
								Yes
							</Button>
							<Button autoFocus onClick={this.changeVendor_closeHandler} color="primary">
								No
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={this.state.openReplaceFileScanInProgress}
						aria-labelledby="draggable-dialog-title"
					>
						<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
							Invoice Replacement
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								On file selection, the existing invoice will be replaced and the selected file will be uploaded for scanning
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.replaceFileInfoDialog_clickHandler} color="primary">
								Ok
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog open={this.state.openReplaceInvoiceDocumentEditorDialog} TransitionComponent={Transition} fullScreen>
						<InvoiceDocumentEditor info={replaceInfo} onClose={this.close_clickHandler} />
					</Dialog>
				</div>
			</div>
		);
	}
}
