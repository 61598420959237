import * as React from 'react';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';

export default class InvoicesPendingPostReport extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: ""
		};
	}

	componentDidMount() {
		this.fetchVendors();
	}

	fetchVendors() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/vendors')
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				that.setState({ data: data.records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		return (
			<div>
				<h1>Vendors</h1>
				<div className="form-group">
					<MaterialTable
						title=""
						columns={[
						{
							title: 'Site ID',
							field: 'siteId'
						},
						{
							title: 'Code',
							field: 'vendorCode'
						},
						{
							title: 'Name',
							field: 'vendorName',
							width: 300
						},
						{
							title: 'Short Name',
							field: 'vendorName',
							width: 300
						},
						{
							title: 'Vendor Address',
							field: 'vendorAddress',
							width: 500
						}
					]}
						options={{
						pageSize: 10,
						pageSizeOptions: [10, 20, 30, 40, 50],
						maxBodyHeight: 670,
						columnsButton: true,
						exportButton: true,
						exportAllData: true,
						exportFileName: "Vendors"
					}}
						data={this.state.data}
					/>
				</div>
			</div>
		);
	}
}
