import * as React from 'react';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table'
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import { toast } from 'react-toastify';

export default class PaymentTermsMaps extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			isDirty: false
		};

		this.paymentTermsMaps_cancelHandler = this.paymentTermsMaps_cancelHandler.bind(this);
		this.paymentTermsMaps_saveHandler = this.paymentTermsMaps_saveHandler.bind(this);

	}

	componentDidMount() {
		this.fetchPaymentTermsMaps();
	}

	paymentTermsMaps_cancelHandler(event) {
		if (this.props.onClose) {
			this.props.onClose(event);
		}
	}

	paymentTermsMaps_saveHandler(event) {
		var data = {
			termsCode: this.props.info.termsCode,
			paymentTermsMaps: this.state.data
		};

		this.props.info.inProgress(true);

		httpPost("/api/setup/savepaymenttermsmaps/", data)
			.then((response) => {
				toast.success('Updated Successfully');
				this.props.info.inProgress(false);
				this.paymentTermsMaps_cancelHandler(null);
			}).catch((error) => {
				this.props.info.inProgress(false);
				toast.error(<div>Payment Terms Update Failed<br />{error.response.data.detail}</div>);
			});
	}

	fetchPaymentTermsMaps() {
		var that = this;

		this.props.info.inProgress(true);

		httpGet('/api/setup/paymenttermsmaps/' + this.props.info.termsCode)
			.then((response) => {
				var data = response.data;

				that.setState({ data: data });

				this.props.info.inProgress(false);
			})
			.catch((error) => {
				this.props.info.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		return (
			<div>
				<h1>Payment Terms Mappings</h1>
				<div className="form-group">
					<MaterialTable
						title=""
						columns={[
							{
								title: 'Invoice Terms Code',
								field: 'apamInvoiceTermsCode'
							}
						]}
						options={{
							actionsColumnIndex: -1,
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670
						}}
						data={this.state.data}
						editable={
							{
								onRowAdd: newData =>
									new Promise((resolve, reject) => {
										const paymentTermsMaps = this.state.data;

										newData.termsCode = this.props.info.termsCode;
										paymentTermsMaps.push(newData);

										this.setState({ data: paymentTermsMaps, isDirty: true }, () => resolve());
									}),
								onRowUpdate: (newData, oldData) =>
									new Promise((resolve, reject) => {
										const paymentTermsMaps = this.state.data;
										const index = paymentTermsMaps.indexOf(oldData);
										paymentTermsMaps[index] = newData;

										this.setState({ data: paymentTermsMaps, isDirty: true }, () => resolve());
									}),
								onRowDelete: oldData =>
									new Promise((resolve, reject) => {
										const paymentTermsMaps = this.state.data;
										const index = paymentTermsMaps.indexOf(oldData);
										paymentTermsMaps.splice(index, 1);

										this.setState({ data: paymentTermsMaps, isDirty: true }, () => resolve());
									}),
							}}
					/>
				</div>
				<Button color="primary" onClick={this.paymentTermsMaps_cancelHandler} style={{ float: "right" }}>
					Cancel
				</Button>
				<Button autoFocus color="primary" disabled={!this.state.isDirty} onClick={this.paymentTermsMaps_saveHandler} style={{ float: "right" }}>
					Save
				</Button>
			</div>
		);
	}
}
