import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { logoutUser } from '../../store/action/loginAction';
import DashboardMenu from './dashboardMenu';
import { stringIsNullOrEmpty } from '../Common/Utilities';

class TopMenu extends Component {
	constructor(props) {
		super(props);

		this.logoutHandler = this.logoutHandler.bind(this);
	}

	logoutHandler() {

		//event.preventDefault()

		const ssoType = localStorage.ssoType;
		const token = localStorage.token;

		localStorage.removeItem("ssoType");
		localStorage.removeItem("token");
		localStorage.removeItem("tokenExpiry");
		localStorage.removeItem("authenticatedUser");
		localStorage.removeItem("userInfo");

		if (stringIsNullOrEmpty(ssoType)) {
			this.props.logoutUser({});
			this.props.tokenHandler(token);

			window.location.pathname = "/login";
		}
		else {
			window.location.pathname = "/ssoauthentication/logout";
		}
	}

	render() {
		return (
			<div className="top-menu">
				<ul className="nav navbar-nav pull-right">

					{/*<FavoriteMenu RenderHeader={this.props.RenderHeader} />*/}

					<li className="droddown dropdown-separator">
						<span className="separator"></span>
					</li>

					<DashboardMenu />

					<li className="droddown dropdown-separator">
						<span className="separator"></span>
					</li>

					<li className="dropdown dropdown-extended dropdown-tasks dropdown-dark marginTop10">
						<span className="dropdown-toggle non-auth" >
							<i className="fa fa-sign-out" title="Logout" onClick={this.logoutHandler}></i>
						</span>
					</li>

					<li className="dropdown dropdown-extended dropdown-inbox dropdown-dark" style={{ display: "none" }} id="header_inbox_bar">
						<a href="#" className="dropdown-toggle non-auth" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
							<span className="circle">3</span>
							<span className="corner"></span>
						</a>
						<ul className="dropdown-menu">
							<li className="external">
								<h3>You have
                                                        <strong>7 New</strong> Messages</h3>
								<a href="app_inbox.html">view all</a>
							</li>
							<li>

								<div className="slimScrollDiv" style={{ position: "relative", overflow: "hidden", width: "auto", height: "250px" }}><ul className="dropdown-menu-list scroller" style={{ overflow: "hidden", width: "auto", height: "250px" }}>
									<li>
										<a href="#">
											<span className="photo">
											</span>
											<span className="subject">
												<span className="from"> Lisa Wong </span>
												<span className="time">Just Now </span>
											</span>
											<span className="message"> Vivamus sed auctor nibh congue nibh. auctor nibh auctor nibh... </span>
										</a>
									</li>
									<li>
										<a href="#">
											<span className="photo">
											</span>
											<span className="subject">
												<span className="from"> Richard Doe </span>
												<span className="time">16 mins </span>
											</span>
											<span className="message"> Vivamus sed congue nibh auctor nibh congue nibh. auctor nibh auctor nibh... </span>
										</a>
									</li>
									<li>
										<a href="#">
											<span className="photo">
											</span>
											<span className="subject">
												<span className="from"> Bob Nilson </span>
												<span className="time">2 hrs </span>
											</span>
											<span className="message"> Vivamus sed nibh auctor nibh congue nibh. auctor nibh auctor nibh... </span>
										</a>
									</li>
									<li>
										<a href="#">
											<span className="photo">
											</span>
											<span className="subject">
												<span className="from"> Lisa Wong </span>
												<span className="time">40 mins </span>
											</span>
											<span className="message"> Vivamus sed auctor 40% nibh congue nibh... </span>
										</a>
									</li>
									<li>
										<a href="#">
											<span className="photo">
											</span>
											<span className="subject">
												<span className="from"> Richard Doe </span>
												<span className="time">46 mins </span>
											</span>
											<span className="message"> Vivamus sed congue nibh auctor nibh congue nibh. auctor nibh auctor nibh... </span>
										</a>
									</li>
								</ul>
									<div className="slimScrollBar" style={{ background: "rgb(234, 234, 234)", width: "12px", position: "absolute", top: "0px", opacity: "0.2", display: "block", borderRadius: "7px", zIndex: "99", right: "1px" }}></div>
									<div className="slimScrollRail" style={{ width: "12px", height: "100%", position: "absolute", top: "0px", display: "none", borderRadius: "7px", background: "rgb(99, 114, 131)", opacity: "0.2", zIndex: "90", right: "1px" }}></div></div>

							</li>
						</ul>
					</li>
					<li className="dropdown dropdown-extended dropdown-notification dropdown-dark" id="header_notification_bar" style={{ display: "none" }}>
						<a href="#" className="dropdown-toggle non-auth" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">

							<i className="fa fa-star-o fav-icon-no" aria-hidden="true"></i>
							<span className="badge badge-default">7</span>
						</a>
						<ul className="dropdown-menu">
							<li className="external">
								<h3>You have
                                                        <strong>12 pending</strong> tasks</h3>
								<a href="app_todo.html">view all</a>
							</li>
							<li>

								<div className="slimScrollDiv" style={{ position: "relative", overflow: "hidden", width: "auto", height: "250px" }}><ul className="dropdown-menu-list scroller" style={{ overflow: "hidden", width: "auto", height: "250px" }}>
									<li>
										<a href="#">
											<span className="time">just now</span>
											<span className="details">
												<span className="label label-sm label-icon label-success">
													<i className="fa fa-plus"></i>
												</span> New user registered. </span>
										</a>
									</li>
									<li>
										<a href="#">
											<span className="time">3 mins</span>
											<span className="details">
												<span className="label label-sm label-icon label-danger">
													<i className="fa fa-bolt"></i>
												</span> Server #12 overloaded. </span>
										</a>
									</li>
									<li>
										<a href="#">
											<span className="time">10 mins</span>
											<span className="details">
												<span className="label label-sm label-icon label-warning">
													<i className="fa fa-bell-o"></i>
												</span> Server #2 not responding. </span>
										</a>
									</li>
									<li>
										<a href="#">
											<span className="time">14 hrs</span>
											<span className="details">
												<span className="label label-sm label-icon label-info">
													<i className="fa fa-bullhorn"></i>
												</span> Application error. </span>
										</a>
									</li>
									<li>
										<a href="#">
											<span className="time">2 days</span>
											<span className="details">
												<span className="label label-sm label-icon label-danger">
													<i className="fa fa-bolt"></i>
												</span> Database overloaded 68%. </span>
										</a>
									</li>
									<li>
										<a href="#">
											<span className="time">3 days</span>
											<span className="details">
												<span className="label label-sm label-icon label-danger">
													<i className="fa fa-bolt"></i>
												</span> A user IP blocked. </span>
										</a>
									</li>
									<li>
										<a href="#">
											<span className="time">4 days</span>
											<span className="details">
												<span className="label label-sm label-icon label-warning">
													<i className="fa fa-bell-o"></i>
												</span> Storage Server #4 not responding dfdfdfd. </span>
										</a>
									</li>
									<li>
										<a href="#">
											<span className="time">5 days</span>
											<span className="details">
												<span className="label label-sm label-icon label-info">
													<i className="fa fa-bullhorn"></i>
												</span> System Error. </span>
										</a>
									</li>
									<li>
										<a href="#">
											<span className="time">9 days</span>
											<span className="details">
												<span className="label label-sm label-icon label-danger">
													<i className="fa fa-bolt"></i>
												</span> Storage server failed. </span>
										</a>
									</li>
								</ul><div className="slimScrollBar" style={{ background: "rgb(234, 234, 234)", width: "12px", position: "absolute", top: "0px", opacity: "0.2", display: "block", borderRadius: "7px", zIndex: "99", right: "1px" }}></div><div className="slimScrollRail" style={{ width: "12px", height: "100%", position: "absolute", top: "0px", display: "none", borderRadius: "7px", background: "rgb(99, 114, 131)", opacity: "0.2", zIndex: "90", right: "1px" }}></div></div>

							</li>
						</ul>
					</li>




				</ul >
			</div >
		);
	}
}
function mapStateToProps(state) {

	return ({
		users: state.rootReducer.currentUser
	})
}
const mapDispatchToProps = dispatch => ({
	logoutUser: (updatedValue) => {

		dispatch(logoutUser(updatedValue));
	}
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopMenu));