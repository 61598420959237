import * as React from 'react';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Zoom from '@material-ui/core/Zoom';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class InvoiceDocumentRoutingHistory extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: []
		};

		this.inProgress = false;

		this.close_clickHandler = this.close_clickHandler.bind(this);
	}

	componentDidUpdate() {
		var that = this;

		if (this.props.info.openDialog && this.state.data.length === 0 && !this.inProgress) {
			this.inProgress = true;
			this.fetchInvoiceDocumentRoutingHistory();

			that.props.info.inProgress(true);
		}
	}

	fetchInvoiceDocumentRoutingHistory() {
		var that = this;

		httpGet('/api/invoicedocument/invoicedocumentroutinghistory/' + this.props.info.rowData.invoiceDocumentId)
			.then((response) => {
				var data = response.data;

				that.inProgress = false;
				that.setState({ data: data });

				this.props.info.inProgress(false);
			})
			.catch((error) => {
				that.inProgress = false;
				that.props.info.setInProgess(false);

				toast.error('An error occurred while fetching the records');
			});
	}

	close_clickHandler(event) {
		this.setState({ data: [] });

		if (this.props.onClose) {
			this.props.onClose(event, this.state.rowData);
		}
	}

	render() {
		return (
			<Dialog open={this.props.info.openDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
				<DialogTitle>
					Invoice Document Approval History
				</DialogTitle>
				<DialogContent>
					<MaterialTable
						title=""
						key="sequenceId"
						columns={[
							{
								title: 'Approval Level',
								field: 'approvalLevel'
							},
							{
								title: 'Status',
								field: 'status'
							},
							{
								title: 'Comments',
								field: 'approvalRejectionComment'
							},
							{
								title: 'Timestamp',
								field: 'timestamp'
							},
							{
								title: 'User',
								field: 'resourceName'
							}
						]}
						options={{
							toolbar: false,
							sorting: false,
							draggable: false,
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 350
						}}
						data={this.state.data}
					/>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={this.close_clickHandler} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
