import * as React from 'react';
import Draggable from 'react-draggable';
import './css/style.css';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { toast } from 'react-toastify';
import { TextBlocksRenderer } from './TextBlocksRenderer';
import PDFViewer from './PDFViewer';
import { httpGet, httpPost } from '../../../HttpRequestHandling/httpRequestHandler';

export class DocumentViewer extends React.Component {
	constructor(props) {
		super(props);

		var info = this.props.info;

		info.invoiceDocument = {
			offsetTop: 0,
			offsetLeft: 0,
			offsetWidth: 0,
			offsetHeight: 0
		};

		this.state = {
			info: info,
			blockType: {
				value: "WORD"
			},
			documentUrl: null,
			documentJsonUrl: null,
			documentJson: null,
			documentContentType: "",
			page: 1,
			pages: 1,
			scale: 1.4,
			inProgress: false,
			selectedFile: null
		};

		this.position = null;

		this.navLink_clickHandler = this.navLink_clickHandler.bind(this);
		this.fileUpload_changeHandler = this.fileUpload_changeHandler.bind(this);
		this.load_Handler = this.load_Handler.bind(this);
		this.documentLoadSuccess_Handler = this.documentLoadSuccess_Handler.bind(this);
		this.pageRenderSuccess_Handler = this.pageRenderSuccess_Handler.bind(this);
		this.resize_Handler = this.resize_Handler.bind(this);
		this.tryFetchingInvoiceDocument = this.tryFetchingInvoiceDocument.bind(this);

		window.addEventListener("resize", this.resize_Handler);

		if (this.props.info.rowData) {
			this.props.info.inProgress(true);
			this.fetchInvoiceDocument(this.props.info.rowData.invoiceDocumentFileLocation);
		}
	}

	componentDidUpdate() {
		if (this.props.info.invoiceModelEditor) {
			const approvalStampConfiguration = this.props.info.invoiceModelEditor.approvalStampConfiguration;

			if (approvalStampConfiguration.left != this.position.x) {
				this.position.x = approvalStampConfiguration.left;
			}

			if (approvalStampConfiguration.top != this.position.y) {
				this.position.y = approvalStampConfiguration.top;
			}
		}
	}

	fetchInvoiceDocument(invoiceDocumentFileLocation) {
		httpGet("/api/invoicemodels/invoicedocument/" + this.props.info.modelDefId + "/" + escape(invoiceDocumentFileLocation))
			.then((response) => {
				var data = response.data;

				if (data.documentJson) {
					this.setState(
						{
							documentUrl: data.documentUrl,
							documentJsonUrl: data.documentJsonUrl,
							documentJson: data.documentJson,
							documentDataUri: data.documentDataUri,
							documentContentType: data.documentContentType,
							inProgress: false,
							fetchInterval: null
						});

					clearInterval(this.state.fetchInterval);

					this.props.info.inProgress(false);
				}
				else {
					this.setState({ inProgress: false });
				}
			}).catch((error) => {
				this.setState({ documentUrl: null, documentJsonUrl: null, documentJson: null });
				this.props.info.inProgress(false);

				toast.error('Error fetching invoice document');
			});
	}

	tryFetchingInvoiceDocument() {
		if (!this.state.documentJson && !this.state.inProgress) {
			this.setState({ inProgress: true });

			if (this.props.info.invoiceDocumentSampleFileLocation) {
				this.fetchInvoiceDocument(this.props.info.invoiceDocumentSampleFileLocation);
			}
		}
	}

	resize_Handler(event) {
		var invoiceDocumentContainer;

		if (this.state.documentContentType === "application/pdf") {
			invoiceDocumentContainer = document.getElementById("the-canvas");
		}
		else if (this.state.documentContentType.startsWith("image")) {
			invoiceDocumentContainer = document.getElementById("imgInvoiceDocument");
		}

		if (invoiceDocumentContainer) {
			this.calculateInvoiceDocumentSize(invoiceDocumentContainer);
		}
	}

	load_Handler(event) {
		this.calculateInvoiceDocumentSize(event.target);

		if (this.state.documentJson) {
			this.props.info.inProgress(false);
		}
	}

	documentLoadSuccess_Handler(pdfDocument) {
		this.setState({ pages: pdfDocument.numPages });
	}

	pageRenderSuccess_Handler(documentCanvas) {
		this.calculateInvoiceDocumentSize(documentCanvas);

		if (this.state.documentJson) {
			this.props.info.inProgress(false);
		}
	}

	calculateInvoiceDocumentSize(imgInvoiceDocument) {
		var info = this.state.info;

		info.invoiceDocument.offsetTop = imgInvoiceDocument.offsetTop;
		info.invoiceDocument.offsetLeft = imgInvoiceDocument.offsetLeft;
		info.invoiceDocument.offsetWidth = imgInvoiceDocument.offsetWidth;
		info.invoiceDocument.offsetHeight = imgInvoiceDocument.height;

		this.setState({ info: info });
	}

	navLink_clickHandler(event) {
		event.preventDefault();

		var blockType = this.state.blockType;
		blockType.value = event.currentTarget.dataset.blocktype;

		this.setState({ blockType: blockType });
	}

	fileUpload_changeHandler(event) {
		const formData = new FormData();
		const files = Array.from(event.target.files);
		for (var x = 0; x < files.length; x++) {
			const fileOriginal = files[x];
			var filename = fileOriginal.name.replace(/[^\w\.]/gi, '');
			var fileReplaced = new File([fileOriginal], filename, { type: fileOriginal.type });
			files[x] = fileReplaced;
		};

		var file = files[0];

		if (!this.checkMimeType(event) || !this.checkFileSize(event)) {
			event.target.value = null
			return;
		}

		if (file) {
			formData.append(0, file);

			formData.append("modelDefId", this.props.info.modelDefId);

			this.props.info.inProgress(true);

			this.setState({
				selectedFile: file,
				documentContentType: file.type
			});

			const token = localStorage.token;
			const headers = {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}

			httpPost("/api/invoicemodels/upload", formData, {
				headers: headers,
				onUploadProgress: ProgressEvent => {
					this.props.info.inProgress(true, true, (ProgressEvent.loaded / ProgressEvent.total * 100));
				},
			}).then((response) => {
				toast.success('Uploaded Successfully');

				this.props.info.inProgress(false, false, 0, response.data);
				this.props.info.inProgress(true);

				this.setState({ fetchInterval: setInterval(this.tryFetchingInvoiceDocument, 5000) });
			}).catch((error) => {
				toast.error('Upload Failed');

				this.props.info.inProgress(false, false, 0);
			});
		}

		event.target.value = null;
	}

	checkMimeType = (event) => {
		//getting file object
		let files = event.target.files
		//define message container
		let err = []
		// list allow mime type
		const types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']
		// loop access array
		for (var x = 0; x < files.length; x++) {
			// compare file type find doesn't matach
			if (types.every(type => files[x].type !== type)) {
				// create error message and assign to container   
				err.push(files[x].name + ' of type \'' + files[x].type + '\' is not a supported format');
			}
		};

		if (err.length > 0) { // if message not same old that mean has error
			for (var i = 0; i < err.length; i++) {
				toast.error(err[i]);
			}

			return false;
		}

		return true;
	}

	checkFileSize = (event) => {
		let files = event.target.files
		var size = 10 * 1024 * 1024; //10 MB size
		let err = "";

		for (var x = 0; x < files.length; x++) {
			if (files[x].size > size) {
				err += files[x].name + ' is too large, please pick a smaller file\n';
			}
		};

		if (err !== '') {
			toast.error(err);

			return false
		}

		return true;
	}

	handlePrevious() {
		var page = this.state.page - 1 >= 1 ? this.state.page - 1 : this.state.page;

		this.setState({ page: page });
	}

	handleNext() {
		var page = this.state.page + 1 <= this.state.pages ? this.state.page + 1 : this.state.page;

		this.setState({ page: page });
	}

	zoomOut(event) {
		event.preventDefault();

		var scale = parseFloat((this.state.scale - 0.1).toFixed(2));

		if (this.state.scale > 1) {
			this.setState({ scale: scale });

			this.props.info.inProgress(true);
		}
	}

	zoomIn(event) {
		event.preventDefault();

		var scale = parseFloat((this.state.scale + 0.1).toFixed(2));

		if (scale < 2.5) {
			this.setState({ scale: scale });

			this.props.info.inProgress(true);
		}
	}

	handleDrag = (e, ui) => {
		this.position.x += ui.deltaX;
		this.position.y += ui.deltaY;
	};

	onStop = () => {
		var invoiceModelEditor = this.props.info.invoiceModelEditor;
		var approvalStampConfiguration = invoiceModelEditor.approvalStampConfiguration;

		approvalStampConfiguration.left = this.position.x;
		approvalStampConfiguration.top = this.position.y;

		if (this.props.info.passInvoiceModelEditorToSiblings) {
			this.props.info.passInvoiceModelEditorToSiblings(invoiceModelEditor);
		}
	};

	getDraggable() {
		if (!this.props.info.invoiceModelEditor) {
			return null;
		}
		else {
			var approvalStampConfiguration = this.props.info.invoiceModelEditor.approvalStampConfiguration;
			var showApprovalStamp = this.props.info.invoiceModelEditor.showApprovalStamp;

			const position = { x: approvalStampConfiguration.left, y: approvalStampConfiguration.top };

			if (this.position == null) {
				this.position = position;
			}

			return <Draggable
				bounds="parent"
				defaultPosition={position}
				position={position}
				onDrag={this.handleDrag}
				onStop={this.onStop}>
				<div
					style={{
						border: 2,
						borderStyle: "solid",
						borderRadius: 2,
						borderColor: approvalStampConfiguration.textFormat.fontColor,
						opacity: approvalStampConfiguration.textFormat.opacity,
						position: "absolute",
						zIndex: 999,
						width: approvalStampConfiguration.width,
						height: approvalStampConfiguration.height,
						display: showApprovalStamp ? "" : "none"
					}} >
					<span style={{
						color: approvalStampConfiguration.textFormat.fontColor,
						fontFamily: approvalStampConfiguration.textFormat.fontFamily,
						fontSize: approvalStampConfiguration.textFormat.fontSize,
						fontStyle: approvalStampConfiguration.textFormat.italic ? "italic" : "",
						fontWeight: approvalStampConfiguration.textFormat.bold ? "bold" : "",
						textDecoration: approvalStampConfiguration.textFormat.underlined ? "underline" : "",
						opacity: approvalStampConfiguration.textFormat.opacity
					}}>Approval Stamp</span>
				</div>
			</Draggable>;
		}
	}

	render() {
		var info = this.state.info;
		info.documentJson = this.state.documentJson;
		info.blockType = this.state.blockType;
		info.page = this.state.page;
		info.invoiceModelEditor = this.props.info.invoiceModelEditor;

		var file = "";
		var src = "";

		if (this.state.documentContentType === "application/pdf") {
			file = this.state.documentDataUri ? this.state.documentDataUri : this.state.selectedFile;
		}
		else if (this.state.documentContentType.startsWith("image")) {
			src = this.state.documentDataUri ? this.state.documentDataUri : URL.createObjectURL(this.state.selectedFile);
		}

		return (
			<div className="mf-6 col-left" style={{ backgroundColor: "#7a7a7a" }}>
				<div className="top-header" style={{ display: info.documentJson ? "" : "none" }}>
					<nav className="navbar navbar-expand-md navbar-dark bg-primary primary-menu">
						<div className="collapse navbar-collapse" id="navbarNavDropdown" style={{ paddingTop: 17 }}>
							<ul className="navbar-nav">
								<li className="nav-item">
									<a className="nav-link" href="#" title="View Raw Invoice" data-blocktype="NONE" onClick={this.navLink_clickHandler}> <img src="images/view-raw-invoice-icon.png" /> </a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#" title="Mark up Lines" data-blocktype="LINE" onClick={this.navLink_clickHandler}><img src="images/mark-up-lines-icon.png" /></a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#" title="Mark up Words" data-blocktype="WORD" onClick={this.navLink_clickHandler}><img src="images/mark-up-words-icon.png" /></a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#" title="Mark up Tables" data-blocktype="CELL" onClick={this.navLink_clickHandler}> <img src="images/mark-up-tables-icon.png" /> </a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#" title="Mark up Key Value Pairs" data-blocktype="KEY_VALUE_SET" onClick={this.navLink_clickHandler}> <img src="images/mark-up-key-value-prayers-icon.png" /> </a>
								</li>
								<li className="nav-item nav-no-border">
									<div className="nav-link nav-pager-link">
										<a href="#" onClick={(e) => e.preventDefault()}><img src="images/pager-first-icon.png" onClick={() => this.setState({ page: 1 })} /></a>
										<a href="#" onClick={(e) => e.preventDefault()}><img className="pagerPrevIcon" src="images/pager-previous-icon.png" onClick={() => this.handlePrevious()} /></a>
										<span>Page </span>
										{this.state.page}
										<span> of </span>
										<span>{this.state.pages}</span>
										<a href="#" onClick={(e) => e.preventDefault()}><img className="pagerNextIcon" src="images/pager-next-icon.png" onClick={() => this.handleNext()} /></a>
										<a href="#" onClick={(e) => e.preventDefault()}><img src="images/pager-last-icon.png" onClick={() => this.setState({ page: this.state.pages })} /></a>
									</div>
								</li>
								<li className="nav-item" style={{ display: this.state.documentContentType.startsWith("image") ? "none" : "" }}>
									<a className="nav-link" href="#" title="Zoom Out" onClick={(e) => this.zoomOut(e)}> <img src="images/ZoomOut.gif" /> </a>
									<a className="nav-link" href="#" title="Zoom In" onClick={(e) => this.zoomIn(e)}> <img src="images/ZoomIn.gif" /> </a>
								</li>
							</ul>
						</div>
					</nav>
				</div>
				<div className="col-pdf-view">
					<div className="pdfContainer">
						<div className="file-drop-area" style={{ display: this.props.info.rowData || this.state.documentContentType ? "none" : "" }}>
							<label htmlFor="input-file">
								<div className="file-drop-container">
									<img src="images/drag-and-drop-icon.png" />
									<span className="file-msg">Drag & Drop Invoice Here <br /> or </span>
								</div>
								<input
									type="file"
									accept="application/pdf,image/jpg,image/jpeg,image/png"
									id="input-file"
									style={{ display: "none" }}
									onChange={this.fileUpload_changeHandler} />
								<div className="file-drop-browse">
									<label htmlFor="input-file">
										<Button variant="contained" color="default" component="span" size="large" startIcon={<CloudUploadIcon />}>Browse Invoice</Button>
									</label>
								</div>
							</label>
						</div>
						<div style={{ display: this.state.documentContentType === "" ? "none" : "", overflow: "auto", maxHeight: 1350, position: "relative" }}>
							<img id="imgInvoiceDocument" src={src} alt="" style={{ position: "absolute", display: src.length > 0 ? "" : "none" }} onLoad={this.load_Handler} />
							<PDFViewer file={file} page={this.state.page} scale={this.state.scale} onDocumentLoadSuccess={this.documentLoadSuccess_Handler} onPageRenderSuccess={this.pageRenderSuccess_Handler} />
							{this.getDraggable()}
							<TextBlocksRenderer info={info} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
