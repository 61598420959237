import actionType from '../constants/constants'
const INITIAL_STATE = {
	currentUser: {}
}

export default (states = INITIAL_STATE, action) => {
	
	switch (action.type) {
		case actionType.LOGIN_USER:
			return ({
				...states,
				currentUser: action.payload
			});
		case actionType.LOGOUT_USER:
			return {
				...states,
				currentUser: {}
			}
		default:
			return states;
	}
}