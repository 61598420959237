var toCurrencyFormat = function (value) {
	if (isNaN(value) || stringIsNullOrEmpty(value)) {
		const zero = 0;
		return zero.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
	}

	return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

exports.formatCurrency = toCurrencyFormat;

var toNumberFormat = function (value) {
	var number = toCurrencyFormat(value).replace(/\$/g, '');
	if (round(number, 2) == 0.00 || round(number, 2) == "-0.00") {
		number = '0.00';
	}
	return number;
}

exports.formatNumber = toNumberFormat;

const round = (number, decimalPlaces) => {
	const factorOfTen = Math.pow(10, decimalPlaces);
	return Math.round(number * factorOfTen) / factorOfTen;
}
var isNullOrUndefined = function (value) {
	return value === undefined || value == null;
}

exports.isNullOrUndefined = isNullOrUndefined;

var stringIsNullOrEmpty = function (value) {
	return value === undefined || value == null || (value != null && typeof value === "string" && value.length === 0);
}

exports.stringIsNullOrEmpty = stringIsNullOrEmpty;

var blankStringIfNullOrEmpty = function (value) {
	return value === undefined || value == null ? "" : value;
}

exports.blankStringIfNullOrEmpty = blankStringIfNullOrEmpty;

var defaultStringIfNullOrEmpty = function (value, defaultValue) {
	return stringIsNullOrEmpty(value) ? defaultValue : value;
}

exports.defaultStringIfNullOrEmpty = defaultStringIfNullOrEmpty;

var zeroIfNull = function (value) {
	return value === undefined || value == null || isNaN(value) ? 0 : value;
}

exports.zeroIfNull = zeroIfNull;

var checkForUnAuthorizedException = function (compObj, error) {
	if (error.response.status === 401) {
		//let searchParams = new URLSearchParams(compObj.props.location.search);
		localStorage.removeItem("token");
		localStorage.removeItem("tokenExpiry");
		localStorage.removeItem("userInfo");
		localStorage.removeItem("authenticatedUser");
		document.location.href = "/logout";
		//compObj.props.history.push({
		//	pathname: "/login",
		//	//search: searchParams.toString()
		//});
	}
}

exports.checkForUnAuthorizedException = checkForUnAuthorizedException;

var addDaysInDate = function addDays(date, number) {
	const newDate = new Date(date);
	return new Date(newDate.setDate(newDate.getDate() + number));
}

exports.addDaysInDate = addDaysInDate;

