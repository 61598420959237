import * as React from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import SearchBar from 'material-ui-search-bar';
import Zoom from '@material-ui/core/Zoom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import { isNullOrUndefined, stringIsNullOrEmpty } from '../Common/Utilities';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});
const initialState = {
	masterData: [],
	data: [],
	searchText: "",
	searchField: "All",
	searchPlaceholderText: "Search All",
	autoFocusSearchBar: true,
	selectedRows: [],
	comments: "",
	distinctResourceList: []
}
export class POHeaderResourceLookup extends React.Component {
	
	constructor(props) {
		super(props);

		this.vendorCode = null;
		this.siteId = null;
		this.inProgress = false;

		this.state = initialState;

		this.checkbox_clickHandler = this.checkbox_clickHandler.bind(this);
		this.comments_changeHandler = this.comments_changeHandler.bind(this);
		this.routeToEmployee_clickHandler = this.routeToEmployee_clickHandler.bind(this);
		this.close_clickHandler = this.close_clickHandler.bind(this);
	}

	componentDidUpdate() {
		//var that = this;
		if (this.props.info.vendorCode !== this.vendorCode || this.props.info.siteId !== this.siteId) {
			this.vendorCode = this.props.info.vendorCode;
			this.siteId = this.props.info.siteId;

			this.inProgress = false;
			this.setState({ masterData: [], data: [], selectedRows: [] });
		}

		if (this.props.info.openDialog && this.state.masterData.length === 0 && !this.inProgress) {
			this.inProgress = true;
			this.fetchRecords();
		}
	}

	checkbox_clickHandler(event) {
		var rowId = parseInt(event.target.value);
		var selectedRows = this.state.selectedRows;
		var rowIndex = selectedRows.findIndex(sr => sr.rowId === rowId);

		var rowData = this.getRowData(rowId);

		if (rowIndex < 0) {
			selectedRows.push(rowData);
		}
		else {
			selectedRows.splice(rowIndex, 1);
		}

		this.setState({ selectedRows: selectedRows });
	}

	comments_changeHandler(event) {
		this.setState({ comments: event.target.value, autoFocusSearchBar: false });
	}

	routeToEmployee_clickHandler(event) {
		if (this.props.onClose) {
			this.state.selectedRows.map(rowData => rowData.comments = this.state.comments);
			this.props.onClose(event, this.state.selectedRows); this.inProgress = false;
			this.setState({
				...initialState
			});
		}
	}

	close_clickHandler(event) {
		if (this.props.onClose) {
			this.props.onClose(event, []);
			this.inProgress = false;
			this.setState({
				...initialState
			});
		}
	}

	fetchRecords() {
		if (this.state.data.length === 0) {
			var invoiceDocumentIdParam = !isNullOrUndefined(this.props.info.invoiceDocumentId) && this.props.info.invoiceDocumentId > 0 ? this.props.info.invoiceDocumentId.toString() : "";

			this.props.info.inProgress(true);

			httpGet("/api/lookup/poheaderandresourcelistbyvendor/" + encodeURIComponent(this.props.info.vendorCode) + "/" + encodeURIComponent(this.props.info.siteId) + "/" + invoiceDocumentIdParam)
				.then((response) => {
					var masterData = response.data;
					var rowId = 1;
					masterData.map(row => row.rowId = rowId++);
					var data = JSON.parse(JSON.stringify(masterData))
					this.setState(
						{
							vendorCode: this.props.info.vendorCode,
							siteId: this.props.info.siteId,
							masterData: masterData,
							data: data,
							selectedRows: []
						});

					this.props.info.inProgress(false);
				}).catch((error) => {
					this.props.info.inProgress(false);
					toast.error('Error fetching PO Header Resource Lookup');
					this.close_clickHandler();
				});
		}
	}

	getRowData(value) {
		var data = this.state.masterData;

		return data[data.findIndex(d => d.rowId === value)];
	}

	getDistinctResourceList() {
		var distinctResources = [];

		this.state.data.map(function (row, index) {
			var resource = {
				rowId: row.rowId,
				resourceId: row.resourceId,
				employeeName: row.employeeName
			};

			if (!distinctResources.some(r => r.resourceId === row.resourceId)) {
				distinctResources.push(resource);
			};
		});

		return distinctResources.sort((a, b) => (a.employeeName > b.employeeName ? 0 : -1));
	}

	getResourceListByResourceId(resourceId) {
		var filteredResources = [];

		filteredResources = resourceId === "-1" ? this.state.data : this.state.data.filter(resource => resource.resourceId === resourceId);

		return filteredResources;
	}

	onSearchChange(searchText) {
		var value = new RegExp(searchText, "i");
		var data = "";
		if (stringIsNullOrEmpty(this.state.searchField) || this.state.searchField === "All") {
			data = this.state.masterData.filter(row => stringIsNullOrEmpty(value) ||
				(!stringIsNullOrEmpty(row.employeeName) && row.employeeName.match(value)) ||
				(!stringIsNullOrEmpty(row.positionCategoryName) && row.positionCategoryName.match(value)) ||
				(!stringIsNullOrEmpty(row.clientCode) && row.clientCode.match(value)) ||
				(!stringIsNullOrEmpty(row.clientName) && row.clientName.match(value)) ||
				(!stringIsNullOrEmpty(row.jobCode) && row.jobCode.match(value)) ||
				(!stringIsNullOrEmpty(row.campaignName) && row.campaignName.match(value)) ||
				(!stringIsNullOrEmpty(row.activityCode) && row.activityCode.match(value)) ||
				(!stringIsNullOrEmpty(row.mediaPlanName) && row.mediaPlanName.match(value))
			);
		}
		else {
			data = this.state.masterData.filter(row => stringIsNullOrEmpty(value) ||
				(!stringIsNullOrEmpty(row[this.state.searchField]) && row[this.state.searchField].match(value))
			);
		}
		this.setState({ data: data, searchText: searchText, autoFocusSearchBar: true });
	}

	render() {
		var that = this;

		return (
			<Dialog open={this.props.info.openDialog} TransitionComponent={Transition} fullScreen>
				<DialogTitle>
					Route to Employee
				</DialogTitle>
				<DialogContent>
					<div className="mainTable box-container" style={{ maxWidth: "none" }}>
						<div style={{ marginTop: 10, marginBottom: 10 }}>
							<MaterialTable
								title=""
								columns={[
									{
										cellStyle: { width: 50 },
										render: rowData =>
											<Checkbox
												color="default"
												name="chk"
												checked={this.state.selectedRows.findIndex(sr => sr.rowId === rowData.rowId) >= 0}
												value={rowData.rowId}
												onClick={this.checkbox_clickHandler}
											/>
									},
									{
										title: 'Employee Name',
										field: 'employeeName',
										cellStyle: { width: 1700 }
									}
								]}
								options={{
									pageSize: 10,
									pageSizeOptions: [10, 20, 30, 40, 50],
									maxBodyHeight: 505,
									search: false
								}}
								components={{
									Toolbar: props => (
										<div style={{ padding: 20, height: 90 }}>
											<SearchBar style={{ float: "right", width: 300 }}
												value={this.state.searchText}
												placeholder={this.state.searchPlaceholderText}
												autoFocus={this.state.autoFocusSearchBar}
												onChange={(newValue) => this.onSearchChange(newValue)}
												onCancelSearch={() => this.onSearchChange("")}
											/>
											<Select style={{ float: "right", height: 50, width: 150, marginRight: 10 }}
												value={this.state.searchField}
												onChange={(event) => {
													this.setState({ searchPlaceholderText: "Search " + event.currentTarget.innerText, searchField: event.target.value });

													setTimeout(function () { that.onSearchChange(that.state.searchText); }, 10);
												}}
											>
												<MenuItem value="All">All</MenuItem>
												<MenuItem value="employeeName">Employee Name</MenuItem>
												<MenuItem value="positionCategoryName">Role</MenuItem>
												<MenuItem value="clientCode">Client Code</MenuItem>
												<MenuItem value="clientName">Client Name</MenuItem>
												<MenuItem value="jobCode">Job Code</MenuItem>
												<MenuItem value="campaignName">Campaign Name</MenuItem>
												<MenuItem value="activityCode">Activity Code</MenuItem>
												<MenuItem value="mediaPlanName">Media Plan Name</MenuItem>
											</Select>
											<InputLabel id="demo-simple-select-label" style={{ float: "right", padding: 20 }}>Search By: </InputLabel>
										</div>
									)
								}}
								data={this.getDistinctResourceList()}
								detailPanel={[
									{
										tooltip: 'Show Details',
										render: parentRowData => {
											return (
												<div style={{ padding: 10 }}>
													<MaterialTable
														title=""
														columns={[
															{
																title: 'Role',
																field: 'positionCategoryName'
															},
															{
																title: 'Client Code',
																field: 'clientCode'
															},
															{
																title: 'Client Name',
																field: 'clientName'
															},
															{
																title: 'Job Code',
																field: 'jobCode'
															},
															{
																title: 'Campaign Name',
																field: 'campaignName'
															},
															{
																title: 'Activity Code',
																field: 'activityCode'
															},
															{
																title: 'Media Plan Name',
																field: 'mediaPlanName'
															}
														]}
														options={{
															pageSize: 10,
															pageSizeOptions: [10, 20, 30, 40, 50],
															maxBodyHeight: 405,
															search: false,
															toolbar: false
														}}
														data={this.getResourceListByResourceId(parentRowData.resourceId)}
													/>
												</div>
											)
										},
									}
								]}
							/>
						</div>
						<div className="row-form">
							<div className="mf-8 field_full-8">
								<label><span style={{ color: "red" }}>*</span>Comments: </label>
								<textarea name="comments" value={this.state.comments} autoFocus={!this.state.autoFocusSearchBar} onChange={this.comments_changeHandler}></textarea>
							</div>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button autoFocus disabled={this.state.selectedRows.length === 0 || this.state.comments.length === 0} onClick={this.routeToEmployee_clickHandler} color="primary">
						Route to Employee
					</Button>
					<Button autoFocus onClick={this.close_clickHandler} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
