import * as React from 'react';

export class TextBlocksRenderer extends React.Component {
	constructor(props) {
		super(props);

		//var json = JSON.parse(this.props.info.json);
		this.state = {
			blockType: this.props.info.blockType
		};

		this.dragStart_Handler = this.dragStart_Handler.bind(this);
	}

	dragStart_Handler(event) {
		var blocks = this.props.info.documentJson;
		var block = JSON.parse(event.target.dataset.block);

		var cellBlock = blocks.find(b => b.blockType.value === "CELL"
			&& b.relationships.find(r => r.type.value === "CHILD"
				&& r.ids.find(i => i === block.id) != null) != null);

		if (cellBlock) {
			var tableBlock = blocks.find(b => b.blockType.value === "TABLE"
				&& b.relationships.find(r => r.type.value === "CHILD"
					&& r.ids.find(i => i === cellBlock.id) != null) != null);

			if (tableBlock) {
				var tables = blocks.filter(b => b.blockType.value === "TABLE" && b.page === cellBlock.page);

				if (tables) {
					var sortedTables = tables.sort(function (t1, t2) {
						var result = 0;

						result = t1.geometry.boundingBox.top - t2.geometry.boundingBox.top;

						if (result === 0) {
							result = t1.geometry.boundingBox.left - t2.geometry.boundingBox.left;
						}

						return result;
					});

					var tableNumber = sortedTables.findIndex(t => t.id === tableBlock.id) + 1;
					block.tableNumber = tableNumber;
				}
			}

			block.columnIndex = cellBlock.columnIndex;
			block.rowIndex = cellBlock.rowIndex;
		}

		if (block.blockType.value === "CELL" || block.blockType.value === "KEY_VALUE_SET") {
			var childIds = block.relationships.find(r => r.type.value === "CHILD").ids;

			var childWords = blocks.filter(b => childIds.find(c => c == b.id) != null && b.blockType.value === "WORD");

			var text = childWords.map(c => c.text).join(" ");

			block.text = text;
		}

		event.dataTransfer.setData("Block", JSON.stringify(block));
	}

	render() {
		if (!this.props.info.documentJson || this.props.info.mode === "View" || this.props.info.mode === "AssignVendor") {
			return <div id="divBlocks" style={{ whiteSpace: "nowrap", position: "relative", height: 1350 }}></div>;
		}

		const offsetTop = this.props.info.invoiceDocument.offsetTop;
		const offsetLeft = this.props.info.invoiceDocument.offsetLeft;
		const offsetWidth = this.props.info.invoiceDocument.offsetWidth;
		const offsetHeight = this.props.info.invoiceDocument.offsetHeight;

		var that = this;

		return (
			<div id="divBlocks" style={{ position: "relative", height: offsetHeight === 0 ? 1350 : offsetHeight }}>
				{
					this.props.info.documentJson.filter(function (block) {
						return block.blockType.value === that.state.blockType.value && block.page === that.props.info.page;
					}).map(function (block, index) {
						var left = (block.geometry.boundingBox.left * offsetWidth);
						var top = (block.geometry.boundingBox.top * offsetHeight);
						var width = (block.geometry.boundingBox.width * offsetWidth);
						var height = (block.geometry.boundingBox.height * offsetHeight);

						var backgroundColor = "";
						var blockType = that.state.blockType;

						if (that.props.info.invoiceModelEditor) {
							var invoiceModelEditor = Object.entries(that.props.info.invoiceModelEditor);

							invoiceModelEditor.map(function (i, index) {
								if (i.length === 2 && i[1] !== undefined && i[1].blocks !== undefined && i[1].blocks.length > 0) {
									var item = i[1].blocks.find(b => b.id === block.id);

									if (item) {
										backgroundColor = "yellow";
									}
								}
							});
						}

						return <div
							draggable="true"
							id={block.id}
							key={index}
							data-text={block.text ? block.text : ""}
							data-block={JSON.stringify(block)}
							dragleft={block.geometry.boundingBox.left}
							dragtop={block.geometry.boundingBox.top}
							onDragStart={that.dragStart_Handler}
							style={{
								border: 1,
								borderStyle: "solid",
								borderColor: "#ff00ff",
								borderRadius: 2,
								backgroundColor: backgroundColor,
								opacity: 0.5,
								display: blockType.value === block.blockType.value ? "" : "none",
								position: "absolute",
								width: width,
								height: height,
								left: left,
								top: top
							}}>
						</div>;
					})
				}
			</div>
		);
	}
}
