import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DirectionsIcon from '@material-ui/icons/Directions';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import MessageIcon from '@material-ui/icons/Message';
import MenuItem from '@material-ui/core/MenuItem';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ScannerIcon from '@material-ui/icons/Scanner';
import RouteToEmployeeIcon from '@material-ui/icons/AssignmentInd';
import Select from '@material-ui/core/Select';
//import { styled } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import { toast } from 'react-toastify';
import { formatNumber, defaultStringIfNullOrEmpty, stringIsNullOrEmpty, checkForUnAuthorizedException } from '../Common/Utilities';
import { httpGet, httpPost, httpPut } from '../../HttpRequestHandling/httpRequestHandler';
import { InvoiceDocumentViewer } from '../InvoiceDocument/InvoiceDocumentViewer';
import { InvoiceDocumentEditor } from '../InvoiceDocument/InvoiceDocumentEditor';
import { POHeaderResourceLookup } from '../Lookups/POHeaderResourceLookup';
import { Level2ResourceLookup } from '../Lookups/Level2ResourceLookup';
import { fileUpload } from '../NonMappedInvoices/ManualUploadApi';
import { green, orange } from '@material-ui/core/colors';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});
//const HtmlTooltip = styled(({ className, ...props }) => (
//	<Tooltip {...props}/>
//));
export default class Dashboard extends React.Component {
	constructor() {
		super();

		const userInfo = JSON.parse(localStorage.userInfo);

		this.isAdmin = userInfo.selectedUserRoles.isAdmin;
		this.selectedRows = [];
		this.scanSelectedRows = [];
		var openInvoiceDocumentEditorDialog = false;
		var rowData = null;

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);

		const invoiceDocumentId = urlParams.get('invoicedocumentid');
		const status = urlParams.get('status');
		var mode = urlParams.get('mode');
		var modelDefId = urlParams.get('modeldefid');

		if (!stringIsNullOrEmpty(invoiceDocumentId)) {
			openInvoiceDocumentEditorDialog = true;
			rowData = { invoiceDocumentId: parseInt(invoiceDocumentId), invoiceDocumentStatusId: parseInt(status) };
			mode = stringIsNullOrEmpty(mode) ? "MakeCorrections" : mode;
			modelDefId = modelDefId;
		}
		
		this.state = {
			weeksToFilter1: 0,
			weeksToFilter2: 0,
			weeksToFilter3: 0,
			weeksToFilter4: 4,
			queuedInvoices: [],
			unmatchedInvoices: [],
			filteredUnmatchedInvoices: [],
			pendingApprovalInvoices: [],
			approvedInvoices: [],
			openInvoiceViewer: false,
			openInvoiceDocumentEditorDialog: openInvoiceDocumentEditorDialog,
			openPOHeaderResourceDialog: false,
			openLevel2ResourceDialog: false,
			rowData: rowData,
			mode: mode,
			modelDefId: modelDefId,
			isViaQueryString: !stringIsNullOrEmpty(invoiceDocumentId),
			selectedRoutedToResourceId: "-1",
			distinctRoutedToResourceList: [],
			selectedFiles: null,
		};

		this.weeksToFilter_changeHandler = this.weeksToFilter_changeHandler.bind(this);
		this.routedToResourceList_changeHandler = this.routedToResourceList_changeHandler.bind(this);
		this.bulkRouteToEmployee_clickHandler = this.bulkRouteToEmployee_clickHandler.bind(this);
		this.poHeaderResourceDialog_closeHandler = this.poHeaderResourceDialog_closeHandler.bind(this);
		this.level2ResourceDialog_closeHandler = this.level2ResourceDialog_closeHandler.bind(this);
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.fileUpload_clickHandler = this.fileUpload_clickHandler.bind(this);
		this.fileUpload_changeHandler = this.fileUpload_changeHandler.bind(this);
		this.viewLink_clickHandler = this.viewLink_clickHandler.bind(this);
		this.scan_clickHandler = this.scan_clickHandler.bind(this);
		this.scanAll_clickHandler = this.scanAll_clickHandler.bind(this);
		this.invoiceDocument_clickHandler = this.invoiceDocument_clickHandler.bind(this);
		this.suggestionFlag_clickHandler = this.suggestionFlag_clickHandler.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.invoiceDocumentEditor_closeHandler = this.invoiceDocumentEditor_closeHandler.bind(this);
		
	}

	componentDidMount() {
		this.fetchDashboard(0, 0);
	}

	fetchDashboard(gadgetNo, weeks) {
		const that = this;
		var modelDefId = this.props.modelDefId;
		var gadgetNumber = gadgetNo;
		var weeksToFilter = weeks;

		this.props.inProgress(true);

		httpGet("/api/dashboard/" + modelDefId + "/" + gadgetNumber + "/" + weeksToFilter)
			.then((response) => {
				var data = response.data;

				var weeksToFilter1 = that.state.weeksToFilter1;
				var weeksToFilter2 = that.state.weeksToFilter2;
				var weeksToFilter3 = that.state.weeksToFilter3;
				var weeksToFilter4 = that.state.weeksToFilter4;

				var queuedInvoices = that.state.queuedInvoices;
				var unmatchedInvoices = that.state.unmatchedInvoices;
				var filteredUnmatchedInvoices = that.state.filteredUnmatchedInvoices;
				var pendingApprovalInvoices = that.state.pendingApprovalInvoices;
				var approvedInvoices = that.state.approvedInvoices;
				var distinctRoutedToResourceList = that.state.distinctRoutedToResourceList;

				switch (gadgetNumber) {
					case 1:
						weeksToFilter1 = weeksToFilter;
						queuedInvoices = data.queuedInvoices;
						that.setRowId(queuedInvoices);
						queuedInvoices.map(row => row.invoiceDocumentFileLocation = row.documentManualUploadFileLocation);

						break;
					case 2:
						weeksToFilter2 = weeksToFilter;
						unmatchedInvoices = data.unmatchedInvoices;
						that.setRowId(unmatchedInvoices);

						distinctRoutedToResourceList = that.getDistinctRoutedToResourceList(unmatchedInvoices);
						filteredUnmatchedInvoices = that.getFilteredUnmatchedInvoices(unmatchedInvoices, that.state.selectedRoutedToResourceId);

						break;
					case 3:
						weeksToFilter3 = weeksToFilter;
						pendingApprovalInvoices = data.pendingApprovalInvoices;
						that.setRowId(pendingApprovalInvoices);

						break;
					case 4:
						weeksToFilter4 = weeksToFilter;
						approvedInvoices = data.approvedInvoices;
						that.setRowId(approvedInvoices);

						break;
					default:
						weeksToFilter1 = weeksToFilter;
						weeksToFilter2 = weeksToFilter;
						weeksToFilter3 = weeksToFilter;
						//weeksToFilter4 = weeksToFilter;

						queuedInvoices = data.queuedInvoices;
						unmatchedInvoices = data.unmatchedInvoices;
						pendingApprovalInvoices = data.pendingApprovalInvoices;
						approvedInvoices = data.approvedInvoices;

						that.setRowId(queuedInvoices);
						queuedInvoices.map(row => row.invoiceDocumentFileLocation = row.documentManualUploadFileLocation);

						that.setRowId(unmatchedInvoices);
						that.setRowId(pendingApprovalInvoices);
						that.setRowId(approvedInvoices);

						
						distinctRoutedToResourceList = that.getDistinctRoutedToResourceList(unmatchedInvoices);
						filteredUnmatchedInvoices = that.getFilteredUnmatchedInvoices(unmatchedInvoices, that.state.selectedRoutedToResourceId);

						break;
				}

				that.setState(
					{
						weeksToFilter1: weeksToFilter1,
						weeksToFilter2: weeksToFilter2,
						weeksToFilter3: weeksToFilter3,
						weeksToFilter4: weeksToFilter4,
						queuedInvoices: queuedInvoices,
						unmatchedInvoices: unmatchedInvoices,
						filteredUnmatchedInvoices: filteredUnmatchedInvoices,
						pendingApprovalInvoices: pendingApprovalInvoices,
						approvedInvoices: approvedInvoices,
						modelDefId: modelDefId,
						distinctRoutedToResourceList: distinctRoutedToResourceList
					});

				that.props.inProgress(false);
			}).catch((error) => {
				checkForUnAuthorizedException(that, error);
				that.props.inProgress(false);

				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	setRowId(data) {
		var rowId = 1;
		data.map(row => row.rowId = rowId++);
	}

	weeksToFilter_changeHandler(event) {
		var gadgetNumber = parseInt(event.currentTarget.dataset.gadgetnumber);
		var weeksToFilter = parseInt(event.target.value, 10);

		this.fetchDashboard(gadgetNumber, weeksToFilter);
	}

	routedToResourceList_changeHandler(event) {
		var filteredUnmatchedInvoices = this.getFilteredUnmatchedInvoices(this.state.unmatchedInvoices, event.target.value);
		this.setState({ selectedRoutedToResourceId: event.target.value, filteredUnmatchedInvoices: filteredUnmatchedInvoices });
	}

	bulkRouteToEmployee_clickHandler(event) {
		if (this.selectedRows.length > 0) {
			var isProduction = this.props.modelDefId === 2;
			this.setState({ openPOHeaderResourceDialog: !isProduction, openLevel2ResourceDialog: isProduction });
		}
	}

	poHeaderResourceDialog_closeHandler(event, poHeaderResources) {
		if (poHeaderResources.length > 0) {
			var that = this;
			var routedToResources = [];

			if (this.selectedRows.length > 0) {
				this.selectedRows.map(function (selectedRow, selectedRowIndex) {
					routedToResources = routedToResources.concat(that.getRoutedToResources(poHeaderResources, selectedRow.invoiceDocumentId));
				});
			}
			else {
				routedToResources = this.getRoutedToResources(poHeaderResources, this.state.invoiceDocumentId);
			}

			this.props.inProgress(true);

			httpPost("/api/invoicedocument/routetoemployee/", routedToResources)
				.then((response) => {
					this.props.inProgress(false);

					this.selectedRows = [];
					this.fetchDashboard(2, this.state["weeksToFilter" + 2]);

					toast.success('Routed to Employee Successfully');
				}).catch((error) => {
					this.props.inProgress(false);
					toast.error('Route to Employee Failed');
				});
		}

		this.setState({ openPOHeaderResourceDialog: false, invoiceDocumentId: null });
	}

	level2ResourceDialog_closeHandler(event, level2Resources) {
		if (level2Resources.length > 0) {
			var that = this;
			var routedToResources = [];

			if (this.selectedRows.length > 0) {
				this.selectedRows.map(function (selectedRow, selectedRowIndex) {
					routedToResources = routedToResources.concat(that.getRoutedToResources(level2Resources, selectedRow.invoiceDocumentId));
				});
			}
			else {
				routedToResources = this.getRoutedToResources(level2Resources, this.state.invoiceDocumentId);
			}

			this.props.inProgress(true);

			httpPost("/api/invoicedocument/routetoemployee/", routedToResources)
				.then((response) => {
					this.props.inProgress(false);

					this.selectedRows = [];
					this.fetchDashboard(2, this.state["weeksToFilter" + 2]);

					toast.success('Routed to Employee Successfully');
				}).catch((error) => {
					this.props.inProgress(false);
					toast.error('Route to Employee Failed');
				});
		}

		this.setState({ openLevel2ResourceDialog: false, invoiceDocumentId: null });
	}

	refresh_clickHandler(event) {
		var gadgetNumber = parseInt(event.currentTarget.dataset.gadgetnumber);

		this.fetchDashboard(gadgetNumber, this.state["weeksToFilter" + gadgetNumber]);
	}

	fileUpload_changeHandler(event) {
		fileUpload(this, event, this.state.modelDefId, toast, httpPost,
			() => { this.props.inProgress(true); },
			() => { this.props.inProgress(false); },
			ProgressEvent => { },
			() => {
				this.fetchDashboard(1, this.state["weeksToFilter" + 1]);
			});
	}

	fileUpload_clickHandler(event) {
		document.getElementById("contained-button-file").click();
	}

	viewLink_clickHandler(event) {
		event.preventDefault();

		var rowId = parseInt(event.target.dataset.rowid, 10);
		var gadgetName = event.target.dataset.gadgetname;
		var rowData = this.state[gadgetName].find(d => d.rowId === rowId);

		this.setState({ openInvoiceViewer: true, invoiceDocumentFileLocation: rowData.invoiceDocumentFileLocation });
	}

	scan_clickHandler(event) {
		event.preventDefault();

		this.props.inProgress(true);

		httpPut("/api/nonmappedinvoices/manuallyuploadedinvoices/" + event.currentTarget.dataset.documentmanualuploadrecordid)
			.then(res => {
				this.props.inProgress(false);

				toast.success('File Scan in Progress');
				this.fetchDashboard(0, 0);
			}).catch((error) => {
				this.props.inProgress(false);
				toast.error('Couldn\'t start scanning');
			});
	}	
	scanAll_clickHandler(event) {
		event.preventDefault();
		this.props.inProgress(true);		
		if (this.scanSelectedRows.length > 0) {			
		
			httpPost('/api/NonMappedInvoices/scanAll', this.scanSelectedRows)
				.then((response) => {
					this.props.inProgress(false);
					this.scanSelectedRows = [];
					toast.success('File(s) Scan in Progress');
					this.fetchDashboard(0, 0);
				}).catch((error) => {
					this.props.inProgress(false);
					toast.error('Couldn\'t start scanning');
				});		
		}
		else {
			this.props.inProgress(false);
			toast.error('No invoice(s) selected for scan');
		}
	}

	invoiceDocument_clickHandler(event) {
		event.preventDefault();

		var rowId = parseInt(event.target.dataset.rowid, 10);
		var datasource = this.state[event.target.dataset.source];

		var rowData = datasource.find(d => d.rowId === rowId);

		this.setState({ openInvoiceDocumentEditorDialog: true, mode: event.target.dataset.mode, rowData: rowData, isViaQueryString: false });
	}

	suggestionFlag_clickHandler(event) {
		event.preventDefault();

		var invoiceDocumentId = parseInt(event.currentTarget.dataset.invoicedocumentid, 10);
		var isProduction = this.props.modelDefId === 2;

		this.setState({ openPOHeaderResourceDialog: !isProduction, openLevel2ResourceDialog: isProduction, invoiceDocumentId: invoiceDocumentId });
	}

	closeDialog(event) {
		this.setState({ openDelete: false, openInvoiceViewer: false });
	}

	invoiceDocumentEditor_closeHandler(event) {
		this.setState({ openInvoiceDocumentEditorDialog: false, rowData: null });

		this.fetchDashboard(0, 0);
	}

	getRoutedToResources(selectedResources, invoiceDocumentId) {
		var routedToResources = [];

		selectedResources.map(function (selectedResource, selectedResourceIndex) {
			const invoiceDocumentResourceRoutingHistory = { invoiceDocumentId: invoiceDocumentId, resourceId: selectedResource.resourceId, email: selectedResource.email, comments: selectedResource.comments };

			routedToResources.push(invoiceDocumentResourceRoutingHistory);
		});

		return routedToResources;
	}

	getRefreshButton(gadgetNumber) {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginRight: 15, marginTop: 22 }}
			data-gadgetnumber={gadgetNumber}
		>
			<RefreshIcon />
		</IconButton>;
	}

	getUploadButton(gadgetNumber) {
		return <IconButton
			variant="contained"
			onClick={this.fileUpload_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginRight: 15, marginTop: 22 }}
			data-gadgetnumber={gadgetNumber}>
			<CloudUploadIcon />
		</IconButton>;
	}
	getScanButton(gadgetNumber) {
		return <IconButton
			variant="contained"
			onClick={this.scanAll_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginRight: 15, marginTop: 22 }}
			data-gadgetnumber={gadgetNumber}>
			<ScannerIcon />
		</IconButton>;
	}
	getRoutedToFilterDropDown() {
		return <Select style={{ float: "right", zIndex: 1, marginTop: 22, marginRight: 10, width: 100 }}
			value={this.state.selectedRoutedToResourceId}
			onChange={this.routedToResourceList_changeHandler}
		>
			<MenuItem value="-1">All Invoices</MenuItem>
			{
				this.state.distinctRoutedToResourceList.map(function (routedToResource, index) {
					return <MenuItem key={index} value={routedToResource.routedToResourceId}>{routedToResource.routedTo}</MenuItem>
				})
			}
		</Select>;
	}

	getWeeksToFilterDropDown(gadgetNumber) {
		return <Select
			style={{ float: "right", zIndex: 1, marginTop: 22, marginRight: 10, width: 150 }}
			value={this.state["weeksToFilter" + gadgetNumber]}
			onChange={this.weeksToFilter_changeHandler}
		>
			<MenuItem data-gadgetnumber={gadgetNumber} value={0}>No Filter</MenuItem>
			<MenuItem data-gadgetnumber={gadgetNumber} value={1}>1 Weeks</MenuItem>
			<MenuItem data-gadgetnumber={gadgetNumber} value={2}>2 Weeks</MenuItem>
			<MenuItem data-gadgetnumber={gadgetNumber} value={4}>5 Weeks (1 Month)</MenuItem>
			<MenuItem data-gadgetnumber={gadgetNumber} value={9}>9 Weeks (2 Months)</MenuItem>
			<MenuItem data-gadgetnumber={gadgetNumber} value={26}>26 Weeks (6 Months)</MenuItem>
			<MenuItem data-gadgetnumber={gadgetNumber} value={52}>52 Weeks (1 Year)</MenuItem>
		</Select>;
	}

	getDistinctRoutedToResourceList(unmatchedInvoices) {
		var distinctRoutedtoResources = [];

		unmatchedInvoices.map(function (unmatchedInvoice, index) {
			var routedToResource = {				
				routedToResourceId: defaultStringIfNullOrEmpty(unmatchedInvoice.routedToResourceId, "Not Routed"),
				routedTo: unmatchedInvoice.routedTo
			};

			if (!distinctRoutedtoResources.some(r => r.routedToResourceId === defaultStringIfNullOrEmpty(unmatchedInvoice.routedToResourceId, "Not Routed"))) {
				distinctRoutedtoResources.push(routedToResource);
			};
		});

		return distinctRoutedtoResources.sort((a, b) => (a.routedToResourceId > b.routedToResourceId ? 0 : -1));
	}

	getFilteredUnmatchedInvoices(unmatchedInvoices, routedToResourceId) {
		var filteredUnmatchedInvoices = [];

		filteredUnmatchedInvoices = routedToResourceId === "-1" ? unmatchedInvoices : unmatchedInvoices.filter(unmatchedInvoice => defaultStringIfNullOrEmpty(unmatchedInvoice.routedToResourceId, "Not Routed") === routedToResourceId);

		return filteredUnmatchedInvoices;
	}

	displayUploadStatus(uploadStatus) {
		switch (uploadStatus) {
			case 1:
				return <div>Not Processed</div>;
			case 2:
				return <div>Scan in Progress</div>;
			default:
				return <div>Unknown</div>;
		}
	}

	displayActions(rowData) {
		return rowData.approvalLinkText.length > 0 ? <Link data-rowid={rowData.rowId} data-source="pendingApprovalInvoices" data-mode="Approval" href="#" onClick={this.invoiceDocument_clickHandler}>{rowData.approvalLinkText}</Link> : <></>;
	}
	displayVendorName(rowData) {
		return stringIsNullOrEmpty(rowData.vendorName) ? rowData.vendorCode : rowData.vendorName;		
	}
	getRoutedToInfoColumn(rowData) {
		var returnValue = <div></div>;

		if (rowData.routedTo === "Not Routed") {
			if (rowData.suggestionFlag === "H") {
				returnValue = <Link href="#"><Tooltip title="route to IO/PO resources" ><EmojiObjectsIcon style={{ color: green[500] }} data-invoicedocumentid={rowData.invoiceDocumentId} onClick={this.suggestionFlag_clickHandler} /></Tooltip></Link>;
			}
			else if (rowData.suggestionFlag === "L") {
				returnValue = <Link href="#"><Tooltip title="route to any resources"><EmojiObjectsOutlinedIcon style={{ color: orange[500] }} data-invoicedocumentid={rowData.invoiceDocumentId} onClick={this.suggestionFlag_clickHandler} /></Tooltip></Link>;
			}
		}
		else {
			if (rowData.suggestionFlag === "N") {
				if (rowData.createId === "system") {
					returnValue = <Tooltip title="routed by system" ><DirectionsIcon color="primary" /></Tooltip>;
				}
				else {
					returnValue = <Tooltip title={rowData.comments} ><MessageIcon color="primary" /></Tooltip>;
				}
			}
		}

		return returnValue;
	}
	

	render() {
		const info = {
			modelDefId: this.props.modelDefId,
			invoiceDocumentFileLocation: this.state.invoiceDocumentFileLocation,
			inProgress: this.props.inProgress,
			onClose: this.closeDialog
		}

		const InvoiceDocumentEditorInfo = {
			rowData: this.state.rowData,
			mode: this.state.mode,
			modelDefId: this.state.modelDefId,
			isViaQueryString: this.state.isViaQueryString,
			inProgress: this.props.inProgress
		}

		const poHeaderResourceInfo = {
			inProgress: this.props.inProgress,
			openDialog: this.state.openPOHeaderResourceDialog,
			vendorCode: null,
			siteId: null,
			invoiceDocumentId: this.state.invoiceDocumentId
		};

		const level2ResourceInfo = {
			inProgress: this.props.inProgress,
			openDialog: this.state.openLevel2ResourceDialog,
			vendorCode: null,
			siteId: null,
			invoiceDocumentId: this.state.invoiceDocumentId
		};

		const spacing = 1;
		const fontSize = 12;
		const cellStyle = { fontSize: fontSize, padding: 8 };
		const headerStyle = { fontSize: fontSize, padding: 8 };
		const fontStyle = { fontSize: fontSize, padding: 8 };
		const gridStyle = { flexGrow: 1 };

		const tableOptionsTopLeft = {
			pageSize: 5,
			pageSizeOptions: [5, 10, 20, 30, 40, 50],
			minBodyHeight: 230,
			maxBodyHeight: 230,
			headerStyle: { fontSize: fontSize },
			searchFieldStyle: fontStyle,
			actionsColumnIndex: -1,
			selection: true,
			showTextRowsSelected: false,			
			selectionProps: (rowData) => {
				const checked = rowData.invoiceDocumentScanStatusId === 30 ? { checked: false } : {};
				return {
					color: 'default',
					disabled: rowData.invoiceDocumentScanStatusId === 30,
					...checked
				}
			}
		};

		const tableOptionsTopRight = {
			pageSize: 5,
			pageSizeOptions: [5, 10, 20, 30, 40, 50],
			minBodyHeight: 230,
			maxBodyHeight: 230,
			headerStyle: fontStyle,
			searchFieldStyle: fontStyle,
			showTextRowsSelected: false,
			selection: true,
			showSelectAllCheckbox: false,
			selectionProps: rowData => ({
				color: 'default'
			})
		};

		const tableOptionsBottom = {
			pageSize: 5,
			pageSizeOptions: [5, 10, 20, 30, 40, 50],
			minBodyHeight: 320,
			maxBodyHeight: 320,
			headerStyle: fontStyle,
			searchFieldStyle: fontStyle
		};

		return (
			<div>
				<h1>{this.props.modelDefId === 1 ? 'Media' : 'Production'} Invoices Dashboard</h1>
				<Grid container style={gridStyle} spacing={spacing}>
					<Grid item xs={12}>
						<Grid container justify="center" spacing={spacing}>
							<Grid key={1} item xs={6}>
								{this.getRefreshButton(1)}
								<input
									type="file"
									multiple
									accept="application/pdf,image/jpg,image/jpeg,image/png"
									id="contained-button-file"
									style={{ display: "none" }}
									onChange={this.fileUpload_changeHandler} />
								
								{this.getScanButton(5)}
								{this.getUploadButton(1)}
								{this.getWeeksToFilterDropDown(1)}
								<MaterialTable
									title="Invoice Queue (waiting to be scanned)"
									columns={[
										{
											title: 'File Name',
											field: 'fileName',
											cellStyle: cellStyle,
											headerStyle: headerStyle
										},
										{
											title: 'Received Date',
											field: 'documentManualUploadFileUpdateDate',
											type: 'datetime',
											defaultSort: 'desc',
											cellStyle: cellStyle,
											headerStyle: headerStyle
										},
										{
											title: 'Status',
											field: 'uploadStatus',
											cellStyle: cellStyle,
										headerStyle: headerStyle,
											render: rowData => this.displayUploadStatus(rowData.uploadStatus)
										},
										{
											title: 'View',
								cellStyle: cellStyle,
								headerStyle: headerStyle,
											render: rowData => (
												<Link data-rowid={rowData.rowId} data-gadgetname="queuedInvoices" href="#" onClick={this.viewLink_clickHandler}>View</Link>
											)
										},
										{
											title: 'Scan Now',
								cellStyle: cellStyle,
								headerStyle: headerStyle,
											width: 20,
											render: rowData => (
												rowData.uploadStatus === 1 ? <Link data-documentmanualuploadrecordid={rowData.documentManualUploadRecordId} href="#" onClick={this.scan_clickHandler}>Scan</Link> : null
											)
										}
									]}									
									options={tableOptionsTopLeft}
									onSelectionChange={(rows) => { this.scanSelectedRows = rows }}
									data={this.state.queuedInvoices}
								/>
							</Grid>
							<Grid key={2} item xs={6}>
								{this.getRefreshButton(2)}
								<IconButton
									variant="contained"
									onClick={this.bulkRouteToEmployee_clickHandler}
									size="small"
									style={{ float: "right", zIndex: 1, marginRight: 10, marginTop: 22 }}
								>
									<RouteToEmployeeIcon />
								</IconButton>
								{this.getRoutedToFilterDropDown()}
								{this.getWeeksToFilterDropDown(2)}
								<MaterialTable
									title="Unmatched/Discrepant Invoices"
									columns={[
										{
											title: 'Vendor Name',
											field: 'vendorName',
											cellStyle: cellStyle,
											maxWidth: 50,
											width: 50,											
											render: rowData => { return this.displayVendorName(rowData); }
										},
										{
											title: 'Status',
											field: 'status',
											cellStyle: cellStyle,
											maxWidth: 60,
											width: 50,
											render: rowData => rowData.status + (rowData.duplicateInvoiceFlag === 'Y' ? " (Duplicate Invoice!)" : "")
										},
										{
											title: 'File Name',
											field: 'fileName',											
											
											cellStyle: cellStyle
										},
										{
											title: 'Routed To',
											field: 'routedTo',
											cellStyle: cellStyle,
											hidden: !this.isAdmin,
											maxWidth: 50,
											width: 50,
											render: rowData => <div>
												{rowData.routedTo.indexOf("...") > 1 ?
													<Tooltip title={rowData.routedToCsv}>
														<Typography variant="inherit">{rowData.routedTo}</Typography>
													</Tooltip>
													: rowData.routedTo}
											</div>
										},
										{
											cellStyle: cellStyle,
											maxWidth: 30,
											width: 30,
											render: rowData => { return this.getRoutedToInfoColumn(rowData); }
										},
										{
											title: 'Date Scanned',
											field: 'invoiceDocumentScanUpdateDate',
											type: 'datetime',
											defaultSort: 'desc',
											maxWidth: 50,
											width: 50,
											cellStyle: cellStyle
										},
										{
											title: 'Action',
											cellStyle: cellStyle,
											maxWidth: 50,
											width: 50,
											render: rowData => (
												<Link data-rowid={rowData.rowId} data-source="unmatchedInvoices" data-mode="MakeCorrections" href="#" onClick={this.invoiceDocument_clickHandler}>Make Corrections</Link>
											)
										}
									]}
									options={tableOptionsTopRight}
									onSelectionChange={(rows) => { this.selectedRows = rows }}
									data={this.state.filteredUnmatchedInvoices}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container justify="center" spacing={spacing}>
							<Grid key={1} item xs={6}>
								{this.getRefreshButton(3)}
								{this.getWeeksToFilterDropDown(3)}
								<MaterialTable
									title="Pending Approval"
									columns={[
										{
											title: 'Vendor Name',
											field: 'vendorName',
											cellStyle: cellStyle,
											render: rowData =>  this.displayVendorName(rowData)
										},
										{
											title: 'Invoice Number',
											field: 'invoiceNumber',
											cellStyle: cellStyle
										},
										{
											title: 'Total Invoice Amt',
											field: 'totalAmount',
											type: 'currency',
											headerStyle: {
												textAlign: 'right',
											},
											cellStyle: {
												paddingRight: 8,
												fontSize: 12
											},
											render: rowData => formatNumber(rowData.totalAmount) + " " + rowData.currencyCode
										},
										{
											title: 'Invoice Date',
											field: 'invoiceDate',
											type: 'date',
											cellStyle: cellStyle
										},
										{
											title: 'Assigned To',
											field: 'assignedTo',
											cellStyle: cellStyle,
											render: rowData => {
												if (rowData.assignedTo && rowData.assignedTo.split('|').length>1)
													return <div>	
														<Tooltip
															title={
																<span style={{ whiteSpace: 'pre-line' }}>{rowData.assignedTo.split('|').join("\n")}</span>
															}>
															<Link>{rowData.assignedTo.split('|')[0]} ...</Link>
														</Tooltip>														
													</div>;
												return rowData.assignedTo;
											}												
										},
										{
											title: 'Previously Approved',
											field: 'approvedBy',
											cellStyle: cellStyle
										},
										{
											title: 'Status',
											field: 'status',
											cellStyle: cellStyle
										},
										{
											title: 'Review Invoice',
											cellStyle: cellStyle,
											render: rowData => this.displayActions(rowData)
										}
									]}
									options={tableOptionsBottom}
									data={this.state.pendingApprovalInvoices}
								/>
							</Grid>
							<Grid key={2} item xs={6}>
								{this.getRefreshButton(4)}
								{this.getWeeksToFilterDropDown(4)}
								<MaterialTable
									title="Approved Invoices"
									columns={[
										{
											title: 'Vendor Name',
											field: 'vendorName',
											cellStyle: cellStyle,
											render: rowData => { return this.displayVendorName(rowData); }
										},
										{
											title: 'Invoice Number',
											field: 'invoiceNumber',
											width: 10,
											cellStyle: cellStyle
										},
										{
											title: 'Total Invoice Amt',
											field: 'totalAmount',
											width: 10,
											type: 'currency',
											headerStyle: {
												textAlign: 'right',
											},
											cellStyle: {
												paddingRight: 8,
												fontSize: 12
											},
											render: rowData => formatNumber(rowData.totalAmount) + " " + rowData.currencyCode
										},
										{
											title: 'Date Approved',
											field: 'approvalRejectionUpdateDate',
											type: 'datetime',
											cellStyle: cellStyle
										},
										{
											title: 'Last Approved By',
											field: 'approvedBy',
											cellStyle: cellStyle
										},
										{
											title: 'Status',
											field: 'pendingStatus',
											cellStyle: cellStyle,
											render: rowData =>
												<div>
													{rowData.pendingStatus}
													<Tooltip title={rowData.invoiceDocumentPostingErrorMessage ? rowData.invoiceDocumentPostingErrorMessage : ""}>
														<ErrorIcon style={{ float: "right", display: rowData.invoiceDocumentStatusId === 180 ? "" : "none" }} color="secondary" />
													</Tooltip>
												</div>
										},
										{
											title: 'View',
											cellStyle: cellStyle,
											render: rowData => (
												<Link data-rowid={rowData.rowId} data-source="approvedInvoices" data-mode="View" href="#" onClick={this.invoiceDocument_clickHandler}>View</Link>
											)
										}
									]}
									options={tableOptionsBottom}
									data={this.state.approvedInvoices}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Dialog
					open={this.state.openInvoiceViewer}
					aria-labelledby="draggable-dialog-title"
					TransitionComponent={Transition}
					fullWidth={true}
					maxWidth="lg"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Invoice Document
					</DialogTitle>
					<DialogContent>
						<InvoiceDocumentViewer info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.closeDialog} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.openInvoiceDocumentEditorDialog} TransitionComponent={Transition} fullScreen>
					<InvoiceDocumentEditor info={InvoiceDocumentEditorInfo} onClose={this.invoiceDocumentEditor_closeHandler} />
				</Dialog>
				<POHeaderResourceLookup info={poHeaderResourceInfo} onClose={this.poHeaderResourceDialog_closeHandler} />
				<Level2ResourceLookup info={level2ResourceInfo} onClose={this.level2ResourceDialog_closeHandler} />
			</div >
		);
	}
}
