import * as React from 'react';
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import { formatNumber } from '../Common/Utilities';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';

export default class PODetails extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: ""
		};
	}

	componentDidMount() {
		this.fetchPODetails();
	}

	fetchPODetails() {
		var that = this;

		this.props.info.inProgress(true);

		httpGet('/api/setup/PODetails/' + this.props.info.poCode)
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				that.setState({ data: data.records, lastUpdated: localDateString });

				this.props.info.inProgress(false);
			})
			.catch((error) => {
				this.props.info.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		const cellStyle = { paddingLeft: 8, paddingRight: 42 };
		const headerStyle = { textAlign: 'right' };

		return (
			<div>
				<h1>Purchase Order Details</h1>
				<div className="form-group">
					<MaterialTable
						title={"Last Updated: " + this.state.lastUpdated}
						columns={[
							{
								title: 'Line #',
								field: 'lineId',
								width: 50
							},
							{
								title: 'Project',
								field: 'level2Key'
							},
							{
								title: 'Activity',
								field: 'level3Key'
							},
							{
								title: 'Due Date',
								field: 'dueDate',
								type: 'date'
							},
							{
								title: 'Quantity',
								field: 'quantity',
								type: 'numeric'
							},
							{
								title: 'Net Cost',
								field: 'netCost',
								type: 'currency',
								cellStyle: cellStyle,
								headerStyle: headerStyle,
								render: rowData => formatNumber(rowData.netCost) + " " + this.props.info.currencyCode
							},
							{
								title: 'Match to Date Net',
								field: 'matchToDateNet',
								type: 'numeric'
							},
							{
								title: 'Closed',
								field: 'closeFlag',
								render: rowData => { return rowData.closeFlag === 1 ? "Y" : "N"; }
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670
						}}
						data={this.state.data}
					/>
				</div>
			</div>
		);
	}
}
