import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialTable from 'material-table';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import { httpDelete, httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import { PositionCategoryCodes } from './PositionCategoryCodes';

export default class UserRoles extends React.Component {
	constructor() {
		super();

		this.currentEditRow = null;

		this.state = {
			data: [],
			company: {},
			positionCategoryList: [],
			userRolesInvoiceRightsList: []
		};

		this.selectedPositionCategoryCodes_onClose = this.selectedPositionCategoryCodes_onClose.bind(this);
	}

	componentDidMount() {
		this.fetchUserRoles().then((response) => {
			const userInfo = JSON.parse(localStorage.userInfo);

			this.props.inProgress(true);

			httpGet("/api/company/" + userInfo.companyCode)
				.then((response) => {
					var data = response.data;

					this.props.inProgress(false);
					this.setState({ company: data });
				});

			this.props.inProgress(true);

			httpGet("/api/positioncategory/")
				.then((response) => {
					var positionCategoryList = response.data;

					this.props.inProgress(false);

					this.setState({ positionCategoryList: positionCategoryList });
				})

		}).catch((error) => {
			this.props.inProgress(false);

			toast.error('An error occurred while fetching the records');
			console.error('There was an error!', error);
		});
	}

	fetchUserRoles() {
		var that = this;

		this.props.inProgress(true);

		var promise = httpGet("/api/userroles/")
			.then((response) => {
				var data = response.data;
				var rowId = 1;

				data.map(row => { row.rowId = rowId++; that.convertToBooleanValues(row); });

				var userRolesInvoiceRightsList = data[0].userRolesInvoiceRightsList;

				this.props.inProgress(false);

				this.setState({ data: data, userRolesInvoiceRightsList: userRolesInvoiceRightsList });
			}).catch((error) => {
				this.props.inProgress(false);

				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});

		return promise;
	}

	saveUserRoles(data, resolve, reject) {
		data.isAdminRole = data.isAdminRole ? "Y" : "N";
		data.allowRoutingSetup = data.allowRoutingSetup ? "Y" : "N";

		data.userRolesInvoiceRightsList.map(function (userRolesInvoiceRights, index) {
			var modelDefId = userRolesInvoiceRights.modelDefId;

			userRolesInvoiceRights.allowNonMappedInvoicesRequestProcessing = data["allowNonMappedInvoicesRequestProcessing_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceModelMapping = data["allowInvoiceModelMapping_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceAutoRouting = data["allowInvoiceAutoRouting_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceManualProcessing = data["allowInvoiceManualProcessing_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceDocumentManagement = data["allowInvoiceDocumentManagement_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceCorrection = data["allowInvoiceCorrection_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceLevel1Approval = data["allowInvoiceLevel1Approval_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceLevel2Approval = data["allowInvoiceLevel2Approval_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceLevel3Approval = data["allowInvoiceLevel3Approval_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceLevel4Approval = data["allowInvoiceLevel4Approval_" + modelDefId] ? "Y" : "N";
			userRolesInvoiceRights.allowInvoiceLevel5Approval = data["allowInvoiceLevel5Approval_" + modelDefId] ? "Y" : "N";
		});

		this.props.inProgress(true);

		httpPost("/api/userroles/", data)
			.then((response) => {
				this.props.inProgress(false);
				toast.success('Updated Successfully');
				resolve();
				this.currentEditRow = null;
				this.fetchUserRoles();
			}).catch((error) => {
				reject();
				this.props.inProgress(false);
				toast.error(<div>User Role Update Failed<br />{error.response.data.detail}</div>);
			});
	}

	deleteUserRoles(data, resolve, reject) {
		this.props.inProgress(true);

		httpDelete("/api/userroles/" + data.companyCode + "/" + data.userRoleId)
			.then((response) => {
				this.props.inProgress(false);
				toast.success('Deleted Successfully');
				resolve();
				this.fetchUserRoles();
			}).catch((error) => {
				reject();
				this.props.inProgress(false);
				toast.error(<div>Delete Failed<br />{error.response.data}</div>);
			});
	}

	isRecordValid(data) {
		var returnValue = true;
		var message = "";

		if (data.userRoleName === undefined || data.userRoleName.trim().length === 0) {
			message += "User Group Name";
		}

		if (message.length > 0) {
			toast.error("Please Provide " + message);
			returnValue = false;
		}

		return returnValue;
	}

	convertToBooleanValues(row) {
		row.isAdminRole = row.isAdminRole === 'Y';
		row.allowRoutingSetup = row.allowRoutingSetup === 'Y';

		row.userRolesInvoiceRightsList.map(function (userRolesInvoiceRights, index) {
			var modelDefId = userRolesInvoiceRights.modelDefId;

			row["allowNonMappedInvoicesRequestProcessing_" + modelDefId] = userRolesInvoiceRights.allowNonMappedInvoicesRequestProcessing === 'Y';
			row["allowInvoiceModelMapping_" + modelDefId] = userRolesInvoiceRights.allowInvoiceModelMapping === 'Y';
			row["allowInvoiceAutoRouting_" + modelDefId] = userRolesInvoiceRights.allowInvoiceAutoRouting === 'Y';
			row["allowInvoiceManualProcessing_" + modelDefId] = userRolesInvoiceRights.allowInvoiceManualProcessing === 'Y';
			row["allowInvoiceDocumentManagement_" + modelDefId] = userRolesInvoiceRights.allowInvoiceDocumentManagement === 'Y';
			row["allowInvoiceCorrection_" + modelDefId] = userRolesInvoiceRights.allowInvoiceCorrection === 'Y';
			row["allowInvoiceLevel1Approval_" + modelDefId] = userRolesInvoiceRights.allowInvoiceLevel1Approval === 'Y';
			row["allowInvoiceLevel2Approval_" + modelDefId] = userRolesInvoiceRights.allowInvoiceLevel2Approval === 'Y';
			row["allowInvoiceLevel3Approval_" + modelDefId] = userRolesInvoiceRights.allowInvoiceLevel3Approval === 'Y';
			row["allowInvoiceLevel4Approval_" + modelDefId] = userRolesInvoiceRights.allowInvoiceLevel4Approval === 'Y';
			row["allowInvoiceLevel5Approval_" + modelDefId] = userRolesInvoiceRights.allowInvoiceLevel5Approval === 'Y';
		});
	}

	getEmptyUserRolesInvoiceRightsList() {
		var userRolesInvoiceRightsList = [];

		this.state.userRolesInvoiceRightsList.map(function (userRolesInvoiceRights, index) {
			userRolesInvoiceRightsList.push({ modelDefId: userRolesInvoiceRights.modelDefId });
		});

		return userRolesInvoiceRightsList;
	}

	getPositionCategoryName(apadUserRolePositionCategories) {
		var assignedUserRolePositionCategories = "";

		if (this.state.positionCategoryList.length > 0 && apadUserRolePositionCategories && apadUserRolePositionCategories.length > 0) {
			assignedUserRolePositionCategories = this.state.positionCategoryList.find(pc => pc.positionCategoryCode === apadUserRolePositionCategories[0].positionCategoryCode).positionCategoryName + (apadUserRolePositionCategories.length > 1 ? "..." : "");
		}

		return assignedUserRolePositionCategories;
	}

	getPositionCategoryNameCSV(apadUserRolePositionCategories) {
		var assignedUserRolePositionCategories = [];

		if (this.state.positionCategoryList.length > 0 && apadUserRolePositionCategories && apadUserRolePositionCategories.length > 0) {
			apadUserRolePositionCategories.map((positionCategory, index) => (
				assignedUserRolePositionCategories.push(this.state.positionCategoryList.find(pc => pc.positionCategoryCode === positionCategory.positionCategoryCode).positionCategoryName)
			))
		}

		return assignedUserRolePositionCategories.join(", ");
	}

	selectedPositionCategoryCodes_onClose(selectedPositionCategories) {
		if (selectedPositionCategories) {
			var apadUserRolePositionCategories = [];

			selectedPositionCategories.map((positionCategory, index) => (
				apadUserRolePositionCategories.push({ companyCode: positionCategory.companyCode, userRoleId: this.currentEditRow.userRoleId, positionCategoryCode: parseInt(positionCategory.positionCategoryCode, 10) })
			));

			this.currentEditRow.apadUserRolePositionCategories = apadUserRolePositionCategories;

			this.setState({ dummy: null });
		}
	}

	render() {
		var that = this;

		const columns = [
			{
				title: 'User Group Name',
				field: 'userRoleName',
				width: 400
			},
			{
				title: 'Role',
				field: 'apadUserRolePositionCategories',
				width: 200,
				render: rowData => {
					return <div>
						<Tooltip title={this.getPositionCategoryNameCSV(rowData.apadUserRolePositionCategories)}>
							<Typography variant="inherit">{this.getPositionCategoryName(rowData.apadUserRolePositionCategories)}</Typography>
						</Tooltip>
					</div>
				},
				editComponent: props => {
					this.currentEditRow = props.rowData;

					const info = {
						positionCategoryList: this.state.positionCategoryList,
						apadUserRolePositionCategories: props.rowData.apadUserRolePositionCategories ? props.rowData.apadUserRolePositionCategories : []
					};

					return <div>
						<Tooltip title={this.getPositionCategoryNameCSV(this.currentEditRow.apadUserRolePositionCategories)}>
							<Typography variant="inherit">{this.getPositionCategoryName(this.currentEditRow.apadUserRolePositionCategories)}</Typography>
						</Tooltip>
						<PositionCategoryCodes info={info} onClose={this.selectedPositionCategoryCodes_onClose} />
					</div>
				}
			},
			{
				title: 'Admin',
				field: 'isAdminRole',
				type: 'boolean',
				editComponent: props => (
					<Checkbox
						color="default"
						checked={props.value}
						onChange={e => props.onChange(e.target.checked)}
					/>
				)
			},
			{
				title: 'Routing Setup',
				field: 'allowRoutingSetup',
				type: 'boolean',
				editComponent: props => (
					<Checkbox
						color="default"
						checked={props.value}
						onChange={e => props.onChange(e.target.checked)}
					/>
				)
			}
		];

		if (this.state.company && this.state.company.companyApprovalConfigurations) {
			var approvalConfigs = this.state.company.companyApprovalConfigurations;

			approvalConfigs.map(function (approvalConfig, index) {
				var modelDefId = approvalConfig.modelDefId;
				var invoiceType = approvalConfig.modelDefId === 2 ? 'Production' : 'Media';

				columns.push(
					{
						title: invoiceType + ' Non Mapped Invoices Request Processing',
						field: 'allowNonMappedInvoicesRequestProcessing_' + modelDefId,
						type: 'boolean',
						editComponent: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => props.onChange(e.target.checked)}
							/>
						)
					},
					{
						title: invoiceType + ' Model Mapping',
						field: 'allowInvoiceModelMapping_' + modelDefId,
						type: 'boolean',
						editComponent: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => props.onChange(e.target.checked)}
							/>
						)
					},
					{
						title: invoiceType + ' Receive Discrepant Invoice',
						field: 'allowInvoiceAutoRouting_' + modelDefId,
						type: 'boolean',
						editComponent: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => props.onChange(e.target.checked)}
							/>
						)
					},
					{
						title: invoiceType + ' Manual Processing',
						field: 'allowInvoiceManualProcessing_' + modelDefId,
						type: 'boolean',
						editComponent: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => props.onChange(e.target.checked)}
							/>
						)
					},
					{
						title: invoiceType + ' Document Management',
						field: 'allowInvoiceDocumentManagement_' + modelDefId,
						type: 'boolean',
						editComponent: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => props.onChange(e.target.checked)}
							/>
						)
					},
					{
						title: invoiceType + ' Correction',
						field: 'allowInvoiceCorrection_' + modelDefId,
						type: 'boolean',
						editComponent: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => props.onChange(e.target.checked)}
							/>
						)
					},
					{
						title: invoiceType + ' Level 1 Approval',
						field: 'allowInvoiceLevel1Approval_' + modelDefId,
						type: 'boolean',
						editComponent: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => props.onChange(e.target.checked)}
							/>
						)
					});

				if (approvalConfig.routingNumberOfApprovalLevels >= 2) {

					columns.push({
						title: invoiceType + ' Level 2 Approval',
						field: 'allowInvoiceLevel2Approval_' + modelDefId,
						type: 'boolean',
						editComponent: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => props.onChange(e.target.checked)}
							/>
						)
					});
				}

				if (approvalConfig.routingNumberOfApprovalLevels >= 3) {
					columns.push({
						title: invoiceType + ' Level 3 Approval',
						field: 'allowInvoiceLevel3Approval_' + modelDefId,
						type: 'boolean',
						editComponent: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => props.onChange(e.target.checked)}
							/>
						)
					});
				}

				if (approvalConfig.routingNumberOfApprovalLevels >= 4) {
					columns.push({
						title: invoiceType + ' Level 4 Approval',
						field: 'allowInvoiceLevel4Approval_' + modelDefId,
						type: 'boolean',
						editComponent: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => props.onChange(e.target.checked)}
							/>
						)
					});
				}

				if (approvalConfig.routingNumberOfApprovalLevels >= 5) {
					columns.push({
						title: invoiceType + ' Level 5 Approval',
						field: 'allowInvoiceLevel5Approval_' + modelDefId,
						type: 'boolean',
						editComponent: props => (
							<Checkbox
								color="default"
								checked={props.value}
								onChange={e => props.onChange(e.target.checked)}
							/>
						)
					});
				}
			});
		}

		return (
			<div>
				<h1>User Groups Management</h1>
				<div className="form-group">
					<MaterialTable
						title=""
						key="rowId"
						columns={columns}
						data={this.state.data}
						options={{
							actionsColumnIndex: -1,
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 650
						}}
						editable={
							{
								onRowAdd: newData =>
									new Promise((resolve, reject) => {
										newData.rowId = 0;
										newData.userRoleId = 0;
										newData.companyCode = parseInt(this.state.company.companyCode);
										newData.userRolesInvoiceRightsList = this.getEmptyUserRolesInvoiceRightsList();
										newData.apadUserRolePositionCategories = that.currentEditRow.apadUserRolePositionCategories;

										if (this.isRecordValid(newData)) {
											this.saveUserRoles(newData, resolve, reject);
										}
										else {
											reject();
										}
									}),
								onRowUpdate: (newData, oldData) =>
									new Promise((resolve, reject) => {
										newData.apadUserRolePositionCategories = that.currentEditRow.apadUserRolePositionCategories;

										if (this.isRecordValid(newData)) {
											this.saveUserRoles(newData, resolve, reject);
										}
										else {
											reject();
										}
									}),
								onRowDelete: oldData =>
									new Promise((resolve, reject) => {
										this.deleteUserRoles(oldData, resolve, reject);
									}),
							}
						}
					/>
				</div>
			</div>
		);
	}
}
