import { loginUser } from './loginAction';
import actionType from '../constants/constants'
export const getProfileFetch = () => {
    
    return dispatch => {
       
        const token = localStorage.token;
        if (token) {
            
            return fetch("api/Auth/profile", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(resp => {
                    try {
                        if (resp.status === 200) {
                            var json = resp.json();
                            return json;
                        }
                    } catch{
                    }
                    localStorage.removeItem("token");
                    return {message: "Token Expired"};
                })
                .then(data => {
                    if (data.message) {
                        // An error will occur if the token is invalid.
                        // If this happens, you may want to remove the invalid token.
                        localStorage.removeItem("token")
                    } else {
                        dispatch(loginUser(data.user))
                    }
                })
        }
    }
}
