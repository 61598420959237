import React from 'react'
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table'
import RefreshIcon from '@material-ui/icons/Refresh';
import { toast } from 'react-toastify';
import Zoom from '@material-ui/core/Zoom';
import { InvoiceDocumentViewer } from '../InvoiceDocument/InvoiceDocumentViewer';
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class ScannedSuccessful extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isDirty: false,
			openInvoiceViewer: false
		};

		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.viewLink_clickHandler = this.viewLink_clickHandler.bind(this);
		this.checkbox_clickHandler = this.checkbox_clickHandler.bind(this);
		this.submit_clickHandler = this.submit_clickHandler.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
	}

	componentDidMount() {
		this.fetchScannedInvoices();
	}

	fetchScannedInvoices() {
		var that = this;

		this.props.info.inProgress(true, false, 0);

		httpGet('/api/nonmappedinvoices/scannedinvoices/10/' + this.props.info.modelDefId)
			.then((response) => {
				var data = response.data;

				this.props.info.inProgress(false, false, 0);
				that.setState({ data: data, isDirty: false });
			}).catch((error) => {
				this.props.info.inProgress(false, false, 0);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	refresh_clickHandler(event) {
		this.fetchScannedInvoices();
	}

	viewLink_clickHandler(event) {
		event.preventDefault();

		var rowData = this.state.data.find(d => d.scannerMonitorRecordId === parseInt(event.target.dataset.rowid, 10));

		this.setState({ openInvoiceViewer: true, invoiceDocumentFileLocation: rowData.invoiceDocumentFileLocation });
	}

	checkbox_clickHandler(event) {
		var rows = this.state.data;
		var checkbox = event.target;
		var scannerMonitorRecordId = parseInt(checkbox.name.substr("scannedSuccessful".length, checkbox.name.length), 10);
		var index = rows.findIndex(d => d.scannerMonitorRecordId === scannerMonitorRecordId);

		rows[index].requestToMap = false;
		rows[index].manualProcessing = false;
		rows[index].remove = false;

		rows[index][checkbox.value] = checkbox.checked;

		this.setState({ data: rows, isDirty: true });
	}

	submit_clickHandler(event) {
		this.props.info.inProgress(true, false, 0);

		httpPost("/api/nonmappedinvoices/scannedinvoices/", this.state.data)
			.then((response) => {
				this.props.info.inProgress(false, false, 0);

				toast.success("Records saved successfully");
				this.fetchScannedInvoices();
			}).catch((error) => {
				this.props.info.inProgress(false, false, 0);
				toast.error('An error occurred while updating the records');
			});
	}

	closeDialog(event) {
		this.setState({ openInvoiceViewer: false });
	}

	render() {
		const info = {
			modelDefId: this.props.info.modelDefId,
			invoiceDocumentFileLocation: this.state.invoiceDocumentFileLocation,
			inProgress: this.props.info.inProgress,
			onClose: this.closeDialog
		}

		return (
			<div>
				<Button
					variant="contained"
					color="default"
					onClick={this.refresh_clickHandler}
					size="medium"
					style={{ float: "right", zIndex: 1, margin: 10 }}
					startIcon={<RefreshIcon />}>Refresh</Button>
				<MaterialTable
					title=""
					columns={[
						{
							title: 'File Name',
							field: 'fileName'
						},
						{
							title: 'Timestamp',
							field: 'invoiceDocumentScanUpdateDate',
							type: 'datetime'
						},
						{
							title: 'View',
							render: rowData => (
								<Link data-rowid={rowData.scannerMonitorRecordId} href="#" onClick={this.viewLink_clickHandler}>View</Link>
							)
						},
						{
							title: 'Request for Mapping and/or Vendor Assignment',
							type: 'boolean',
							headerStyle: {
								textAlign: 'center',
							},
							cellStyle: {
								textAlign: 'center'
							},
							render: rowData =>
								<Checkbox
									color="default"
									checked={rowData.requestToMap}
									name={"scannedSuccessful" + rowData.scannerMonitorRecordId}
									value="requestToMap"
									onClick={this.checkbox_clickHandler}
								/>
						},
						{
							title: 'Route for Manual Processing',
							type: 'boolean',
							headerStyle: {
								textAlign: 'center',
							},
							cellStyle: {
								textAlign: 'center'
							},
							render: rowData =>
								<Checkbox
									color="default"
									checked={rowData.manualProcessing}
									name={"scannedSuccessful" + rowData.scannerMonitorRecordId}
									value="manualProcessing"
									onClick={this.checkbox_clickHandler}
								/>
						},
						{
							title: 'Remove',
							type: 'boolean',
							headerStyle: {
								textAlign: 'center',
							},
							cellStyle: {
								textAlign: 'center'
							},
							render: rowData =>
								<Checkbox
									color="default"
									checked={rowData.remove}
									name={"scannedSuccessful" + rowData.scannerMonitorRecordId}
									value="remove"
									onClick={this.checkbox_clickHandler}
								/>
						}
					]}
					options={{
						pageSize: 10,
						pageSizeOptions: [10, 20, 30, 40, 50],
						maxBodyHeight: 550
					}}
					data={this.state.data}
				/>
				<div align="right" style={{ paddingTop: 10 }}>
					<Button variant="contained" size="large" onClick={this.submit_clickHandler} disabled={!this.state.isDirty}>Submit</Button>
				</div>
				<Dialog
					open={this.state.openInvoiceViewer}
					aria-labelledby="draggable-dialog-title"
					TransitionComponent={Transition}
					fullWidth={true}
					maxWidth="lg"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Invoice Document
					</DialogTitle>
					<DialogContent>
						<InvoiceDocumentViewer info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.closeDialog} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}
