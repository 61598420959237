import * as React from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Zoom from '@material-ui/core/Zoom';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import { formatNumber, isNullOrUndefined, stringIsNullOrEmpty } from '../Common/Utilities';


import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class POHeaderLookup extends React.Component {
	constructor(props) {
		super(props);

		this.vendorCode = null;
		this.siteId = null;
		this.serviceTermStartDate = null;
		this.serviceTermEndDate = null;
		this.invoiceDocumentId = 0;
		this.searchText = "";
		this.inProgress = false;
		this.doFetchRecords = true;

		this.state = {
			poCode: null,
			data: [],
			rowData: null
		};

		this.checkbox_clickHandler = this.checkbox_clickHandler.bind(this);
		this.close_clickHandler = this.close_clickHandler.bind(this);
		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
	}

	componentDidUpdate() {
		var that = this;

		if (this.props.info.vendorCode !== this.vendorCode ||
			this.props.info.siteId !== this.siteId ||
			this.props.info.serviceTermStartDate !== this.serviceTermStartDate ||
			this.props.info.serviceTermEndDate !== this.serviceTermEndDate ||
			this.props.info.invoiceDocumentId !== this.invoiceDocumentId) {
			this.vendorCode = this.props.info.vendorCode;
			this.siteId = this.props.info.siteId;
			this.serviceTermStartDate = this.props.info.serviceTermStartDate;
			this.serviceTermEndDate = this.props.info.serviceTermEndDate;
			this.invoiceDocumentId = this.props.info.invoiceDocumentId;

			this.doFetchRecords = true;
			this.inProgress = false;

			this.setState({ data: [], rowData: null });
		}

		if (this.props.info.openDialog && this.state.data.length === 0 && this.doFetchRecords && !this.inProgress) {
			this.inProgress = true;
			this.fetchRecords();
		}
		else if (this.props.info.poCode !== this.state.poCode) {
			var rowData = this.getRowData(this.props.info.poCode);

			this.setState({ poCode: this.props.info.poCode, rowData: rowData });
		}
	}

	checkbox_clickHandler(event) {
		var rowData = this.getRowData(event.target.value);

		this.setState({ rowData: rowData });
	}

	close_clickHandler(event) {
		this.searchText = "";

		if (this.props.onClose) {
			this.props.onClose(event, this.state.rowData);
		}
	}
	handleClose = (event) => {
		this.props.onClose(event, this.state.rowData);
	};
	refresh_clickHandler(event) {
		if (!stringIsNullOrEmpty(this.searchText)) {
			this.props.info.inProgress(true);

			httpGet("/api/invoicedocument/refetchpoinfo/" + this.props.info.modelDefId + '/' + this.searchText)
				.then((response) => {
					var data = response.data;

					this.doFetchRecords = true;
					this.props.info.inProgress(false);
					this.setState({ data: [] });

				}).catch((error) => {
					this.props.info.inProgress(false);

					toast.error('Error fetching IO/PO Number Lookup');
				});
		}
	}

	fetchRecords() {
		if (this.state.data.length === 0) {
			var url = "/api/lookup/poheaderlistbyvendor/" + encodeURIComponent(this.props.info.vendorCode) + "/" + encodeURIComponent(this.props.info.siteId) + "/" + this.props.info.modelDefId;
			url += "/" + (isNullOrUndefined(this.props.info.invoiceDocumentId) ? 0 : this.props.info.invoiceDocumentId);

			if (!stringIsNullOrEmpty(this.props.info.serviceTermStartDate) && !stringIsNullOrEmpty(this.props.info.serviceTermEndDate)) {
				url += "/" + this.props.info.serviceTermStartDate + "/" + this.props.info.serviceTermEndDate;
			}

			this.props.info.inProgress(true);

			httpGet(url)
				.then((response) => {
					var data = response.data;
					var rowData = data[data.findIndex(d => d.poCode === this.props.info.poCode)];

					this.inProgress = false;
					this.doFetchRecords = false;
					this.setState({ vendorCode: this.props.info.vendorCode, siteId: this.props.info.siteId, poCode: this.props.info.poCode, data: data, rowData: rowData });
					this.props.info.inProgress(false);
				}).catch((error) => {
					this.props.info.inProgress(false);

					toast.error('Error fetching IO/PO Number Lookup');

					this.close_clickHandler();
				});
		}
	}

	getRowData(value) {
		var data = this.state.data;

		return data[data.findIndex(d => d.poCode === value)];
	}

	getRefreshButton() {
		return <IconButton
			variant="contained"
			onClick={this.refresh_clickHandler}
			size="small"
			style={{ float: "right", zIndex: 1, marginRight: 15, marginTop: 22 }}>
			<RefreshIcon />
		</IconButton>;
	}

	render() {
		const cellStyle = { paddingRight: 45 };
		const headerStyle = { textAlign: 'right' };

		return (
			<Dialog open={this.props.info.openDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
				<DialogTitle>
					Select IO/PO Number
					<IconButton
						aria-label="close"
						onClick={this.handleClose}
						style={{
							position: "absolute",
							right: 8,
							float:"right",
							top: 8,
							color: (theme) => theme.palette.grey[500]
						}}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				
				<DialogContent>
					{this.getRefreshButton()}
					<MaterialTable
						title=""
						columns={[
							{
								width: 5,
								render: rowData =>
									<Checkbox
										color="default"
										name="chk"
										checked={this.state.rowData != null && this.state.rowData !== undefined && rowData.poCode === this.state.rowData.poCode}
										value={rowData.poCode}
										onClick={this.checkbox_clickHandler}
									/>
							},
							{
								title: (this.props.info.modelDefId === 2 ? 'PO Number' : 'IO/PO Number'),
								field: 'poCode',
								width: 50
							},
							{
								title: 'IO/PO Amount',
								field: 'poAmount',
								type: 'currency',
								cellStyle: cellStyle,
								headerStyle: headerStyle,
								render: rowData => formatNumber(rowData.poAmount)
							},
							{
								title: 'Consumed Amount',
								field: 'consumedAmount',
								type: 'currency',
								cellStyle: cellStyle,
								headerStyle: headerStyle,
								render: rowData => formatNumber(rowData.consumedAmount)
							},
							{
								title: 'Remaining Amount',
								type: 'currency',
								cellStyle: cellStyle,
								headerStyle: headerStyle,
								render: rowData => formatNumber(rowData.poAmount - rowData.consumedAmount)
							},
							{
								title: 'Delivery Amount',
								field: 'deliveryAmount',
								type: 'currency',
								cellStyle: cellStyle,
								headerStyle: headerStyle,
								hidden: this.props.info.modelDefId === 2,
								render: rowData => formatNumber(rowData.deliveryAmount)
							},
							{
								title: 'Currency',
								field: 'currencyCode'
							},
							{
								title: 'Client Name',
								field: 'clientName',
								width: 50
							},
							{
								title: 'Job Code',
								field: 'jobCode',
								width: 50
							},
							{
								title: (this.props.info.modelDefId === 2 ? 'Job Name' : 'Campaign Name'),
								field: 'campaignName',
								width: 50
							},
							{
								title: 'Media Plan Name',
								field: 'mediaPlanName',
								hidden: this.props.info.modelDefId === 2,
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 550
						}}
						data={this.state.data}
						onSearchChange={(searchText) => { this.searchText = searchText; }}
					/>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={this.close_clickHandler} color="primary">
						Select
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
