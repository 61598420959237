import * as React from 'react';
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';

export default class Level2Resources extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: ""
		};
	}

	componentDidMount() {
		this.fetchLevel2Resources();
	}

	fetchLevel2Resources() {
		var that = this;

		this.props.info.inProgress(true);

		httpGet('/api/setup/level2Resources/' + this.props.info.level2Key)
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				that.setState({ data: data.records, lastUpdated: localDateString });

				this.props.info.inProgress(false);
			})
			.catch((error) => {
				this.props.info.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		return (
			<div>
				<h1>Project Resources</h1>
				<div className="form-group">
					<MaterialTable
						title={"Last Updated: " + this.state.lastUpdated}
						columns={[
							{
								title: 'Resource',
								field: 'employeeName'
							},
							{
								title: 'Category',
								field: 'positionCategoryName',
							},
							{
								title: 'Effective Date',
								field: 'effectiveDate',
								type: 'date'
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670
						}}
						data={this.state.data}
					/>
				</div>
			</div>
		);
	}
}
