import React from 'react'
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import RefreshIcon from '@material-ui/icons/Refresh';
import Zoom from '@material-ui/core/Zoom';
import { toast } from 'react-toastify';
import { httpDelete, httpGet, httpPost, httpPut } from '../../HttpRequestHandling/httpRequestHandler';
import { InvoiceDocumentViewer } from '../InvoiceDocument/InvoiceDocumentViewer';
import { fileUpload } from '../NonMappedInvoices/ManualUploadApi';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class ManualUpload extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isDirty: false,
			selectedFiles: null,
			openDelete: false,
			openInvoiceViewer: false
		};

		this.refresh_clickHandler = this.refresh_clickHandler.bind(this);
		this.fileUpload_changeHandler = this.fileUpload_changeHandler.bind(this);
		this.viewLink_clickHandler = this.viewLink_clickHandler.bind(this);
		this.scan_clickHandler = this.scan_clickHandler.bind(this);
		this.deleteYes_clickHandler = this.deleteYes_clickHandler.bind(this);
		this.openDeleteDialog = this.openDeleteDialog.bind(this);
		this.closeDialog = this.closeDialog.bind(this);

		this.fetchManuallyUploadedInvoices();
	}

	fetchManuallyUploadedInvoices() {
		var that = this;

		this.props.info.inProgress(true, false, 0);

		httpGet('/api/nonmappedinvoices/manuallyuploadedinvoices/' + this.props.info.modelDefId)
			.then((response) => {
				var data = response.data;

				this.props.info.inProgress(false, false, 0);
				that.setState({ data: data, isDirty: false });
			}).catch((error) => {
				this.props.info.inProgress(false, false, 0);
				toast.error('An error occurred while fetching the records');
			});
	}

	refresh_clickHandler(event) {
		this.fetchManuallyUploadedInvoices();
	}

	fileUpload_changeHandler(event) {
		fileUpload(this, event, this.props.info.modelDefId, toast, httpPost,
			() => { this.props.info.inProgress(true, false, 0);},
			() => { this.props.info.inProgress(false, false, 0);},
			ProgressEvent => {
				this.props.info.inProgress(true, true, (ProgressEvent.loaded / ProgressEvent.total * 100));},
			() => this.fetchManuallyUploadedInvoices());
	}

	viewLink_clickHandler(event) {
		event.preventDefault();

		var rowData = this.state.data.find(d => d.documentManualUploadRecordId === parseInt(event.target.dataset.rowid, 10));

		this.setState({ openInvoiceViewer: true, invoiceDocumentFileLocation: rowData.documentManualUploadFileLocation });
	}

	scan_clickHandler(event) {
		this.props.info.inProgress(true, false, 0);

		httpPut("/api/nonmappedinvoices/manuallyuploadedinvoices/" + event.currentTarget.value, null)
			.then(res => {
				this.props.info.inProgress(false, false, 0);

				toast.success('File Scan in Progress');
				this.fetchManuallyUploadedInvoices();
			}).catch((error) => {
				this.props.info.inProgress(false, false, 0);
				toast.error('Couldn\'t start scanning');
			});
	}

	openDeleteDialog(event) {
		this.setState({ openDelete: true, documentManualUploadRecordId: event.currentTarget.value });
	}

	closeDialog(event) {
		this.setState({ openDelete: false, openInvoiceViewer: false });
	}

	deleteYes_clickHandler(event) {
		this.props.info.inProgress(true, false, 0);

		httpDelete("/api/nonmappedinvoices/manuallyuploadedinvoices/" + this.state.documentManualUploadRecordId)
			.then(res => {
				this.props.info.inProgress(false, false, 0);

				toast.success('Deleted Successfully');
				this.fetchManuallyUploadedInvoices();
			}).catch((error) => {
				this.props.info.inProgress(false, false, 0);
				toast.error(<div>Delete Failed<br />{error.response.data}</div>);
			});

		this.closeDialog(event);
	}

	displayUploadStatus(uploadStatus) {
		switch (uploadStatus) {
			case 1:
				return <div>Not Processed</div>;
			case 2:
				return <div>Scan in Progress</div>;
			case 3:
				return <div>Scanned, Invoice could not be mapped. Open in Scanned Successfully tab</div>;
			case 4:
				return <div>Scanned and partially mapped. Open in Scanned Successfully tab</div>;
			case 5:
				return <div>Scanned and mapped successfully. Open in <a href={this.props.info.basePath + "/manageinvoicedocuments"}>Manage Invoice Documents</a> screen</div >;
			case 6:
				return <div>Scan failed. Open in Scanner Failed tab</div>;
			case 7:
				return <div>Mapping and/or Vendor Assignment requested</div>;
			case 8:
				return <div>Routed for manual processing</div>;
			default:
				return <div>Unknown</div>;
		}
	}

	render() {
		const info = {
			modelDefId: this.props.info.modelDefId,
			invoiceDocumentFileLocation: this.state.invoiceDocumentFileLocation,
			inProgress: this.props.info.inProgress,
			onClose: this.closeDialog
		}

		return (
			<div>
				<div align="right" style={{ marginBottom: 10 }}>
					<input
						type="file"
						multiple
						accept="application/pdf,image/jpg,image/jpeg,image/png"
						id="contained-button-file"
						style={{ display: "none" }}
						onChange={this.fileUpload_changeHandler} />
					<label htmlFor="contained-button-file">
						<Button variant="contained" color="default" component="span" size="large" startIcon={<CloudUploadIcon />}>Upload</Button>
					</label>
				</div>
				<Button
					variant="contained"
					color="default"
					onClick={this.refresh_clickHandler}
					size="medium"
					style={{ float: "right", zIndex: 1, margin: 10 }}
					startIcon={<RefreshIcon />}>Refresh</Button>
				<MaterialTable
					title=""
					columns={[
						{
							title: 'Uploaded File Name',
							field: 'fileName',
							width: 400
						},
						{
							title: 'Timestamp',
							field: 'documentManualUploadFileUpdateDate',
							type: 'datetime',
							width: 200
						},
						{
							title: 'View',
							width: 20,
							render: rowData => (
								<Link data-rowid={rowData.documentManualUploadRecordId} href="#" onClick={this.viewLink_clickHandler}>View</Link>
							)
						},
						{
							title: 'Action',
							headerStyle: {
								textAlign: 'center',
							},
							cellStyle: {
								textAlign: 'center'
							},
							width: 180,
							render: rowData =>
								<div style={{ display: rowData.uploadStatus === 1 ? "" : "none" }}>
									<Button
										color="primary"
										variant="text"
										size="medium"
										value={rowData.documentManualUploadRecordId}
										style={{ textTransform: 'none', textAlign: 'center' }}
										onClick={this.scan_clickHandler}>Scan</Button>|
									<Button
										color="secondary"
										variant="text"
										size="medium"
										value={rowData.documentManualUploadRecordId}
										style={{ textTransform: 'none', textAlign: 'center' }}
										onClick={this.openDeleteDialog}>Remove</Button>
								</div>
						},
						{
							title: 'Status',
							field: 'uploadStatus',
							width: 500,
							render: rowData => this.displayUploadStatus(rowData.uploadStatus)
						}
					]}
					options={{
						pageSize: 10,
						pageSizeOptions: [10, 20, 30, 40, 50],
						maxBodyHeight: 550
					}}
					data={this.state.data}
				/>
				<Dialog
					open={this.state.openDelete}
					aria-labelledby="draggable-dialog-title"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Delete
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete this file?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.deleteYes_clickHandler} color="primary">
							Yes
						</Button>
						<Button autoFocus onClick={this.closeDialog} color="primary">
							No
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={this.state.openInvoiceViewer}
					aria-labelledby="draggable-dialog-title"
					TransitionComponent={Transition}
					fullWidth={true}
					maxWidth="lg"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Invoice Document
					</DialogTitle>
					<DialogContent>
						<InvoiceDocumentViewer info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.closeDialog} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}
