import React, { Component } from 'react';

export default class Error extends Component {
    constructor() {
        super();
    }
    
    render() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const message = urlParams.get('message');
		return (
            <div>{message}</div>
		);
	}
}