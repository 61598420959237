import React, { Component } from 'react';
import Layout from './components/Layout';
import { connect } from 'react-redux';
import Login from './components/Authentication/login';
import LoginError from './components/Authentication/LoginError';
import Error from './components/Error';
import { Switch, Route } from 'react-router-dom';
import { getProfileFetch } from './store/action/profileFetchAction';

class App extends Component {
	constructor(props) {

		super(props);

		this.state = {
			token: localStorage.token,
			tokenExpiry: localStorage.tokenExpiry
		}

		this.tokenHandler = this.tokenHandler.bind(this);
		this.userLoginFetch = this.userLoginFetch.bind(this);
	}

	componentDidMount = () => {
		this.props.getProfileFetch();
	}

	tokenHandler(jwt) {
		this.setState({ token: jwt });
	}

	userLoginFetch(userInfo) {
	}

	render() {
		return (
			<div>
				{
					(window.location.pathname.substring() === "/error") ? <Error /> :
						(window.location.pathname.substring() === "/loginerror") ? <LoginError /> :
							this.state.token && !(this.state.tokenExpiry != null && Date.now() > Date.parse(this.state.tokenExpiry)) ? <Layout tokenHandler={this.tokenHandler} userLoginFetch={this.userLoginFetch} /> : <Login tokenHandler={this.tokenHandler} userLoginFetch={this.userLoginFetch} />
				}
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	getProfileFetch: () => dispatch(getProfileFetch())
})

export default connect(null, mapDispatchToProps)(App);