import React, { Component } from 'react';
//import './Header.css';
//import './bootstrap.min.css';
export class ProfileMenu extends Component {
    clickHandler() {

    }
	render() {
        return (<div className="top-menu" style={{ display: "none" }}>
            <div className="profile profile_title">

                <div className="btn-group">
                    <h3 className="btn">

                    </h3>
                    <h3 className="btn dropdown-toggle" data-toggle="dropdown">

                        <span className="arrow heading-padding">
                            <img style={{ border: "0px" }} src="../../MediaPlanning/images/arrow.png" /></span>
                    </h3>
                    <ul className="dropdown-menu">

                        <li className="dropdown-submenu">

                        </li>
                        <li className="dropdown-submenu">

                        </li>
                        <li><a className="masterAnchor" href="#" onClick={this.clickHandler}>
                            Switch Employee</a></li>
                        <li><a className="masterAnchor" href="#" onClick={this.clickHandler}>
                            Help</a></li>
                        <li><a className="masterAnchor" href="#" onClick={this.clickHandler}>
                            About</a></li>
                        <li><a className="masterAnchor" href="#" onClick={this.clickHandler}>
                            Log Out</a></li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 nexelus_link">

                <a className="masterAnchor" href="">
                </a>
            </div>
        </div>);
	}

}