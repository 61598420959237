import React, { Component } from 'react';
import { connect } from 'react-redux';
class DashboardMenu extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
	}

	clickHandler() {

	}

	initialCap(data) {
		return data[0].toUpperCase() + data.slice(1);
	}

	getName() {
		try {
			var userInfo = JSON.parse(localStorage.userInfo);
			return this.initialCap(userInfo.lastName) + ', ' + this.initialCap(userInfo.firstName);
		} catch { }
	}

	getCompany() {
		try {
			var userInfo = JSON.parse(localStorage.userInfo);
			return this.initialCap(userInfo.coName);
		} catch { }
	}

	getRole() {
		try {
			var userInfo = JSON.parse(localStorage.userInfo);
			return this.initialCap(userInfo.selectedUserRoles.userRoleName);
		} catch { }
	}

	render() {
		return (
			<li className="dropdown dropdown-user dropdown-dark">
				<div className="dropdown-toggle inlineBlock" /*data-toggle="dropdown"*/ data-hover="dropdown" data-close-others="true">
					<label className="userProfilePicture">
						<img id="profile-picture" alt="" className="img-circle" src="/images/avatar.png" />
					</label>
					<label className="inlineBlock user-info">

						<label>
							<span className="lblUserName inlineBlock">{this.getName()}</span>
						</label>

						<label className="company-info">
							<i className="fa fa-user fa-6" aria-hidden="true"></i>
							<span className="username username-hide-mobile">
								{this.getRole()}
							</span> <i className="material-icons"></i>
							<span className="username username-hide-mobile">{this.getCompany()}</span>
						</label>
					</label>

				</div>
				<ul className="dropdown-menu dropdown-menu-default">
					<li id="dashboardMenuLink" className="dropdown-submenu">
						<a id="ctl00_dashbordAnchor" className="masterAnchor non-auth">
							My Dashboards
                                 </a>
						<ul className="dropdown-menu">
							<li>
								<a className="masterAnchor non-auth" onClick={this.clickHandler}>
									Main Approval (My Nexelus)
                                        </a>
							</li>
							<li>
								<a className="masterAnchor non-auth" onClick={this.clickHandler}>
									Time and Expense
                                        </a>
							</li>
							<li>
								<a className="masterAnchor non-auth" onClick={this.clickHandler}>
									Status and Campaign
                                        </a>
							</li>
							<li>
								<a className="masterAnchor non-auth" onClick={this.clickHandler}>
									Submitted Hours
                                        </a>
							</li>
							<li>
								<a className="masterAnchor non-auth" onClick={this.clickHandler}>
									Notifications
                                        </a>
							</li>
							<li>
								<a className="masterAnchor non-auth" onClick={this.clickHandler}>
									Workflow
                                        </a>
							</li>
							<li>
								<a className="masterAnchor non-auth" onClick={this.clickHandler}>
									Daily Assignments
                                        </a>
							</li>
							<li>
								<a className="masterAnchor non-auth" onClick={this.clickHandler}>
									PO Approvals
                                        </a>
							</li>
							<li>
								<a className="masterAnchor non-auth" onClick={this.clickHandler}>
									Pending Delivery Override
                                        </a>
							</li>
							<li>
								<a className="masterAnchor non-auth" onClick={this.clickHandler}>
									Media Plan Insight
                                        </a>
							</li>
						</ul>
					</li>

					<li className="dropdown-submenu">
						<a className="masterAnchor non-auth" href="#">
							Company
                                                    </a>
						<ul className="dropdown-menu">

							<li className="disabled">
								<a className="masterAnchor non-auth" id="company-2" onClick={this.clickHandler} href="#">
									TEST COMPANY-QA
                                    <i className="material-icons" style={{ fontSize: "17px !important" }}></i>
								</a>
							</li>

						</ul>
					</li>
					<li className="dropdown-submenu">
						<a className="masterAnchor non-auth" href="#">
							Logged As
                                </a>
						<ul className="dropdown-menu">
							<li>
								<a className="masterAnchor grp-list non-auth" id="grp-Finance" onClick={this.clickHandler} href="#">
									Finance
                                        </a>
							</li>
							<li className="disabled">
								<a className="masterAnchor grp-list non-auth" id="grp-Super User" onClick={this.clickHandler} href="#" selected="selected">
									Super User
                                    <i className="material-icons" style={{ fontSize: "17px !important" }}></i>
								</a>
							</li>

						</ul>
					</li>

					<li className="not-allowed-for-lock">
						<a href="#" onClick={this.clickHandler} className="non-auth">
							Switch Employee </a>
					</li>
					<li className="not-allowed-for-lock">
						<a href="#" onClick={this.clickHandler} className="non-auth">
							Help </a>
					</li>
					<li className="not-allowed-for-lock">
						<a href="#" onClick={this.clickHandler} className="non-auth">
							About </a>
					</li>
					<li>
						<a href="#" onClick={this.clickHandler} className="non-auth">
							Log Out </a>
					</li>
				</ul>

				<label className="switchedUser inlineBlock">
					<span><a className="masterAnchor DefaultName non-auth" id="ctl00_lblDefaultName"></a></span>
				</label>


			</li >
		);
	}
}

function mapStateToProps(state) {

	return ({

		favorites: state.favoriteReducer.favorites

	})
}

function mapDispatchToProps(dispatch) {
	return (
		{
			changeStateToReducer: (updatedValue) => {
				//dispatch(ChangeState(updatedValue));
			}
		}
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMenu);