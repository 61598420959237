import actionType from '../constants/constants'
const INITIAL_STATE = {
	favorites: [],
	link: '/',
}

export default (states = INITIAL_STATE, action) => {

	switch (action.type) {
		case actionType.UPDATEFAVORITES:
			return ({
				...states,
				favorites: action.payload
			});
		case actionType.CHANGELINK:
			return ({
				...states,
				link: action.payload
			});
		default:
			return states;
	}
}