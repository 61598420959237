import * as React from 'react';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import { formatNumber } from '../Common/Utilities';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';

export default class APPayments extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: ""
		};
	}

	componentDidMount() {
		this.fetchAPPayments();
	}

	fetchAPPayments() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/appayments')
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				that.setState({ data: data.records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		return (
			<div>
				<h1>AP Payments</h1>
				<div className="form-group">
					<MaterialTable
						title={"Last Updated: " + this.state.lastUpdated}
						columns={[
							{
								title: 'Vendor',
								field: 'vendorCode'
							},
							{
								title: 'Check Number',
								field: 'checkNumber',
								width: 300
							},
							{
								title: 'Invoice Number',
								field: 'invoiceNumber',
								width: 300
							},
							{
								title: 'Document Date',
								field: 'documentDate',
								type: 'date'
							},
							{
								title: 'Payment Amount',
								field: 'paymentAmount',
								type: 'currency',
								headerStyle: {
									textAlign: 'right',
								},
								cellStyle: {
									paddingRight: 45,
									fontSize: 12
								},
								render: rowData => formatNumber(rowData.paymentAmount) + " " + rowData.currencyCode
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670
						}}
						data={this.state.data}
					/>
				</div>
			</div>
		);
	}
}
