import * as React from 'react';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import { checkForUnAuthorizedException, stringIsNullOrEmpty, formatNumber } from '../Common/Utilities';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
export default class PaymentDashboard extends React.Component {
	constructor() {
		super();

		const userInfo = JSON.parse(localStorage.userInfo);
		this.isAdmin = userInfo.selectedUserRoles.isAdmin;

		this.state = {
			paymentDashboard: []
		};
	}

	componentDidMount() {
		this.fetchPaymentDashboard();
	}

	fetchPaymentDashboard() {
		const that = this;
		var modelDefId = this.props.modelDefId;

		this.props.inProgress(true);

		httpGet("/api/dashboard/appayments/" + modelDefId)
			.then((response) => {
				var data = response.data;

				var paymentDashboard = data;

				this.setRowId(paymentDashboard);

				this.setState({ paymentDashboard: paymentDashboard });

				this.props.inProgress(false);
			}).catch((error) => {
				checkForUnAuthorizedException(that, error);
				this.props.inProgress(false);

				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	setRowId(data) {
		var rowId = 1;
		data.map(row => row.rowId = rowId++);
	}
	displayVendorName(rowData) {
		return stringIsNullOrEmpty(rowData.vendorName) ? rowData.vendorCode : rowData.vendorName;
	}
	render() {
		const fontSize = 12;
		const cellStyle = { fontSize: fontSize, padding: 8 };
		const fontStyle = { fontSize: fontSize, padding: 8 };

		const tableOptions = {
			pageSize: 10,
			pageSizeOptions: [10, 20, 30, 40, 50],
			minBodyHeight: 320,
			maxBodyHeight: 670,
			headerStyle: fontStyle,
			searchFieldStyle: fontStyle
		};

		return (
			<div>
				<h1>{this.props.modelDefId === 1 ? 'Media' : 'Production'} Payment Dashboard</h1>
				<div className="form-group">
					<MaterialTable
						title=""
						columns={[
							{
								title: 'Vendor Name',
								field: 'vendorName',
								cellStyle: cellStyle,
								render: rowData => { return this.displayVendorName(rowData); }
							},
							{
								title: 'Vendor Invoice Number',
								field: 'invoiceNumber',
								cellStyle: cellStyle
							},
							{
								title: 'Vendor Invoice Date',
								field: 'invoiceDate',
								type: 'date',
								cellStyle: cellStyle
							},
							{
								title: 'Last Approved By',
								field: 'approvedBy',
								cellStyle: cellStyle
							},
							{
								title: 'Campaign Name',
								field: 'campaignName',
								cellStyle: cellStyle,
								hidden: (this.props.modelDefId === 2),
							},
							{
								title: 'Media Plan',
								field: 'mediaPlanName',
								cellStyle: cellStyle,
								hidden: (this.props.modelDefId === 2),
							},
							{
								title: 'Job',
								field: 'jobCode',
								cellStyle: cellStyle,
								hidden: (this.props.modelDefId !== 2),
							},
							{
								title: 'Activity',
								field: 'activityCode',
								cellStyle: cellStyle,
								hidden: (this.props.modelDefId !== 2),
							},
							{
								title: (this.props.modelDefId === 2 ? 'PO' : 'IO'),
								field: 'ioNumber',
								cellStyle: cellStyle
							},
							{
								title: 'Amount',
								field: 'amount',
								type: 'currency',
								headerStyle: {
									textAlign: 'right',
								},
								cellStyle: {
									paddingRight: 35,
									fontSize: 12
								},
								render: rowData => formatNumber(rowData.amount) + " " + rowData.currencyCode
							},
							{
								title: 'Posted Date',
								field: 'postedDate',
								type: 'date',
								cellStyle: cellStyle
							},
							{
								title: 'Payment Status',
								field: 'paymentStatus',
								cellStyle: cellStyle
							},
							{
								title: 'Payment Amount',
								field: 'paymentAmount',
								type: 'currency',
								headerStyle: {
									textAlign: 'right',
								},
								cellStyle: {
									paddingRight: 35,
									fontSize: 12
								},
								render: rowData => formatNumber(rowData.paymentAmount) + " " + rowData.currencyCode
							},
							{
								title: 'Payment Info',
								field: 'paymentInfo',
								cellStyle: cellStyle
							}
						]}
						options={tableOptions}
						data={this.state.paymentDashboard}
					/>
				</div>
			</div >
		);
	}
}
