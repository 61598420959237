import * as React from 'react';
import { InputError } from './InputError'
import jQuery from 'jquery';

export class InputText extends React.Component {
    constructor() {
        super();

        this.state = {
            readOnly: true,
            value: ""
        }

        this._click = this._click.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    _click() {

    }

    getInitialState() {
        //most of these variables have to do with handling errors
        return {
            isEmpty: true,
            value: "",
            valid: false,
            errorMessage: "Input is invalid",
            errorVisible: false
        };
    }

    validation(value, valid) {
        //The valid variable is optional, and true if not passed in:
        if (typeof valid === 'undefined') {
            valid = true;
        }

        var message = "";
        var errorVisible = false;

        //we know how to validate text fields based on information passed through props
        if (!valid) {
            //This happens when the user leaves the field, but it is not valid
            //(we do final validation in the parent component, then pass the result
            //here for display)
            message = this.props.errorMessage;
            valid = false;
            errorVisible = true;
        }
        else if (this.props.required && jQuery.isEmptyObject(value)) {
            //this happens when we have a required field with no text entered
            //in this case, we want the "emptyMessage" error message
            message = this.props.emptyMessage;
            valid = false;
            errorVisible = true;
        }
        else if (value.length < this.props.minCharacters) {
            //This happens when the text entered is not the required length,
            //in which case we show the regular error message
            message = this.props.errorMessage;
            valid = false;
            errorVisible = true;
        }

        //setting the state will update the display,
        //causing the error message to display if there is one.
        this.setState({
            value: value,
            isEmpty: jQuery.isEmptyObject(value),
            valid: valid,
            errorMessage: message,
            errorVisible: errorVisible
        });

    }

    handleChange(event) {
        //validate the field locally
        this.validation(event.target.value);

        //Call onChange method on the parent component for updating it's state
        //If saving this field for final form submission, it gets passed
        // up to the top component for sending to the server
        if (this.props.changeHandler) {
            this.props.changeHandler(event);
        }
    }

    handleBlur(event) {
        //Complete final validation from parent element when complete
        if (this.props.validate) {
            var valid = this.props.validate(event.target.value);
            //pass the result to the local validation element for displaying the error
            this.validation(event.target.value, valid);
        }
    }

    render() {
        return (
            <div className={this.props.uniqueName}>
                <input className="input_characters form-control noDirty clearable"
                    type="text"
                    id={this.props.textboxInfo.textboxId}
                    name={this.props.textboxInfo.textboxName}
                    placeholder={this.props.textboxInfo.placeholder}
                    readOnly={this.props.textboxInfo.readOnly}
                    value={this.props.textboxInfo.textboxValue == null ? "" : this.props.textboxInfo.textboxValue}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                />
                <InputError
                    visible={this.state.errorVisible}
                    errorMessage={this.state.errorMessage} />
            </div>
        );
    }
}
