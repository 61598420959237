import * as React from 'react';
//import { withRouter } from "react-router-dom";
//import { loginUser } from '../../store/action/loginAction';
//import { connect } from 'react-redux';
//import { toast } from 'react-toastify';
 import './login.css';
//import sha256 from 'js-sha256';

export default class LoginError extends React.Component {
	constructor(props) {
		super(props);

		const queryString = window.location.search;
		const searchParams = new URLSearchParams(queryString);
		const message = searchParams.get('message');

		this.state = {
			message: message
		};
	}

	render() {
		return (
			<form id="LoginForm">
				<div className="container-fluid">
					<div className="loginScreen zero-padding">
						<div className="col-md-4 loginDetail ">
							<img src="images/nexelus-logo-200.png" alt="Nexelus Logo" />
							<h3>Welcome</h3>
							<hr />
							<p><span id="YearPart"></span></p>
							<p>All rights reserved. This product is protected by US and International copyright laws.</p>
						</div>
						<div className="col-md-8 loginForm">
							<div className="errorMessage col-md-12 non-okta" id="idTdErrorMessage"></div>
							<div className="form-group okta" style={{ height: "250px", position: "relative", display: "" }}>
								<div id="poweredByOKTNote" className="col-md-12 zero-padding login-mode-notice" style={{ marginTop: "20%", marginBottom: "20px", fontSize: 16 }}>{this.state.message}</div>
								<div className="okta-footer" style={{ position: "absolute", bottom: 0, left: 0 }}>
									<div id="dvOKTAunknownNotification1" className="col-md-12 zero-padding login-mode-notice">Your account does not exist. Please contact your Administrator or click <a href="/login">here</a> to redirect to login.</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3 loginScreenImg">
						<div className="clientLogo">
							<img src="images/client_data/Nexelus-logo-200.png" alt="Logo" />
						</div>
					</div>
				</div>
			</form >
		);
	}
}
