import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import Zoom from '@material-ui/core/Zoom';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import PaymentTermsMaps from './PaymentTermsMaps';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});


export default class PaymentTerms extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: "",
			openPaymentTermsMapsDialog: false
		};

		this.editPaymentTermsMaps_clickHandler = this.editPaymentTermsMaps_clickHandler.bind(this);
		this.paymentTermsMapsDialog_closeHandler = this.paymentTermsMapsDialog_closeHandler.bind(this);
	}

	componentDidMount() {
		this.fetchPaymentTerms();
	}

	editPaymentTermsMaps_clickHandler(event) {
		event.preventDefault();

		this.setState({ openPaymentTermsMapsDialog: true, selectedTermsCode: event.target.dataset.termscode });
	}

	paymentTermsMapsDialog_closeHandler(event) {
		this.setState({ openPaymentTermsMapsDialog: false });
	}

	fetchPaymentTerms() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/paymentterms')
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				that.setState({ data: data.records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		const info = {
			termsCode: this.state.selectedTermsCode,
			inProgress: this.props.inProgress
		}

		return (
			<div>
				<h1>Payment Terms</h1>
				<div className="form-group">
					<MaterialTable
						title={"Last Updated: " + this.state.lastUpdated}
						columns={[
							{
								title: 'Code',
								field: 'termsCode'
							},
							{
								title: 'Description',
								field: 'termsDesc'
							},
							{
								title: 'Days Due',
								field: 'daysDue',
								type: "numeric"
							},
							{
								title: 'Mappings',
								headerStyle: {
									textAlign: 'center',
								},
								cellStyle: {
									textAlign: 'center'
								},
								render: rowData => <Link data-termscode={rowData.termsCode} href="#" onClick={this.editPaymentTermsMaps_clickHandler}>Edit</Link>
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670
						}}
						data={this.state.data}
					/>
				</div>
				<Dialog open={this.state.openPaymentTermsMapsDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<PaymentTermsMaps info={info} onClose={this.paymentTermsMapsDialog_closeHandler} />
					</DialogContent>
				</Dialog>
			</div>
		);
	}
}
