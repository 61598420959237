import * as React from 'react';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import { formatNumber } from '../Common/Utilities';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';

export default class InsertionOrdersReport extends React.Component {
	constructor() {
		super();

		this.state = {
			data: []
		};
	}

	componentDidMount() {
		this.fetchInsertionOrders();
	}

	fetchInsertionOrders() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/reports/insertionorders/')
			.then((response) => {
				var data = response.data;

				that.setState({ data: data });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		const cellStyle = { paddingLeft: 8, paddingRight: 45 };
		const filterCellStyle = { textAlign: 'right', paddingRight: 45 };
		const headerStyle = { textAlign: 'right' };

		return (
			<div>
				<h1>Insertion Orders</h1>
				<div className="form-group">
					<MaterialTable
						title=""
						columns={[
							{
								title: 'IO/PO Number',
								field: 'poCode',
								width: 50
							},
							{
								title: 'IO/PO Amount',
								field: 'poAmount',
								type: 'currency',
								cellStyle: cellStyle,
								filterCellStyle: filterCellStyle,
								headerStyle: headerStyle,
								render: rowData => formatNumber(rowData.poAmount) + " " + rowData.currencyCode
							},
							{
								title: 'Consumed Amount',
								field: 'consumedAmount',
								type: 'currency',
								cellStyle: cellStyle,
								filterCellStyle: filterCellStyle,
								headerStyle: headerStyle,
								render: rowData => formatNumber(rowData.consumedAmount) + " " + rowData.currencyCode
							},
							{
								title: 'Client Name',
								field: 'clientName',
								width: 50
							},
							{
								title: 'Job Code',
								field: 'jobCode',
								width: 50
							},
							{
								title: 'Campaign Name',
								field: 'campaignName',
								width: 50
							},
							{
								title: 'Media Plan',
								field: 'mediaPlanName',
								width: 50
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670,
							filtering: true,
							columnsButton: true,
							exportButton: true,
							exportAllData: true,
							exportFileName: "InsertionOrders"
						}}
						data={this.state.data}
					/>
				</div>
			</div>
		);
	}
}
