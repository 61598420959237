import * as React from 'react';
import MaterialTable from 'material-table';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import { formatNumber, stringIsNullOrEmpty } from '../Common/Utilities';

export default class VendorInvoicesWithPaymentStatusReport extends React.Component {
	constructor() {
		super();

		var userInfo = JSON.parse(localStorage["userInfo"]);
		var modelDefId = this.getDefaultModelDefId(userInfo);

		this.state = {
			data: [],
			modelDefId: modelDefId,
			userInfo: userInfo
		};

		this.modelDefId_changeHandler = this.modelDefId_changeHandler.bind(this);
		this.getDefaultModelDefId = this.getDefaultModelDefId.bind(this);
		this.getInvoiceTypeMenuItem = this.getInvoiceTypeMenuItem.bind(this);
	}

	getDefaultModelDefId(userInfo) {
		if (!stringIsNullOrEmpty(userInfo.invoiceType)) {
			if (userInfo.invoiceType.includes('1')) {
				return 1;
			}
			else if (userInfo.invoiceType.includes('2')) {
				return 2;
			}
		}
	}

	componentDidMount() {
		this.fetchVendorInvoicesWithPaymentStatus(this.state.modelDefId);
	}

	modelDefId_changeHandler(event) {
		var modelDefId = parseInt(event.target.value, 10);

		this.setState({ data: [], modelDefId: modelDefId });

		this.fetchVendorInvoicesWithPaymentStatus(modelDefId);
	}

	fetchVendorInvoicesWithPaymentStatus(modelDefId) {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/reports/vendorinvoiceswithpaymentstatus/' + modelDefId)
			.then((response) => {
				var data = response.data;

				that.setState({ data: data });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	getInvoiceTypeMenuItem(modelDefId, displayValue) {
		if (this.state.userInfo.invoiceType.includes('' + modelDefId)) {
			return <MenuItem value={modelDefId}>{displayValue}</MenuItem>
		}
	}
	displayVendorName(rowData) {
		return stringIsNullOrEmpty(rowData.vendorName) ? rowData.vendorCode : rowData.vendorName;
	}
	render() {
		const lookup =
		{
			1: 'Unpaid',
			2: 'Partially Paid',
			3: 'Fully Paid'
		};

		const cellStyle = { paddingRight: 45 };
		const filterCellStyle = { textAlign: 'right', paddingRight: 45 };
		const headerStyle = { textAlign: 'right' };

		return (
			<div>
				<h1>Vendor Invoices With Payment Status</h1>
				<div className="form-group">
					<Select
						style={{ float: "right", zIndex: 1, marginTop: 16, marginRight: 20 }}
						value={this.state.modelDefId}
						onChange={this.modelDefId_changeHandler}
					>
						{this.getInvoiceTypeMenuItem(1, 'Media')}
						{this.getInvoiceTypeMenuItem(2, 'Production')}
					</Select>
					<MaterialTable
						title=""
						columns={[
							{
								title: 'Vendor Name',
								field: 'vendorName',
								render: rowData => { return this.displayVendorName(rowData); }
							},
							{
								title: 'Vendor Invoice Number',
								field: 'invoiceNumber'
							},
							{
								title: 'Vendor Invoice Date',
								field: 'invoiceDate',
								type: 'date'
							},
							{
								title: 'Last Approved By',
								field: 'approvedBy'
							},
							{
								title: 'Campaign Name',
								field: 'campaignName',
								hidden: (this.state.modelDefId === 2),
							},
							{
								title: 'Media Plan',
								field: 'mediaPlanName',
								hidden: (this.state.modelDefId === 2),
							},
							{
								title: 'Job',
								field: 'jobCode',
								hidden: (this.state.modelDefId !== 2),
							},
							{
								title: 'Activity',
								field: 'activityCode',
								hidden: (this.state.modelDefId !== 2),
							},
							{
								title: (this.state.modelDefId === 2 ? 'PO' : 'IO'),
								field: 'ioNumber'
							},
							{
								title: 'Amount',
								field: 'amount',
								type: 'currency',
								headerStyle: headerStyle,
								cellStyle: cellStyle,
								filterCellStyle: filterCellStyle,
								render: rowData => formatNumber(rowData.amount) + " " + rowData.currencyCode
							},
							{
								title: 'Posted Date',
								field: 'postedDate',
								type: 'date'
							},
							{
								title: 'Payment Status',
								field: 'paymentStatusId',
								filterCellStyle: { width: 40, maxWidth: 40 },
								lookup: lookup
							},
							{
								title: 'Payment Amount',
								field: 'paymentAmount',
								type: 'currency',
								headerStyle: headerStyle,
								cellStyle: cellStyle,
								filterCellStyle: filterCellStyle,
								render: rowData => formatNumber(rowData.paymentAmount) + " " + rowData.currencyCode
							},
							{
								title: 'Payment Info',
								field: 'paymentInfo'
							},
							{
								title: 'Transaction Id',
								field: 'transactionId'
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670,
							filtering: true,
							columnsButton: true,
							exportButton: true,
							exportAllData: true,
							exportFileName: "VendorInvoicesWithPaymentStatus"
						}}
						data={this.state.data}
					/>
				</div>
			</div>
		);
	}
}
