import * as React from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Zoom from '@material-ui/core/Zoom';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class VendorLookup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			rowData: null,
			inProgress: false,
			currencyCode: ""
		};
		this.checkbox_clickHandler = this.checkbox_clickHandler.bind(this);
		this.close_clickHandler = this.close_clickHandler.bind(this);
	}

	componentDidUpdate() {
		var that = this;
		var loaded = false;
		if (this.props.info.openDialog && !this.state.inProgress) {
			if (that.props.info.invoiceModel.currencyCode != this.state.currencyCode) {
				loaded = true;
			}			
			else if (this.state.data.length == 0) {
				loaded = true;
			}
		}
		if (loaded) {
			this.state.inProgress = true;
			this.fetchVendors();
			that.props.info.inProgress(true);
		}
	}

	fetchVendors() {
		var that = this;
		httpGet('/api/lookup/vendor')
			.then((response) => {
				var data = response.data;
				var currencyCode = "";
				if (that.props.info.invoiceModel.currencyCode) {
					currencyCode = that.props.info.invoiceModel.currencyCode;
					var vendors = data.filter(z => z.currencyCode == currencyCode);
					if (vendors.length > 0) {
						data = vendors;
					}
				}

				var rowId = 1;
				data.map(row => row.rowId = rowId++);

				var rowData = data.find(i => i.vendorCode === that.props.info.invoiceModel.vendorCode && i.siteId === that.props.info.invoiceModel.siteId);

				that.setState({ data: data, rowData: rowData, inProgress: false, currencyCode: currencyCode });
				this.props.info.inProgress(false);
			})
			.catch((error) => {
				that.props.info.setInProgess(false);
				that.setState({ inProgress: false });
				toast.error('An error occurred while fetching the records');
			});
	}
	checkbox_clickHandler(event) {
		var rowData = this.getRowData(parseInt(event.target.value, 10));
		this.setState({ rowData: rowData });
	}

	close_clickHandler(event) {
		if (this.props.onClose) {
			this.props.onClose(event, this.state.rowData);
		}
	}

	getRowData(value) {
		var rowData = this.state.data;
		return rowData[rowData.findIndex(d => d.rowId === value)];
	}

	render() {
		return (
			<Dialog open={this.props.info.openDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
				<DialogTitle>
					Select Vendor
				</DialogTitle>
				<DialogContent>
					<MaterialTable
						title=""
						columns={[
							{
								width: 5,
								render: rowData =>
									<Checkbox
										color="default"
										name="chk"
										checked={this.state.rowData != null && this.state.rowData !== undefined && rowData.rowId === this.state.rowData.rowId}
										value={rowData.rowId}
										onClick={this.checkbox_clickHandler}
									/>
							},
							{
								title: 'Site ID',
								field: 'siteId'
							},
							{
								title: 'Vendor Code',
								field: 'vendorCode'
							},
							{
								title: 'Vendor Name',
								field: 'vendorName',
								width: 300
							},
							{
								title: 'Vendor Address',
								field: 'vendorAddress',
								width: 500
							},
							{
								title: 'Currency',
								field: 'currencyCode',
								width: 100
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 550
						}}
						data={this.state.data}
					/>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={this.close_clickHandler} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}