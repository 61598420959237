import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import Zoom from '@material-ui/core/Zoom';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import Level2Resources from './Level2Resources';
import Level3s from './Level3s';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});


export default class Level2s extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: "",
			openLevel2ResourcesDialog: false,
			openLevel3Dialog: false
		};

		this.viewLevel2Resources_clickHandler = this.viewLevel2Resources_clickHandler.bind(this);
		this.level2ResourcesDialog_closeHandler = this.level2ResourcesDialog_closeHandler.bind(this);
		this.viewLevel3_clickHandler = this.viewLevel3_clickHandler.bind(this);
		this.level3Dialog_closeHandler = this.level3Dialog_closeHandler.bind(this);
	}

	componentDidMount() {
		this.fetchLevel2s();
	}

	viewLevel2Resources_clickHandler(event) {
		event.preventDefault();

		this.setState({ openLevel2ResourcesDialog: true, selectedLevel2Key: event.target.dataset.level2key });
	}

	level2ResourcesDialog_closeHandler(event) {
		this.setState({ openLevel2ResourcesDialog: false });
	}

	viewLevel3_clickHandler(event) {
		event.preventDefault();

		this.setState({ openLevel3Dialog: true, selectedLevel2Key: event.target.dataset.level2key });
	}

	level3Dialog_closeHandler(event) {
		this.setState({ openLevel3Dialog: false });
	}

	fetchLevel2s() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/level2s/')
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				that.setState({ data: data.records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		const info = {
			level2Key: this.state.selectedLevel2Key,
			inProgress: this.props.inProgress
		}

		return (
			<div>
				<h1>Projects</h1>
				<div className="form-group">
					<MaterialTable
						title={"Last Updated: " + this.state.lastUpdated}
						columns={[
							{
								title: 'Project',
								field: 'level2Key',
								width: 80
							},
							{
								title: 'Description',
								field: 'level2Description'
							},
							{
								title: 'Status',
								field: 'level2StatusName',
							},
							{
								title: 'Open Date',
								field: 'openDate',
								type: 'date'
							},
							{
								title: 'Customer Code',
								field: 'customerCode',
								width: 50
							},
							{
								title: 'Customer Name',
								field: 'customerName',
								width: 50
							},
							{
								title: 'Customer PO Number',
								field: 'customerPONumber',
								width: 50
							},
							{
								title: 'PO Required',
								field: 'poRequiredFlag'
							},
							{
								title: 'Tolerance PO',
								field: 'tolerancePOFlag'
							},
							{
								title: 'Tolerance PO Amount',
								field: 'tolerancePOAmount',
								width: 50
							},
							{
								title: 'Activities',
								headerStyle: {
									textAlign: 'center',
								},
								cellStyle: {
									textAlign: 'center'
								},
								render: rowData => <Link data-level2key={rowData.level2Key} href="#" onClick={this.viewLevel3_clickHandler}>View</Link>
							},
							{
								title: 'Resources',
								headerStyle: {
									textAlign: 'center',
								},
								cellStyle: {
									textAlign: 'center'
								},
								render: rowData => <Link data-level2key={rowData.level2Key} href="#" onClick={this.viewLevel2Resources_clickHandler}>View</Link>
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670
						}}
						data={this.state.data}
					/>
				</div>
				<Dialog open={this.state.openLevel2ResourcesDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<Level2Resources info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.level2ResourcesDialog_closeHandler} color="primary">
							Close
					</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.openLevel3Dialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<Level3s info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.level3Dialog_closeHandler} color="primary">
							Close
					</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
