import loginReducer from './LoginReducer';
import favoriteReducer from './favoritesReducer';
import { combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import history from '../../history';

export default combineReducers({
	rootReducer: loginReducer,
	favoriteReducer: favoriteReducer,
	router: connectRouter(history)
})