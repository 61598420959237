import * as React from 'react';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import { formatNumber } from '../Common/Utilities';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';

export default class PurchaseOrdersReport extends React.Component {
	constructor() {
		super();

		this.state = {
			data: []
		};
	}

	componentDidMount() {
		this.fetchPurchaseOrders();
	}

	fetchPurchaseOrders() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/reports/purchaseorders')
			.then((response) => {
				var data = response.data;

				that.setState({ data: data });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		const cellStyle = { paddingLeft: 8, paddingRight: 45 };
		const filterCellStyle = { textAlign: 'right', paddingRight: 45 };
		const headerStyle = { textAlign: 'right' };

		return (
			<div>
				<h1>Purchase Orders</h1>
				<div className="form-group">
					<MaterialTable
						title=""
						columns={[
							{
								title: 'IO/PO Number',
								field: 'poCode',
								width: 50
							},
							{
								title: 'Project',
								field: 'level2Key'
							},
							{
								title: 'Project Name',
								field: 'level2Description'
							},
							{
								title: 'Activity',
								field: 'level3Key'
							},
							{
								title: 'Due Date',
								field: 'dueDate',
								type: 'date'
							},
							{
								title: 'Quantity',
								field: 'quantity',
								type: 'numeric'
							},
							{
								title: 'Net Cost',
								field: 'netCost',
								type: 'currency',
								cellStyle: cellStyle,
								filterCellStyle: filterCellStyle,
								headerStyle: headerStyle,
								render: rowData => formatNumber(rowData.netCost) + " " + rowData.currencyCode
							},
							{
								title: 'Match to Date Net',
								field: 'matchToDateNet',
								type: 'numeric'
							},
							{
								title: 'Closed',
								field: 'closeFlag',
								type: 'boolean'
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670,
							filtering: true,
							columnsButton: true,
							exportButton: true,
							exportAllData: true,
							exportFileName: "PurchaseOrders"
						}}
						data={this.state.data}
					/>
				</div>
			</div>
		);
	}
}
