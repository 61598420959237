import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/MoreHoriz';
import Popover from '@material-ui/core/Popover';

export class PositionCategoryCodes extends React.Component {
	constructor() {
		super();

		this.state = {
			positionCategoryList: []
		};

		this.popover_handleClickOpen = this.popover_handleClickOpen.bind(this);
		this.popover_handleClose = this.popover_handleClose.bind(this);
		this.positionCategory_changeHandler = this.positionCategory_changeHandler.bind(this);
	}

	componentDidMount() {
		var positionCategoryList = this.props.info.positionCategoryList;

		positionCategoryList.map((positionCategory, index) => (
			positionCategory.checked = this.props.info.apadUserRolePositionCategories.findIndex(urpc => urpc.positionCategoryCode === positionCategory.positionCategoryCode) > -1));

		this.setState({ positionCategoryList: positionCategoryList });
	}

	positionCategory_changeHandler(event) {
		var positionCategoryList = this.state.positionCategoryList;
		positionCategoryList.find(pc => pc.positionCategoryCode === parseInt(event.target.value)).checked = event.target.checked;

		this.setState({ positionCategoryList: positionCategoryList });
	}

	popover_handleClickOpen(event) {
		this.setState({ anchorEl: event.currentTarget });
	}

	popover_handleClose(event) {
		this.setState({ anchorEl: null });

		if (this.props.onClose != null) {
			this.props.onClose(this.state.positionCategoryList.filter(pc => pc.checked));
		}
	}

	render() {
		var that = this;

		return (
			<div>
				<IconButton value="color" aria-label="Field Source" className="textAlignmentIcon" onClick={this.popover_handleClickOpen}>
					<SettingsIcon />
				</IconButton>
				<Popover
					open={this.state.anchorEl != null}
					anchorEl={this.state.anchorEl}
					onClose={this.popover_handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}>
					{
						this.state.positionCategoryList.map((positionCategory, index) => (
							<MenuItem key={index}>
								<Checkbox color="default" checked={positionCategory.checked} value={positionCategory.positionCategoryCode} onChange={that.positionCategory_changeHandler} />
								<ListItemText primary={positionCategory.positionCategoryName} />
							</MenuItem>
						))
					}
				</Popover>
			</div>
		);
	}
}
