import * as React from 'react';
import MaterialTable from 'material-table';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import { formatNumber, stringIsNullOrEmpty } from '../Common/Utilities';

export default class VendorInvoicesReport extends React.Component {
	constructor() {
		super();

		var userInfo = JSON.parse(localStorage["userInfo"]);
		var modelDefId = this.getDefaultModelDefId(userInfo);
		
		this.state = {
			data: [],
			modelDefId: modelDefId,
			userInfo: userInfo
		};

		this.modelDefId_changeHandler = this.modelDefId_changeHandler.bind(this);
		this.getDefaultModelDefId = this.getDefaultModelDefId.bind(this);
		this.getInvoiceTypeMenuItem = this.getInvoiceTypeMenuItem.bind(this);
	}

	getDefaultModelDefId(userInfo) {
		if (!stringIsNullOrEmpty(userInfo.invoiceType)) {
			if (userInfo.invoiceType.includes('1')) {
				return 1;
			}
			else if (userInfo.invoiceType.includes('2')) {
				return 2;
			}
		}
	}

	componentDidMount() {
		this.fetchVendorInvoices(this.state.modelDefId);
	}

	modelDefId_changeHandler(event) {
		var modelDefId = parseInt(event.target.value, 10);

		this.setState({ data: [], modelDefId: modelDefId });

		this.fetchVendorInvoices(modelDefId);
	}

	fetchVendorInvoices(modelDefId) {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/reports/vendorinvoices/' + modelDefId)
			.then((response) => {
				var data = response.data;

				that.setState({ data: data });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	getInvoiceTypeMenuItem(modelDefId, displayValue) {
		if (this.state.userInfo.invoiceType.includes('' + modelDefId)) {
			return <MenuItem value={modelDefId}>{displayValue}</MenuItem>
		}
	}

	render() {
		const lookup =
		{
			10: 'Unrecognized',
			20: 'Routed for Manual Processing',
			30: 'Mapping Requested',
			35: 'Missing Vendor Assignment',
			40: 'Missing Info',
			50: 'Verified Successfully',
			60: 'Verified with Errors',
			70: 'First Level Approved',
			80: 'First Level Rejected',
			90: 'Second Level Approved',
			100: 'Second Level Rejected',
			110: 'Third Level Approved',
			120: 'Third Level Rejected',
			130: 'Fourth Level Approved',
			140: 'Fourth Level Rejected',
			150: 'Fifth Level Approved',
			160: 'Fifth Level Rejected',
			170: 'Posted',
			180: 'Post Failed'
		};

		const cellStyle = { paddingLeft: 8, paddingRight: 45 };
		const filterCellStyle = { textAlign: 'right', paddingRight: 45 };
		const headerStyle = { textAlign: 'right' };

		const columns = [
			{
				title: 'Vendor Code',
				field: 'vendorCode'
			},
			{
				title: 'Vendor Name',
				field: 'vendorName'
			},
			{
				title: 'Site Id',
				field: 'siteId'
			},
			{
				title: 'Invoice Number',
				field: 'invoiceNumber'
			}];

		if (this.state.modelDefId === 2)
		{
			columns.push(
				{
					title: 'PO Code',
					field: 'poCode',
					hidden: this.state.modelDefId !== 2
				});
		}

		columns.push(
			{
				title: 'Status',
				field: 'invoiceDocumentStatusId',
				filterCellStyle: { width: 40, maxWidth: 40 },
				lookup: lookup
			},
			{
				title: 'Assigned To',
				field: 'assignedTo',
				filterCellStyle: { width: 40, maxWidth: 40 },
				render: rowData => {
					if (rowData.assignedTo && rowData.assignedTo.split('|').length > 1)
						return <div>
							<Tooltip
								title={
									<span style={{ whiteSpace: 'pre-line' }}>{rowData.assignedTo.split('|').join("\n")}</span>
								}>
								<Link>{rowData.assignedTo.split('|')[0]} ...</Link>
							</Tooltip>
						</div>;
					return rowData.assignedTo;
				}
			},
			{
				title: 'Invoice Date',
				field: 'invoiceDate',
				type: 'date'
			},
			{
				title: 'Due Date',
				field: 'dueDate',
				type: 'date'
			},
			{
				title: 'Payment Term',
				field: 'paymentTermCode'
			});

		if (this.state.modelDefId !== 2) {
			columns.push(
				{
					title: 'Service Term From',
					field: 'serviceTermStartDate',
					type: 'date',
					hidden: this.state.modelDefId === 2
				},
				{
					title: 'Service Term To',
					field: 'serviceTermEndDate',
					type: 'date',
					hidden: this.state.modelDefId === 2
				});
		}

		if (this.state.modelDefId === 2)
		{
			columns.push({
				title: 'Customer Code',
				field: 'clientCode',
				hidden: this.state.modelDefId !== 2
			},
			{
				title: 'Customer Name',
				field: 'clientName',
				hidden: this.state.modelDefId !== 2
			});
		}

		columns.push(
			{
				title: 'Currency',
				field: 'currencyCode'
			});

		if (this.state.modelDefId !== 2) {
			columns.push({
				title: 'Client Code',
				field: 'clientCode',
				hidden: this.state.modelDefId === 2
			},
			{
				title: 'Client Name',
				field: 'clientName',
				hidden: this.state.modelDefId === 2
			},
			{
				title: 'IO/PO Number',
				field: 'ioNumber',
				hidden: this.state.modelDefId === 2
			},
			{
				title: 'IO/PO Description',
				field: 'ioDescription',
				hidden: this.state.modelDefId === 2
			});
		}

		if (this.state.modelDefId === 2)
		{
			columns.push(
				{
					title: 'Project',
					field: 'level2Key',
					hidden: this.state.modelDefId !== 2
				},
				{
					title: 'Activity',
					field: 'level3Key',
					hidden: this.state.modelDefId !== 2
				},
				{
					title: 'Expense Type',
					field: 'rTypeName',
					hidden: this.state.modelDefId !== 2
				},
				{
					title: 'Quantity',
					field: 'quantity',
					type: 'numeric',
					hidden: this.state.modelDefId !== 2
				});
		}

		columns.push(
			{
				title: this.state.modelDefId === 2 ? 'Amount' : 'Invoice Net Amount',
				field: 'ioInvoiceAmount',
				type: 'currency',
				cellStyle: cellStyle,
				filterCellStyle: filterCellStyle,
				headerStyle: headerStyle,
				render: rowData => formatNumber(rowData.ioInvoiceAmount)
			});

		if (this.state.modelDefId === 2)
		{
			columns.push(
				{
					title: 'Net Cost',
					field: 'netCost',
					type: 'currency',
					cellStyle: cellStyle,
					headerStyle: headerStyle,
					filterCellStyle: filterCellStyle,
					hidden: this.state.modelDefId !== 2,
					render: rowData => formatNumber(rowData.netCost)
				},
				{
					title: 'Consumed Amount',
					field: 'matchToDateNet',
					type: 'currency',
					cellStyle: cellStyle,
					headerStyle: headerStyle,
					filterCellStyle: filterCellStyle,
					hidden: this.state.modelDefId !== 2,
					render: rowData => formatNumber(rowData.matchToDateNet)
				},
				{
					title: 'Remaining Amount',
					field: 'remainingAmount',
					type: 'currency',
					cellStyle: cellStyle,
					headerStyle: headerStyle,
					filterCellStyle: filterCellStyle,
					hidden: this.state.modelDefId !== 2,
					render: rowData => formatNumber(rowData.remainingAmount)
				});
		}

		if (this.state.modelDefId !== 2) {
			columns.push(
				{
					title: 'IO/PO Amount',
					field: 'poAmount',
					type: 'currency',
					cellStyle: cellStyle,
					headerStyle: headerStyle,
					filterCellStyle: filterCellStyle,
					hidden: this.state.modelDefId === 2,
					render: rowData => formatNumber(rowData.poAmount)
				},
				{
					title: 'Consumed Amount',
					field: 'consumedAmount',
					type: 'currency',
					cellStyle: cellStyle,
					headerStyle: headerStyle,
					filterCellStyle: filterCellStyle,
					hidden: this.state.modelDefId === 2,
					render: rowData => formatNumber(rowData.consumedAmount)
				},
				{
					title: 'Delivery Amount',
					field: 'deliveryAmount',
					type: 'currency',
					cellStyle: cellStyle,
					headerStyle: headerStyle,
					filterCellStyle: filterCellStyle,
					hidden: this.state.modelDefId === 2,
					render: rowData => formatNumber(rowData.deliveryAmount)
				});
		}

		columns.push(
			{
				title: 'Transaction Id',
				field: 'transactionId'
			});

		return (
			<div>
				<h1>Vendor Invoices</h1>
				<div className="form-group">
					<Select
						style={{ float: "right", zIndex: 1, marginTop: 16, marginRight: 20 }}
						value={this.state.modelDefId}
						onChange={this.modelDefId_changeHandler}
					>
						{this.getInvoiceTypeMenuItem(1, 'Media')}
						{this.getInvoiceTypeMenuItem(2, 'Production')}
					</Select>
					<MaterialTable
						title=""
						columns={columns}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670,
							filtering: true,
							columnsButton: true,
							exportButton: true,
							exportAllData: true,
							exportFileName: "VendorInvoices"
						}}
						data={this.state.data}
					/>
				</div>
			</div>
		);
	}
}
