import * as React from 'react';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import { blankStringIfNullOrEmpty } from '../Common/Utilities';

export default class EmployeesReport extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			userRolesLookup: {}
		};
	}

	componentDidMount() {
		this.fetchEmployees().then((response) => {
			this.props.inProgress(true);

			httpGet("/api/reports/userroles/")
				.then((response) => {
					var userRoles = response.data;
					var userRolesLookup = {};

					userRoles.map(ur => {
						userRolesLookup[ur.userRoleId] = ur.userRoleName;
					});

					this.state.data.map(e => {
						var assignedRoles = [];

						if (Object.values(userRolesLookup).length > 0) {
							for (var i = 0; i < e.apadUserRolesResource.length; i++) {
								assignedRoles.push(userRolesLookup[e.apadUserRolesResource[i].userRoleId]);
							}
						}

						e.userRoles = assignedRoles.join(", ");
					});

					this.props.inProgress(false);

					this.setState({ userRolesLookup: userRolesLookup });
				}).catch((error) => {
					this.props.inProgress(false);

					toast.error('An error occurred while fetching the records');
					console.error('There was an error!', error);
				});
		}).catch((error) => {
			this.props.inProgress(false);

			toast.error('An error occurred while fetching the records');
			console.error('There was an error!', error);
		});
	}

	fetchEmployees() {
		var that = this;

		this.props.inProgress(true);

		var promise = httpGet('/api/reports/employees/')
			.then((response) => {
				var data = response.data;

				that.setState({ data: data });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});

		return promise;
	}

	render() {
		return (
			<div>
				<h1>Employees</h1>
				<div className="form-group">
					<MaterialTable
						title=""
						columns={[
							{
								title: 'User Group',
								field: 'userRoles',
								width: 200
							},
							{
								title: 'Resource Id',
								field: 'resourceId'
							},
							{
								title: 'First Name',
								field: 'nameFirst'
							},
							{
								title: 'Last Name',
								field: 'nameLast'
							},
							{
								title: 'Name Initial',
								field: 'nameInit'
							},
							{
								title: 'Title',
								field: 'title'
							},
							{
								title: 'Email',
								field: 'email'
							},
							{
								title: 'Login',
								field: 'loginId'
							},
							{
								title: 'Active',
								field: 'activeFlag',
								type: 'boolean'
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670,
							filtering: true,
							columnsButton: true,
							exportButton: true,
							exportAllData: true,
							exportFileName: "Employees"
						}}
						data={this.state.data}
					/>
				</div>
			</div>
		);
	}
}
