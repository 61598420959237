import * as React from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaterialTable from 'material-table';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import { httpGet, httpPost, httpPut, httpDelete } from '../../HttpRequestHandling/httpRequestHandler';
import { UserRolesResources } from './UserRolesResources';

export default class Resources extends React.Component {
	constructor() {
		super();

		const companyCode = JSON.parse(localStorage.userInfo).companyCode;

		this.currentEditRow = null;

		this.state = {
			data: [],
			companyCode: companyCode,
			userRolesList: [],
			openPasswordResetConfirmation: false,
			resetPasswordRowData: null
		};

		this.selectedUserRoles_onClose = this.selectedUserRoles_onClose.bind(this);
		this.resources_resetPasswordClickHandler = this.resources_resetPasswordClickHandler.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.resetPasswordYes_clickHandler = this.resetPasswordYes_clickHandler.bind(this);
	}

	componentDidMount() {
		this.fetchResources().then((response) => {
			this.props.inProgress(true);

			httpGet("/api/userroles/")
				.then((response) => {
					var userRolesList = response.data;

					this.props.inProgress(false);

					this.setState({ userRolesList: userRolesList });
				})
		}).catch((error) => {
			this.props.inProgress(false);

			toast.error('An error occurred while fetching the records');
			console.error('There was an error!', error);
		});
	}

	fetchResources() {
		this.props.inProgress(true);

		var promise = httpGet("/api/resources/")
			.then((response) => {
				var data = response.data;
				var rowId = 1;

				data.map(row => row.rowId = rowId++);

				this.props.inProgress(false);

				this.setState({ data: data });
			}).catch((error) => {
				this.props.inProgress(false);

				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});

		return promise;
	}

	saveResources(data, resolve, reject) {
		var httpMethod = data.rowId === 0 ? httpPost : httpPut;

		this.props.inProgress(true);

		httpMethod("/api/resources/", data)
			.then((response) => {
				this.props.inProgress(false);
				toast.success('Updated Successfully');
				resolve();
				this.currentEditRow = null;
				this.fetchResources();
			}).catch((error) => {
				reject();
				this.props.inProgress(false);
				toast.error(<div>Resources Update Failed<br />{error.response.data.detail}</div>);
			});
	}

	deleteResources(data, resolve, reject) {
		this.props.inProgress(true);

		httpDelete("/api/resources/" + data.resourceId)
			.then((response) => {
				this.props.inProgress(false);

				toast.success('Deleted Successfully');
				resolve();

				this.fetchResources();
			}).catch((error) => {
				reject();

				this.props.inProgress(false);

				if (error.response.data.includes("Cannot delete, record being referenced by other entities.")) {
					toast.error("Cannot delete, record being referenced by other entities.");
				}
				else {
					toast.error(<div>Delete Failed<br />{error.response.data}</div>);
				}
			});
	}

	isRecordValid(data) {
		var returnValue = true;
		var message = "";

		const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (data.resourceId === null || data.resourceId === undefined || data.resourceId.trim().length === 0) {
			message += "Resource Id, ";
		}
		if (data.nameFirst === null || data.nameFirst === undefined || data.nameFirst.trim().length === 0) {
			message += "First Name, ";
		}
		if (data.nameLast === null || data.nameLast === undefined || data.nameLast.trim().length === 0) {
			message += "Last Name, ";
		}
		if (data.email === null || data.email !== undefined && data.email.trim().length > 0 && !regex.test(data.email.toLowerCase())) {
			message += "valid Email, ";
		}
		if (data.loginId === null || data.loginId === undefined || data.loginId.trim().length === 0) {
			message += "Login Id, ";
		}

		if (message.length > 0) {
			message = message.substr(0, message.length - 2);

			if (message.indexOf(", ") !== -1) {
				var index = message.lastIndexOf(",");
				message = message.substring(0, index) + " and" + message.substring(index + 1);
			}

			toast.error("Please Provide " + message);

			returnValue = false;
		}

		return returnValue;
	}

	getUserRoleName(apadUserRolesResource) {
		var assignedUserRolesResources = "";

		if (this.state.userRolesList.length > 0 && apadUserRolesResource && apadUserRolesResource.length > 0) {
			assignedUserRolesResources = this.state.userRolesList.find(ur => ur.userRoleId === apadUserRolesResource[0].userRoleId).userRoleName + (apadUserRolesResource.length > 1 ? "..." : "");
		}

		return assignedUserRolesResources;
	}

	getUserRoleNameCSV(apadUserRolesResource) {
		var assignedUserRolesResources = [];

		if (this.state.userRolesList.length > 0 && apadUserRolesResource && apadUserRolesResource.length > 0) {
			apadUserRolesResource.map((userRolesResource, index) => (
				assignedUserRolesResources.push(this.state.userRolesList.find(ur => ur.userRoleId === userRolesResource.userRoleId).userRoleName)
			))
		}

		return assignedUserRolesResources.join(", ");
	}

	selectedUserRoles_onClose(selectedUserRoles) {
		if (selectedUserRoles) {
			var apadUserRolesResource = [];

			selectedUserRoles.map((userRolesResource, index) => (
				apadUserRolesResource.push({ companyCode: userRolesResource.companyCode, resourceId: this.currentEditRow.resourceId, userRoleId: parseInt(userRolesResource.userRoleId, 10) })
			));

			this.currentEditRow.apadUserRolesResource = apadUserRolesResource;

			this.setState({ dummy: null });
		}
	}

	resources_resetPasswordClickHandler(event, rowData) {
		this.setState({ openPasswordResetConfirmation: true, resetPasswordRowData: rowData });
	}

	closeDialog(event) {
		this.setState({ openPasswordResetConfirmation: false, resetPasswordRowData: null });
	}

	resetPasswordYes_clickHandler(event) {
		var url = "/api/resources/resetpassword/";
		var rowData = this.state.resetPasswordRowData;

		this.setState({ openPasswordResetConfirmation: false, resetPasswordRowData: null });

		this.props.inProgress(true);

		httpPost(url, rowData)
			.then((response) => {
				this.props.inProgress(false);

				toast.success('Password reset Successfully');
			}).catch((error) => {
				this.props.inProgress(false);

				toast.error('Password reset Failed');
			});
	}

	render() {
		var that = this;

		return (
			<div>
				<h1>User Management</h1>
				<div className="form-group">
					<MaterialTable
						title=""
						key="rowId"
						columns={[
							{
								title: 'User Group',
								field: 'apadUserRolesResource',
								width: 200,
								render: rowData => {
									return <div>
										<Tooltip title={this.getUserRoleNameCSV(rowData.apadUserRolesResource)}>
											<Typography variant="inherit">{this.getUserRoleName(rowData.apadUserRolesResource)}</Typography>
										</Tooltip>
									</div>
								},
								editComponent: props => {
									this.currentEditRow = props.rowData;

									const info = {
										userRolesList: this.state.userRolesList,
										apadUserRolesResource: props.rowData.apadUserRolesResource ? props.rowData.apadUserRolesResource : []
									};

									return <div>
										<Tooltip title={this.getUserRoleNameCSV(this.currentEditRow.apadUserRolesResource)}>
											<Typography variant="inherit">{this.getUserRoleName(this.currentEditRow.apadUserRolesResource)}</Typography>
										</Tooltip>
										<UserRolesResources info={info} onClose={this.selectedUserRoles_onClose} />
									</div>
								}
							},
							{
								title: 'Resource Id',
								field: 'resourceId',
								editable: 'onAdd'
							},
							{
								title: 'First Name',
								field: 'nameFirst'
							},
							{
								title: 'Last Name',
								field: 'nameLast'
							},
							{
								title: 'Name Initial',
								field: 'nameInit'
							},
							{
								title: 'Title',
								field: 'title'
							},
							{
								title: 'Email',
								field: 'email'
							},
							{
								title: 'Login',
								field: 'loginId'
							},
							{
								title: 'Active',
								field: 'activeFlag',
								type: 'boolean',
								width: 80,
								headerStyle: {
									textAlign: 'center',
								},
								cellStyle: {
									paddingRight: 50
								},
								editComponent: props => (
									<Checkbox
										color="default"
										checked={props.value}
										onChange={e => props.onChange(e.target.checked)}
									/>
								)
							}
						]}
						data={this.state.data}
						options={{
							actionsColumnIndex: -1,
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 650
						}}
						actions={[
							{
								icon: 'autorenew',
								tooltip: 'Reset Password',
								onClick: this.resources_resetPasswordClickHandler
							}
						]}
						editable={
							{
								onRowAdd: newData =>
									new Promise((resolve, reject) => {
										newData.rowId = 0;
										newData.companyCode = parseInt(this.state.companyCode);

										if (this.isRecordValid(newData)) {
											newData.apadUserRolesResource = that.currentEditRow.apadUserRolesResource;

											this.saveResources(newData, resolve, reject);
										}
										else {
											reject();
										}
									}),
								onRowUpdate: (newData, oldData) =>
									new Promise((resolve, reject) => {

										if (this.isRecordValid(newData)) {
											newData.apadUserRolesResource = that.currentEditRow.apadUserRolesResource;

											this.saveResources(newData, resolve, reject);
										}
										else {
											reject();
										}
									}),
								onRowDelete: oldData =>
									new Promise((resolve, reject) => {
										this.deleteResources(oldData, resolve, reject);
									}),
								onRowAddCancelled: rowData =>
									new Promise((resolve, reject) => {
										this.setState({ selectedUserRoleIds: [], initializeSelectedUserRoleIds: true });
									}),
								onRowUpdateCancelled: rowData =>
									new Promise((resolve, reject) => {
										this.setState({ selectedUserRoleIds: [], initializeSelectedUserRoleIds: true });
									})
							}
						}
					/>
				</div>
				<Dialog
					open={this.state.openPasswordResetConfirmation}
					aria-labelledby="draggable-dialog-title"
				>
					<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
						Reset Password
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to reset password for login id "{this.state.resetPasswordRowData ? this.state.resetPasswordRowData.loginId : ""}" ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.resetPasswordYes_clickHandler} color="primary">
							Yes
						</Button>
						<Button autoFocus onClick={this.closeDialog} color="primary">
							No
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
