import React from 'react';
import { Route } from 'react-router';
import Home from './components/Home';
import Login from './components/Authentication/login';
import LoginError from './components/Authentication/LoginError';
import CompanyConfiguration from './components/CompanyConfig/CompanyConfiguration';
import Resources from './components/Resources/Resources';
import UserRoles from './components/UserRoles/UserRoles';
import NonMappedInvoices from './components/NonMappedInvoices/NonMappedInvoices';
import ManageInvoiceModel from './components/InvoiceModels/ManageInvoiceModel';
import InvoiceDocument from './components/InvoiceDocument/InvoiceDocument';
import Dashboard from './components/Dashboard/Dashboard';
import PaymentDashboard from './components/PaymentDashboard/PaymentDashboard';
import Currencies from './components/Setup/Currencies';
import PaymentTerms from './components/Setup/PaymentTerms';
import Taxes from './components/Setup/Taxes';
import Vendors from './components/Setup/Vendors';
import POHeaders from './components/Setup/POHeaders';
import Level2s from './components/Setup/Level2s';
import CostTypes from './components/Setup/CostTypes';
import APPayments from './components/Setup/APPayments';
import EmployeesReport from './components/Reports/EmployeesReport';
import VendorsReport from './components/Reports/VendorsReport';
import PurchaseOrdersReport from './components/Reports/PurchaseOrdersReport';
import InsertionOrdersReport from './components/Reports/InsertionOrdersReport';
import VendorInvoicesReport from './components/Reports/VendorInvoicesReport';
import VendorInvoicesWithPaymentStatusReport from './components/Reports/VendorInvoicesWithPaymentStatusReport';
import InvoicesPendingPostReport from './components/Reports/InvoicesPendingPostReport';
import InvoicesApprovalHistoryReport from './components/Reports/InvoicesApprovalHistoryReport';

import { stringIsNullOrEmpty,isNullOrUndefined } from './components/Common/Utilities';

export default class CustomRoutes extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {

		const getUserInfo = () => isNullOrUndefined(localStorage.userInfo) ? null : JSON.parse(localStorage.userInfo);
		const isTokenExpired = () => isNullOrUndefined(localStorage.tokenExpiry) || Date.now() > Date.parse(localStorage.tokenExpiry);

		const queryString = window.location.search;
		const searchParams = new URLSearchParams(queryString);
		const ssoToken = searchParams.get('ssotoken');

		const getInvoicesRoute = (basePath, modelDefId) => (
			<div>
				<Route exact path={basePath + '/ManageNonMappedInvoices/'} render={(props) => (<NonMappedInvoices {...props} modelDefId={modelDefId} basePath={basePath} inProgress={this.props.inProgress} />)} />
				<Route exact path={basePath + '/ManageInvoiceModels/'} render={(props) => (<ManageInvoiceModel {...props} modelDefId={modelDefId} basePath={basePath} inProgress={this.props.inProgress} />)} />
				<Route exact path={basePath + '/ManageInvoiceDocuments/'} render={(props) => (<InvoiceDocument {...props} modelDefId={modelDefId} basePath={basePath} inProgress={this.props.inProgress} />)} />
				<Route exact path={basePath + '/Dashboard/'} render={(props) => (<Dashboard {...props} modelDefId={modelDefId} inProgress={this.props.inProgress} />)} />
				<Route exact path={basePath + '/PaymentDashboard/'} render={(props) => (<PaymentDashboard {...props} modelDefId={modelDefId} inProgress={this.props.inProgress} />)} />
				<Route exact path={basePath + '/'} render={(props) => (<Dashboard {...props} modelDefId={modelDefId} inProgress={this.props.inProgress} />)} />
			</div>
		);

		return (
			<div>
				<Route exact path='/' render={(props) => (getUserInfo() == null || isTokenExpired() || !stringIsNullOrEmpty(ssoToken) ? <Login {...props} inProgress={this.props.inProgress} /> : (isNullOrUndefined(getUserInfo().invoiceType) ? <Home /> : <Dashboard {...props} modelDefId={getUserInfo().invoiceType.includes('1') ? 1 : 2} inProgress={this.props.inProgress} />))} />
				<Route exact path='/Login' render={(props) => (getUserInfo() == null || isTokenExpired() || !stringIsNullOrEmpty(ssoToken) ? <Login {...props} inProgress={this.props.inProgress} /> : (isNullOrUndefined(getUserInfo().invoiceType) ? <Home /> : <Dashboard {...props} modelDefId={getUserInfo().invoiceType.includes('1') ? 1 : 2} inProgress={this.props.inProgress} />))} />
				<Route exact path='/LoginError' render={(props) => (<LoginError {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/CompanyConfiguration' render={(props) => (<CompanyConfiguration {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Resources' render={(props) => (<Resources {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/UserRoles' render={(props) => (<UserRoles {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Setup/Currencies' render={(props) => (<Currencies {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Setup/PaymentTerms' render={(props) => (<PaymentTerms {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Setup/Taxes' render={(props) => (<Taxes {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Setup/Vendors' render={(props) => (<Vendors {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Setup/InsertionOrders' render={(props) => (<POHeaders {...props} modelDefId={1} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Setup/PurchaseOrders' render={(props) => (<POHeaders {...props} modelDefId={2} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Setup/Projects' render={(props) => (<Level2s {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Setup/CostTypes' render={(props) => (<CostTypes {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Setup/APPayments' render={(props) => (<APPayments {...props} inProgress={this.props.inProgress} />)} />
				{getInvoicesRoute('/Media', 1)}
				{getInvoicesRoute('/Media/', 1)}
				{getInvoicesRoute('/Production', 2)}
				{getInvoicesRoute('/Production/', 2)}
				<Route exact path='/Reports/Employees' render={(props) => (<EmployeesReport {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Reports/Vendors' render={(props) => (<VendorsReport {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Reports/PurchaseOrders' render={(props) => (<PurchaseOrdersReport {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Reports/InsertionOrders' render={(props) => (<InsertionOrdersReport {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Reports/VendorInvoices' render={(props) => (<VendorInvoicesReport {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Reports/VendorInvoicesWithPaymentStatus' render={(props) => (<VendorInvoicesWithPaymentStatusReport {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Reports/InvoicesPendingPost' render={(props) => (<InvoicesPendingPostReport {...props} inProgress={this.props.inProgress} />)} />
				<Route exact path='/Reports/InvoicesApprovalHistory' render={(props) => (<InvoicesApprovalHistoryReport {...props} inProgress={this.props.inProgress} />)} />
			</div>
		);
	}
}