import * as React from 'react';
import { ScannedSuccessful } from './ScannedSuccessful';
import { ScanFailed } from '../NonMappedInvoices/ScanFailed'
import { ManualUpload } from '../NonMappedInvoices/ManualUpload'
import $ from 'jquery';

export default class NonMappedInvoices extends React.Component {
	constructor() {
		super();

		this.state = {
			company: {},
			isDirty: false
		};

		this.changeHandler = this.changeHandler.bind(this);
	}

	componentDidMount() {
		var that = this;
		$("ul#tabStrip").children().on("click", function () {
			that.showHideTab(this);
		});

		this.showHideTab($("ul#tabStrip li:first-child")[0]);
	}

	showHideTab(currentTab) {
		$("div." + currentTab.className).parent().children().hide();
		$(currentTab).parent().children().removeClass('active');

		$('div.' + currentTab.className).show();
		$(currentTab).addClass('active');
	}

	changeHandler(event) {
		var comp = this.state.company;

		comp[event.target.name] = typeof (comp[event.target.name]) === "number" ? parseInt(event.target.value, 10) : event.target.value

		this.setState({
			company: comp,
			isDirty: true
		})
	}

	render() {
		const info = {
			inProgress: this.props.inProgress,
			modelDefId: this.props.modelDefId,
			basePath: this.props.basePath
		};

		return (
			<div>
				<h1>Manage Non-Mapped {this.props.modelDefId === 1 ? 'Media' : 'Production'} Invoices</h1>
				<div className="col-md-12 form-group">
					<div className="form-group">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sub_navigations">
							<div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 nav-padding">
								<ul id="tabStrip">
									<li className="tabScannedSuccessful"><a>Scanned Successfully</a></li>
									<li className="tabScanFailed"><a>Scanner Failed</a></li>
									<li className="tabManualUpload"><a>Manual Upload</a></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="form-group">
						<div className="col-md-12 zero-padding tabScannedSuccessful">
							<ScannedSuccessful info={info} />
						</div>
						<div className="col-md-12 zero-padding tabScanFailed">
							<ScanFailed info={info} />
						</div>
						<div className="col-md-12 zero-padding tabManualUpload">
							<ManualUpload info={info} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
