import * as React from 'react';
export class InputCheckbox extends React.Component {
    constructor() {
        super();

        this.state = {
            readOnly: true,
            value: ""
        }

        this._changeHandler = this._changeHandler.bind(this);
    }
    _changeHandler(event) {
        event.target.value = event.target.checked ? "Y" : "N";

        this.props.changeHandler(event);
    }
    render() {
        var checked = this.props.checkboxInfo.checkboxValue === "Y" ? true : false;

        return (
            <input className="custom-checkbox"
                type="checkbox"
                id={this.props.checkboxInfo.checkboxId}
                name={this.props.checkboxInfo.checkboxName}
                value={this.props.checkboxInfo.checkboxValue == null ? "" : this.props.checkboxInfo.checkboxValue}
                checked={checked}
                onChange={this._changeHandler}
            />
        );
    }
}
