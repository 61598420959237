import React, { Component } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Progress } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import CustomRoutes from '../Routes';
import 'react-toastify/dist/ReactToastify.css';

export default class bodyContainer extends Component {
	constructor() {
		super();

		this.callsInProgress = 0;

		this.state = {
			inProgress: false,
			uploading: false,
			loaded: 0
		};

		this.inProgress = this.inProgress.bind(this);
	}

	componentDidMount() {
	}

	inProgress(value, uploading, loaded) {
		if (uploading) {
			this.setState({ uploading: uploading, loaded: loaded });
		}
		else {
			if (value === true) {
				this.callsInProgress++;
				this.setState({ inProgress: value, uploading: uploading, loaded: loaded });
			}
			else if (value === false) {
				if (this.callsInProgress > 0) {
					this.callsInProgress--;
				}

				if (this.callsInProgress === 0) {
					this.setState({ inProgress: value, uploading: uploading, loaded: loaded });
				}
			}
		}

		return value === undefined ? this.state.inProgress : value;
	}

	render() {
		return (
			<div className="content_down">
				<div id="dLicErrMsg" style={{ display: "block" }}>
					<div id="dLicErrMsg1" style={{ padding: "10px 0 0", marginLeft: "19px", fontWeight: "bold", width: "99%", height: "5px", float: "left", fontFamily: "Open Sans, sans-serif", fontSize: "14px", color: "rgb(206, 67, 2)" }}>
					</div>
				</div>
				<main role="main" className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<CustomRoutes inProgress={this.inProgress} />
				</main>
				<Backdrop style={{ zIndex: 5000, color: '#fff' }} open={this.state.inProgress}>
					<Progress max="100" color="success" style={{ width: "1024px", display: this.state.uploading ? "" : "none" }} value={this.state.loaded} >{Math.round(this.state.loaded, 2)}%</Progress>
					<CircularProgress color="inherit" style={{ display: this.state.inProgress && !this.state.uploading ? "" : "none" }} />
				</Backdrop>
				<ToastContainer
					position="top-right"
					newestOnTop
					closeOnClick
				/>
			</div>
		);
	}
}