import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import Zoom from '@material-ui/core/Zoom';
import { toast } from 'react-toastify';
import { formatNumber } from '../Common/Utilities';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';
import PODetails from './PODetails';
import POResources from './POResources';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});


export default class POHeaders extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			lastUpdated: "",
			openPODetailsDialog: false,
			openPOResourcesDialog: false
		};

		this.viewPODetails_clickHandler = this.viewPODetails_clickHandler.bind(this);
		this.poDetailsDialog_closeHandler = this.poDetailsDialog_closeHandler.bind(this);
		this.viewPOResources_clickHandler = this.viewPOResources_clickHandler.bind(this);
		this.poResourcesDialog_closeHandler = this.poResourcesDialog_closeHandler.bind(this);
	}

	componentDidMount() {
		this.fetchPOHeaders();
	}

	viewPODetails_clickHandler(event) {
		event.preventDefault();

		this.setState({ openPODetailsDialog: true, selectedPOCode: event.target.dataset.pocode });
	}

	poDetailsDialog_closeHandler(event) {
		this.setState({ openPODetailsDialog: false });
	}

	viewPOResources_clickHandler(event) {
		event.preventDefault();

		this.setState({ openPOResourcesDialog: true, selectedPOCode: event.target.dataset.pocode });
	}

	poResourcesDialog_closeHandler(event) {
		this.setState({ openPOResourcesDialog: false });
	}

	fetchPOHeaders() {
		var that = this;

		this.props.inProgress(true);

		httpGet('/api/setup/poheaders/' + this.props.modelDefId)
			.then((response) => {
				var data = response.data;

				var localDateString = data.lastUpdated == null ? "" : new Date(data.lastUpdated).toLocaleString();
				that.setState({ data: data.records, lastUpdated: localDateString });

				this.props.inProgress(false);
			})
			.catch((error) => {
				this.props.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		var currencyCode = this.state.data.length > 0 ? this.state.data[0].currencyCode : "";
		const info = {
			poCode: this.state.selectedPOCode,
			currencyCode: currencyCode,
			inProgress: this.props.inProgress
		}

		const cellStyle = { paddingLeft: 8, paddingRight: 45 };
		const headerStyle = { textAlign: 'right' };

		return (
			<div>
				<h1>{this.props.modelDefId === 1 ? "Insertion" : "Purchase"} Orders</h1>
				<div className="form-group">
					<MaterialTable
						title={"Last Updated: " + this.state.lastUpdated}
						columns={[
							{
								title: 'IO/PO Number',
								field: 'poCode',
								width: 50
							},
							{
								title: 'IO/PO Amount',
								field: 'poAmount',
								type: 'currency',
								cellStyle: cellStyle,
								headerStyle: headerStyle,
								render: rowData => formatNumber(rowData.poAmount) + " " + rowData.currencyCode
							},
							{
								title: 'Consumed Amount',
								field: 'consumedAmount',
								type: 'currency',
								cellStyle: cellStyle,
								headerStyle: headerStyle,
								render: rowData => formatNumber(rowData.consumedAmount) + " " + rowData.currencyCode
							},
							{
								title: 'Client Name',
								field: 'clientName',
								width: 50
							},
							{
								title: 'Job Code',
								field: 'jobCode',
								width: 50
							},
							{
								title: 'Campaign Name',
								field: 'campaignName',
								width: 50
							},
							{
								title: 'Details',
								headerStyle: {
									textAlign: 'center',
								},
								cellStyle: {
									textAlign: 'center'
								},
								hidden: this.props.modelDefId !== 2,
								render: rowData => <Link data-pocode={rowData.poCode} href="#" onClick={this.viewPODetails_clickHandler}>View</Link>
							},
							{
								title: 'Resources',
								headerStyle: {
									textAlign: 'center',
								},
								cellStyle: {
									textAlign: 'center'
								},
								hidden: this.props.modelDefId === 2,
								render: rowData => <Link data-pocode={rowData.poCode} href="#" onClick={this.viewPOResources_clickHandler}>View</Link>
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670
						}}
						data={this.state.data}
					/>
				</div>
				<Dialog open={this.state.openPODetailsDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<PODetails info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.poDetailsDialog_closeHandler} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={this.state.openPOResourcesDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
					<DialogContent>
						<POResources info={info} />
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={this.poResourcesDialog_closeHandler} color="primary">
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
