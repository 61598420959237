import React, { Component } from 'react';
//import './Header.css';
//import './bootstrap.min.css';
import LeftMenu from './LeftMenu/LeftMenu';
import TopMenu  from './TopMenu/TopMenu';
import { ProfileMenu } from './ProfileMenu';
export class Header extends Component {
    constructor() {
        super();
        this.state = {
            changePath: "/"
        };

    }
    changeState = (updatedPath) => {
        
        this.setState({ changePath: updatedPath });
    }
    render() {
        
		return (
			<header>
                <div className="container-fluid zero-padding zero-margin">
                    <div className="col-md-12 page-header zero-padding zero-margin">
                        <div className="col-md-12 page-header-top zero-padding zero-margin">

                            <div className="container zero-padding">
                            
                                <LeftMenu updateLeftMenu={this.state.changePath}/>

                                <div className="page-logo">
                                    <img src="/images/nexelus-logo-200_white.png" alt="Media Planning Logo" className="logo-default" />
                                </div>

                                <TopMenu tokenHandler={this.props.tokenHandler} RenderHeader={this.changeState} />

                                <div className="top-menu" style={{ display: "none" }}>
                                    <ProfileMenu/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
			</header>
		);
	}
}