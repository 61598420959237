import * as React from 'react';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table'
import { httpGet, httpPost } from '../../HttpRequestHandling/httpRequestHandler';
import { toast } from 'react-toastify';

export default class CurrenciesMaps extends React.Component {
	constructor() {
		super();

		this.state = {
			data: [],
			isDirty: false
		};

		this.currenciesMaps_cancelHandler = this.currenciesMaps_cancelHandler.bind(this);
		this.currenciesMaps_saveHandler = this.currenciesMaps_saveHandler.bind(this);
	}

	componentDidMount() {
		this.fetchCurrenciesMaps();
	}

	currenciesMaps_cancelHandler(event) {
		if (this.props.onClose) {
			this.props.onClose(event);
		}
	}

	currenciesMaps_saveHandler(event) {
		var data = {
			currencyCode: this.props.info.currencyCode,
			currenciesMaps: this.state.data
		};

		this.props.info.inProgress(true);

		httpPost("/api/setup/savecurrenciesmaps/", data)
			.then((response) => {
				toast.success('Updated Successfully');
				this.props.info.inProgress(false);
				this.currenciesMaps_cancelHandler(null);
			}).catch((error) => {
				this.props.info.inProgress(false);
				toast.error(<div>Currencies Update Failed<br />{error.response.data.detail}</div>);
			});
	}

	fetchCurrenciesMaps() {
		var that = this;

		this.props.info.inProgress(true);

		httpGet('/api/setup/currenciesmaps/' + this.props.info.currencyCode)
			.then((response) => {
				var data = response.data;

				that.setState({ data: data });

				this.props.info.inProgress(false);
			}).catch((error) => {
				this.props.info.inProgress(false);
				toast.error('An error occurred while fetching the records');
				console.error('There was an error!', error);
			});
	}

	render() {
		return (
			<div>
				<h1>Currency Mappings</h1>
				<div className="form-group">
					<MaterialTable
						title=""
						columns={[
							{
								title: 'Invoice Currency Code',
								field: 'apamInvoiceCurrencyCode'
							}
						]}
						options={{
							actionsColumnIndex: -1,
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 670
						}}
						data={this.state.data}
						editable={
							{
								onRowAdd: newData =>
									new Promise((resolve, reject) => {
										const currenciesMaps = this.state.data;

										newData.currencyCode = this.props.info.currencyCode;
										currenciesMaps.push(newData);

										this.setState({ data: currenciesMaps, isDirty: true }, () => resolve());
									}),
								onRowUpdate: (newData, oldData) =>
									new Promise((resolve, reject) => {
										const currenciesMaps = this.state.data;
										const index = currenciesMaps.indexOf(oldData);
										currenciesMaps[index] = newData;

										this.setState({ data: currenciesMaps, isDirty: true }, () => resolve());
									}),
								onRowDelete: oldData =>
									new Promise((resolve, reject) => {
										const currenciesMaps = this.state.data;
										const index = currenciesMaps.indexOf(oldData);
										currenciesMaps.splice(index, 1);

										this.setState({ data: currenciesMaps, isDirty: true }, () => resolve());
									}),
							}}
					/>
				</div>
				<Button color="primary" onClick={this.currenciesMaps_cancelHandler} style={{ float: "right" }}>
					Cancel
				</Button>
				<Button autoFocus color="primary" disabled={!this.state.isDirty} onClick={this.currenciesMaps_saveHandler} style={{ float: "right" }}>
					Save
				</Button>
			</div>
		);
	}
}
