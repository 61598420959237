import * as React from 'react';
import { DocumentViewer } from './DocumentViewer'
import { InvoiceModelEditor } from './InvoiceModelEditor'
import './css/style.css';

export class InvoiceModelSetup extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.passInvoiceModelEditorToSiblings = this.passInvoiceModelEditorToSiblings.bind(this);
	}

	passInvoiceModelEditorToSiblings(invoiceModelEditor) {
		this.setState({ invoiceModelEditor: invoiceModelEditor })
	}

	render() {
		var info = this.props.info;
		info.passInvoiceModelEditorToSiblings = this.passInvoiceModelEditorToSiblings
		info.invoiceModelEditor = this.state.invoiceModelEditor;

		return (
			<div>
				<h1 style={{ marginLeft: 15 }}>Invoice Model Setup</h1>
				<div className="body-container-wrapper">
					<div className="mainTable fullWidthTable">
						<div className="row-form">
							<DocumentViewer info={this.props.info} />
							<InvoiceModelEditor info={this.props.info} onClose={this.props.onClose} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}