var maxSelectFile = (event, toast) => {
	let files = event.target.files // create file object

	if (files.length > 10) {
		const msg = 'Only 10 files can be uploaded at a time'
		toast.error(msg);

		return false;
	}

	return true;
}

exports.maxSelectFile = maxSelectFile;

var checkMimeType = (event, toast) => {
	//getting file object
	let files = event.target.files
	//define message container
	let err = []
	// list allow mime type
	const types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']
	// loop access array
	for (var x = 0; x < files.length; x++) {
		// compare file type find doesn't matach
		if (types.every(type => files[x].type !== type)) {
			// create error message and assign to container   
			err.push(files[x].name + ' of type \'' + files[x].type + '\' is not a supported format');
		}
	};

	if (err.length > 0) { // if message not same old that mean has error
		for (var i = 0; i < err.length; i++) {
			toast.error(err[i]);
		}

		return false;
	}

	return true;
}

exports.checkMimeType = checkMimeType;

var checkFileSize = (event, toast) => {
	let files = event.target.files
	var size = 10 * 1024 * 1024; //10 MB size
	let err = "";

	for (var x = 0; x < files.length; x++) {
		if (files[x].size > size) {
			err += files[x].name + ' is too large, please pick a smaller file\n';
		}
	};

	if (err !== '') {
		toast.error(err);

		return false
	}

	return true;
}

exports.checkFileSize = checkFileSize;

var fileUpload = function fileUpload(that, event, modelDefId, toast, httpPost, onProgressStart, onProgressEnd, onUploadProgress, onSuccess) {
	const formData = new FormData();
	const files = Array.from(event.target.files);

	for (var x = 0; x < files.length; x++) {
		const fileOriginal = files[x];
		var filename = fileOriginal.name.replace(/[^\w\.]/gi, '');
		var file = new File([fileOriginal], filename, { type: fileOriginal.type });
		files[x] = file;
	};

	if (maxSelectFile(event, toast) && checkMimeType(event, toast) && checkFileSize(event, toast)) {
		// if return true allow to setState
		that.state.selectedFiles = files;
	} else {
		event.target.value = null
		return;
	}

	that.state.selectedFiles.forEach((file, i) => {
		formData.append(i, file)
	})

	formData.append("modelDefId", modelDefId);

	const token = localStorage.token;
	const headers = {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${token}`
	}

	onProgressStart();

	httpPost("/api/nonmappedinvoices/upload", formData, {
		headers: headers,
		onUploadProgress: onUploadProgress,
	}).then((response) => {
		onProgressEnd();

		toast.success('Uploaded Successfully');

		onSuccess();
	}).catch((error) => {
		onProgressEnd();
		toast.error('Upload Failed');
	});

	event.target.value = null;
}

exports.fileUpload = fileUpload;