import * as React from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialTable from 'material-table'
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Zoom from '@material-ui/core/Zoom';
import { httpGet } from '../../HttpRequestHandling/httpRequestHandler';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} />;
});

export class Level3Lookup extends React.Component {
	constructor(props) {
		super(props);

		this.level2Key = null;
		this.poCode = null;

		this.inProgress = false;

		this.state = {
			level3Key: null,
			data: [],
			rowData: null
		};

		this.checkbox_clickHandler = this.checkbox_clickHandler.bind(this);
		this.close_clickHandler = this.close_clickHandler.bind(this);
	}

	componentDidUpdate() {
		var that = this;

		if (this.props.info.level2Key !== this.level2Key || this.props.info.poCode !== this.poCode) {
			this.level2Key = this.props.info.level2Key;
			this.poCode = this.props.info.poCode;

			this.inProgress = false;
			this.setState({ data: [], rowData: null });
		}

		if (this.props.info.openDialog && this.state.data.length === 0 && !this.inProgress) {
			this.inProgress = true;
			this.fetchRecords();
		}
		else if (this.props.info.level3Key !== this.state.level3Key) {
			var rowData = this.getRowData(this.props.info.level3Key);

			this.setState({ level3Key: this.props.info.level3Key, rowData: rowData });
		}
	}

	checkbox_clickHandler(event) {
		var rowData = this.getRowData(event.target.value);

		this.setState({ rowData: rowData });
	}

	close_clickHandler(event) {
		if (this.props.onClose) {
			this.props.onClose(event, this.state.rowData);
		}
	}

	fetchRecords() {
		if (this.state.data.length === 0) {

			this.props.info.inProgress(true);

			httpGet("/api/lookup/level3list/" + this.props.info.level2Key + "/" + this.props.info.poCode)
				.then((response) => {
					var data = response.data;
					var rowData = data[data.findIndex(d => d.level3Key === this.props.info.level3Key)];

					this.setState({ level3Key: this.props.info.level3Key, data: data, rowData: rowData });
					this.props.info.inProgress(false);
				}).catch((error) => {
					this.props.info.inProgress(false);

					toast.error('Error fetching Activity Lookup');

					this.close_clickHandler();
				});
		}
	}

	getRowData(value) {
		var data = this.state.data;

		return data[data.findIndex(d => d.level3Key === value)];
	}

	render() {
		const cellStyle = { paddingLeft: 8, paddingRight: 28 };
		const headerStyle = { textAlign: 'right' };

		return (
			<Dialog open={this.props.info.openDialog} TransitionComponent={Transition} fullWidth maxWidth="lg">
				<DialogTitle>
					Select Activity
						</DialogTitle>
				<DialogContent>
					<MaterialTable
						title=""
						columns={[
							{
								width: 5,
								render: rowData =>
									<Checkbox
										color="default"
										name="chk"
										checked={this.state.rowData != null && this.state.rowData !== undefined && rowData.level3Key === this.state.rowData.level3Key}
										value={rowData.level3Key}
										onClick={this.checkbox_clickHandler}
									/>
							},
							{
								title: 'Activity',
								field: 'level3Key',
								width: 50
							},
							{
								title: 'Description',
								field: 'level3Description'
							},
							{
								title: 'Open Date',
								field: 'openDate',
								type: 'date'
							},
							{
								title: 'Cost Type',
								field: 'costType',
								width: 50
							},
							{
								title: 'Customer PO Number',
								field: 'customerPONumber',
								width: 50
							}
						]}
						options={{
							pageSize: 10,
							pageSizeOptions: [10, 20, 30, 40, 50],
							maxBodyHeight: 550
						}}
						data={this.state.data}
					/>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={this.close_clickHandler} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}
