import * as React from 'react';

export class InputError extends React.Component {

    render() {
        //var errorClass = classNames(this.props.className, {
        //    'error_container': true,
        //    'visible': this.props.visible,
        //    'invisible': !this.props.visible
        //});

        return (
            <div >
                <span>{this.props.errorMessage}</span>
            </div>
        )
    }
}